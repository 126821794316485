import React, { useState, useEffect } from 'react';
import './EnterLeadsModal.css'; // Import CSS file for styling
import Sidebar from '../../Sidebar/Sidebar';
import { useSidebar } from '../../../ContextAPI/SidebarContext';
import Select from 'react-select';
import '../../Dashboard/Allorders.css';
import { ExternalLink } from 'react-feather';
import NotificationButton from '../../../Notification/NotificationButton';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../ContextAPI/AuthContext';
import { toast, Toaster } from 'react-hot-toast';



const EnterLeadsModal = ({onClose}) => {
    // State variables for form inputs
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const [productOptions, setProductOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState('');
    const [errors, setErrors] = useState({
        phoneNumber: ''
    });
    const [city, setCity] = useState('');
    const [cities, setCities] = useState([]);
    const [personInCharge, setPersonInCharge] = useState('');
    const { user,token } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate('/');
        } else {
            
        }
    }, [user, token, navigate]);

    const [formData, setFormData] = useState({
        customerName: '',
        email: '',
        phoneNumber: '',
        category: '',
        state: '',
        stateCode: '',
        city: '',
        pincode: '',
        address: '',
        product1: [],
        product2: [],
        interestLevel: '',
        source: '',
        closingDate: '',
        expectedValue: '',
        personInCharge: ''
    });

    const [interestLevels, setInterestLevels] = useState([
        { value: "3", label: "Very Hot" },
        { value: "2", label: "Hot" },
        { value: "1", label: "Warm" },
        { value: "-1", label: "Cold" }
    ]);

    const [sources, setSources] = useState([
        { value: "1", label: "Phone" },
        { value: "2", label: "Direct" },
        { value: "3", label: "Social Media" },
        { value: "4", label: "Employee Lead" },
        { value: "5", label: "Organic Lead" },
        { value: "6", label: "House Of Alt" },
        { value: "7", label: "Service Team Lead" },
        { value: "8", label: "CRM Lead" },
        { value: "9", label: "Expo" },
        { value: "10", label: "May 24 Forecast" }
    ]);

    const handleSourceChange = (e) => {
        const selectedSource = e.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            source: selectedSource
        }));
    };

    const handleInterestChange = (e) => {
        const selectedInterest = e.target.value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            interestLevel: selectedInterest  // Update interestLevel here
        }));
    };

    const handleCloseClick =() =>{
        onClose();
    }


    const stateCityMap = {
        "AN": ["Others"],
        "AP": ["Others"],
        "AR": ["Others"],
        "AS": ["Others"],
        "BR": ["Others"],
        "CG": ["Others"],
        "CH": ["Others"],
        "DD": ["Others"],
        "DL": ["Others"],
        "DN": ["Others"],
        "GA": ["Others"],
        "GJ": ["Others"],
        "HR": ["Others"],
        "HP": ["Others"],
        "JK": ["Jammu", "Kashmir"],
        "JH": ["Others"],
        "KT": ["Others", "Belgum"],
        "KL": ["Others"],
        "LD": ["Others"],
        "MP": ["Others"],
        "MH": ["Mumbai", "Aurangabad", "Pune", "Nasik", "Others"],
        "MN": ["Others"],
        "ML": ["Others"],
        "MZ": ["Others"],
        "NL": ["Others"],
        "NP": ["Others"],
        "OD": ["Others"],
        "PN": ["Others"],
        "PY": ["Others"],
        "RJ": ["Others"],
        "SK": ["Others"],
        "TN": ["Others"],
        "TG": ["Others"],
        "TR": ["Others"],
        "UK": ["Others"],
        "UP": ["Others"],
        "WB": ["Others"]
    };

    const stateCodeMap = {
        "Andaman and Nicobar Islands": "AN",
        "Andhra Pradesh": "AP",
        "Arunachal Pradesh": "AR",
        "Assam": "AS",
        "Bihar": "BR",
        "Chhattisgarh": "CG",
        "Chandigarh": "CH",
        "Dadra and Nagar Haveli": "DN",
        "Daman and Diu": "DD",
        "Delhi": "DL",
        "Goa": "GA",
        "Gujarat": "GJ",
        "Haryana": "HR",
        "Himachal Pradesh": "HP",
        "Jammu and Kashmir": "JK",
        "Jharkhand": "JH",
        "Karnataka": "KT",
        "Kerala": "KL",
        "Ladakh": "LD",
        "Lakshadweep": "LD",
        "Madhya Pradesh": "MP",
        "Maharashtra": "MH",
        "Manipur": "MN",
        "Meghalaya": "ML",
        "Mizoram": "MZ",
        "Nagaland": "NL",
        "Nepal": "NP",
        "Odisha": "OD",
        "Punjab": "PN",
        "Puducherry": "PY",
        "Rajasthan": "RJ",
        "Sikkim": "SK",
        "Tamil Nadu": "TN",
        "Telangana": "TG",
        "Tripura": "TR",
        "Uttarakhand": "UK",
        "Uttar Pradesh": "UP",
        "West Bengal": "WB"
    };

    const stateCityPersonMap = {
        "AN": { "Others": "Sangeetha" },
        "AP": { "Others": "Sangeetha" },
        "AR": { "Others": "Shankar Das" },
        "AS": { "Others": "Krutika Lilaramani" },
        "BR": { "Others": "Vijay Sharma" },
        "CG": { "Others": "Jyoti Sahu" },
        "CH": { "Others": "Jyoti Sahu" },
        "DD": { "Others": "Sangeetha" },
        "DL": { "Others": "Jyoti Sahu" },
        "DN": { "Others": "Sangeetha" },
        "GA": { "Others": "Sangeetha" },
        "GJ": { "Others": "3T Dealer" },
        "HR": { "Others": "Jyoti Sahu" },
        "HP": { "Others": "Paramjit Singh" },
        "JK": { "Jammu": "Paramjit Singh", "Kashmir": "Krutika Lilaramani" },
        "JH": { "Others": "Vijay Sharma" },
        "KT": { "Others": "Sangeetha", "Belgum": "Siddique" },
        "KL": { "Others": "Sangeetha" },
        "LD": { "Others": "Sangeetha" },
        "MP": { "Others": "Prashant Niwate" },
        "MH": {
            "Mumbai": "Sudhir Pujar",
            "Aurangabad": "Pankaj Jangam",
            "Pune": "Rakesh Shinde",
            "Nasik": "SK Dental",
            "Others": "Krutika Lilaramani"
        },
        "MN": { "Others": "Shankar Das" },
        "ML": { "Others": "Shankar Das" },
        "MZ": { "Others": "Shankar Das" },
        "NL": { "Others": "Shankar Das" },
        "NP": { "Others": "Jyoti Sahu" },
        "OD": { "Others": "GB Venture" },
        "PN": { "Others": "Paramjit Singh" },
        "PY": { "Others": "Varadharaj T U" },
        "RJ": { "Others": "Sadul Singh" },
        "SK": { "Others": "Shankar Das" },
        "TN": { "Others": "Varadharaj T U" },
        "TG": { "Others": "Sangeetha" },
        "TR": { "Others": "Shankar Das" },
        "UK": { "Others": "Krutika Lilaramani" },
        "UP": { "Others": "Krutika Lilaramani" },
        "WB": { "Others": "Nithish" }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'phoneNumber') {
            // Allow only digits and ensure length is up to 10 characters
            const regex = /^[0-9]{0,10}$/;
            if (regex.test(value)) {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
                // Clear error message if input is valid
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: ''
                }));
            } else {
                // Set error message if input is invalid
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: 'Enter only numeric characters (up to 10).'
                }));
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const handleStateChange = (e) => {
        const selectedState = e.target.value;
        console.log('Selected State:', selectedState);
    
        // Get the state code from the mapping
        const stateCode = stateCodeMap[selectedState] || selectedState;
        console.log('State Code:', stateCode);
    
        // Update the state for the frontend form
        setState(selectedState);
    
        // Update formData with the full state name
        setFormData((prevFormData) => {
            console.log('Previous Form Data:', prevFormData);
            return {
                ...prevFormData,
                state: selectedState
            };
        });
    
        // Set the state code for API submission
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                stateCode: stateCode
            };
        });
    
        // Update cities based on the state code
        const cities = stateCityMap[stateCode] || [];
        setCities(cities);
    };

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        setCity(selectedCity);
        setFormData((prevFormData) => ({
            ...prevFormData,
            city: selectedCity
        }));

        const stateCode = stateCodeMap[state];
        const person = stateCityPersonMap[stateCode]?.[selectedCity] || '';
        setPersonInCharge(person);
        setFormData((prevFormData) => ({
            ...prevFormData,
            personInCharge: person
        }));
    };

    const fetchSalesEmpCode = async (id) => {
        try {
          const response = await fetch(`https://api.chesadentalcare.com/salesEmpCode?id=${id}`);
          const data = await response.json();
          return data.code; // Adjust this according to your actual response structure
        } catch (error) {
          console.error('Error fetching salesEmpCode:', error);
          throw new Error('Failed to fetch salesEmpCode');
        }
      };

    const transformFormData = async(formData) => {
        // Ensure product1 and product2 are strings
        const employeeID = sessionStorage.getItem('employeeID');
        const product1 = Array.isArray(formData.product1)
            ? formData.product1.map(option => option.value).join(', ')
            : String(formData.product1);
        const product2 = Array.isArray(formData.product2)
            ? formData.product2.map(option => option.value).join(', ')
            : String(formData.product2);

            const salesEmpCode = await fetchSalesEmpCode(employeeID);

        return {
            name: formData.customerName,
            email: formData.email,
            phone: formData.phoneNumber,
            address: formData.address,
            employeeId: employeeID,
            pincode: formData.pincode,
            city: formData.city,
            state: formData.state,
            stateCode: formData.stateCode,
            category: formData.category,
            interest: formData.interestLevel, // Mapping interestLevel to interest
            source: formData.source,
            expdate: formData.closingDate, // Assuming expdate maps to closingDate
            price: formData.expectedValue, // Assuming price maps to expectedValue
            sname: formData.personInCharge, // Assuming sname maps to personInCharge
            product1, // Ensure product1 is a string
            product2, 
            salesEmp: salesEmpCode
        };
    };


    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior
        const transformedData = await transformFormData(formData);
        console.log('data', transformedData);
    
        toast.promise(
            fetch('https://api.chesadentalcare.com/new_lead_entry_test', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add other headers if needed
                },
                body: JSON.stringify(transformedData),
            })
            .then(async (response) => {
                const result = await response.json();
                if (response.ok) {
                    
                    resetForm(); // Clear the form after successful submission
                   window.location.href= "/new-order-form ";
                    return 'Form submitted successfully!';
                } else {
                    throw new Error(result.message || 'Something went wrong');
                }
            }),
            {
                loading: 'Submitting...',
                success: (msg) => `${msg}`,
                error: (err) => `Error: ${err.message}`,
            }
        );
        handleCloseClick();
    };

    const resetForm = () => {
        setFormData({
            customerName: '',
            email: '',
            phoneNumber: '',
            category: '',
            state: '',
            city: '',
            pincode: '',
            address: '',
            product1: [],
            product2: [],
            interestLevel: '',
            source: '',
            closingDate: '',
            expectedValue: '',
            personInCharge: ''
        });
    };

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch('https://api.chesadentalcare.com/crmpro'); // Replace with your API endpoint
                const data = await response.json();

                // Assuming your API returns an array of products with 'id' and 'pname' fields
                const options = data.map(product => ({
                    value: product.id,
                    label: product.pname
                }));

                setProductOptions(options);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching products:', error);
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'white',
            borderRadius: '4px',
            border: '1px solid #ced4da'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007bff' : 'white',
            color: state.isSelected ? 'white' : 'black'
        })
    };

    const handleProductChange = (selectedOptions, name) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: selectedOptions
        }));
    };

    return (
        <>
                    <div className="enter-leads-modal-container">
                    <span className="close-lead-modal-button" onClick={handleCloseClick}>×</span>
                    <h2 style={{position: 'absolute',top: '10px', color: 'black' }}>Add Quick Lead </h2>
                        <form onSubmit={handleSubmit} className="form-modal-container">
                            <div className="form-modal-groupleads">

                                <label>
                                    Customer Name:
                                    <input
                                        type="text"
                                        name="customerName"
                                        value={formData.customerName}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Email:
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-group">
                                <label>
                                    Phone Number: (Dont include +91)
                                    <div className="error-message">
            {errors.phoneNumber && <span style={{ color: 'red' }}>{errors.phoneNumber}</span>}
        </div>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        required
                                       pattern="[0-9]{10}"      
                                       maxLength="10"  
                                       minLength="10"       
                                       title="Please enter exactly 10 numeric digits."
                                    />
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Category:
                                    <select name="category" value={formData.category} onChange={handleChange} required>
                                        <option value="">Select One</option>
                                        <option value="Indian Products">Indian Products</option>
                                        <option value="Imported Products">Imported Products</option>
                                        <option value="Radiology">Radiology</option>
                                        <option value="Accessories">Accessories</option>

                                    </select>
                                </label>
                            </div>
                            <div className="form-modal-groupleads half-width">
                                <label>
                                    State:
                                    <select name="state" value={formData.state} onChange={handleStateChange} required>
                                        <option value="">Select a state</option>
                                        {Object.keys(stateCodeMap).map((state) => (
                                            <option key={state} value={state}>{state}</option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                            <div className="form-modal-groupleads half-width">
                                <label>
                                    City:
                                    <select name="city" value={formData.city} onChange={handleCityChange} required>
                                        <option value="">Select a city</option>
                                        {cities.map((city) => (
                                            <option key={city} value={city}>{city}</option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                            <div className="form-modal-groupleads half-width">
                                <label >Pincode:
                                    <input
                                        type="pincode"
                                        name="pincode"
                                        value={formData.pincode}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <div className="form-modal-groupleads full-width">
                                <label>
                                    Address:
                                    <input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                        required
                                        maxLength={100}
                                    />
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Product 1:
                                    <Select
                                        name="product1"
                                        value={formData.product1}
                                        onChange={(selectedOptions) => setFormData({ ...formData, product1: selectedOptions })}
                                        options={productOptions}
                                        isMulti
                                        styles={customStyles}
                                    />
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Product 2:
                                    <Select
                                        name="product2"
                                        value={formData.product2}
                                        onChange={(selectedOptions) => setFormData({ ...formData, product2: selectedOptions })}
                                        options={productOptions}
                                        isMulti
                                        styles={customStyles}
                                    />
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Interest Level:
                                    <select id="select_page" name='interestLevel' value={formData.interestLevel} onChange={handleInterestChange} required>
                                        <option value="">Select one</option>
                                        {interestLevels.map((interestLevel) => (
                                            <option key={interestLevel.value} value={interestLevel.value}>{interestLevel.label}</option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Source:
                                    <select id="select_page" className="form-control" name="source" value={formData.source} onChange={handleSourceChange} required>
                                        <option value="">Select One</option>
                                        {sources.map((source) => (
                                            <option key={source.value} value={source.value}>{source.label}</option>
                                        ))}
                                    </select>
                                </label>
                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Closing Date:
                                    <input
                                        type="date"
                                        name="closingDate"
                                        value={formData.closingDate}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>

                            </div>
                            <div className="form-modal-groupleads">
                                <label>
                                    Expected Value:
                                    <input
                                        type="number"
                                        name="expectedValue"
                                        value={formData.expectedValue}
                                        onChange={handleChange}
                                        required
                                    />
                                </label>
                            </div>
                            <button type="submit" className="submit-button" >Submit Lead</button>
                        </form>
                    </div>
        </>

    );
};

export default EnterLeadsModal;
