import React, { useState, useEffect, useCallback } from 'react';
import Sidebar from '../../Sidebar/Sidebar'; // Adjust the import path as necessary
import { useSidebar } from '../../../ContextAPI/SidebarContext';
import { ExternalLink, X, ChevronLeft, ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import './OpenLeads.css'; // Import CSS file for component styling
import NotificationButton from '../../../Notification/NotificationButton';
import axios from 'axios';
import { FaExclamationTriangle } from 'react-icons/fa'; // Import the error icon
import { useAuth } from '../../../ContextAPI/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formControlClasses } from '@mui/material';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx';
import { ConsoleSqlOutlined } from '@ant-design/icons';


const OpenLeads = () => {
    const { isSidebarOpen } = useSidebar();
    const [leads, setLeads] = useState([]);
    const [sortBy, setSortBy] = useState('SequentialNo'); // Default sorting by SequentialNo
    const [sortOrder, setSortOrder] = useState('asc'); // Default sorting order is ascending
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [entriesPerPage] = useState(8);
    const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
    const [isLossDialogOpen, setIsLossDialogOpen] = useState(false);
    const [isWonDialogOpen, setIsWonDialogOpen] = useState(false);
    const [selectedLead, setSelectedLead] = useState(null);
    const [lossRemarks, setLossRemarks] = useState('');
    const [remarks, setRemarks] = useState('');
    const [lossType, setLossType] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("");
    const [wonType, setWonType] = useState('');
    const navigate = useNavigate();
    const [expandedRows, setExpandedRows] = useState([]);
    const [salesOpportunity, setSalesOpportunity] = useState({});
    const [followUpDate, setFollowUpDate] = useState('');
    const [followUpAction, setFollowUpAction] = useState('');
    const [salesEmpCode, setSalesEmpCode] = useState(null);
    const [salesId, setSalesId] = useState(null)
    const salesEmpCache = {};
    const { user, token } = useAuth();
    const [filterType, setFilterType] = useState('all'); // 'all' or 'old'
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!user) {
            navigate('/');
        } else {

        }
    }, [user, token, navigate]);

    const stageKeyDefinitions = {
        1: "Cold",
        2: "Warm",
        3: "Hot",
        4: "Closed",
        5: "First Call",
        6: "First Meeting",
        7: "Quotation",
        8: "Negotiation",
        9: "Payment Confirmation",
        10: "Order Confirmed",
        11: "Second Meeting",
        12: "Revision Of Products",
        13: "Second Call",
        14: "Third Call",
        15: "Lost",
    };


    // Mapping of state codes to full names
    const stateMapping = {
        AP: 'Andhra Pradesh',
        AR: 'Arunachal Pradesh',
        AS: 'Assam',
        BR: 'Bihar',
        CG: 'Chhattisgarh',
        CH: 'Chandigarh',
        DD: 'Daman and Diu',
        DL: 'Delhi',
        DN: 'Dadra and Nagar Haveli',
        GA: 'Goa',
        GJ: 'Gujarat',
        HR: 'Haryana',
        HP: 'Himachal Pradesh',
        JK: 'Jammu and Kashmir',
        JH: 'Jharkhand',
        KT: 'Karnataka',
        KL: 'Kerala',
        LD: 'Lakshadweep',
        MP: 'Madhya Pradesh',
        MH: 'Maharashtra',
        MN: 'Manipur',
        ML: 'Meghalaya',
        MZ: 'Mizoram',
        NL: 'Nagaland',
        NP: 'Nepal',
        OD: 'Odisha',
        PN: 'Punjab',
        PY: 'Puducherry',
        RJ: 'Rajasthan',
        SK: 'Sikkim',
        TN: 'Tamil Nadu',
        TG: 'Telangana',
        TR: 'Tripura',
        UK: 'Uttarakhand',
        UP: 'Uttar Pradesh',
        WB: 'West Bengal'
    };

    // Mapping of lead sources
    const sourceMapping = {
        1: 'Phone',
        2: 'Direct',
        3: 'Social Media',
        4: 'Employee Lead',
        5: 'Organic Lead',
        6: 'House Of Alt',
        7: 'Service Team Lead',
        8: 'CRM Lead',
        9: 'Expo',
        10: 'May 24 Forecast'
    };

    // Mapping of interest levels
    const interestMapping = {
        '-1': 'Cold',
        '1': 'Warm',
        '2': 'Hot',
        '3': 'Very Hot',
        '4': 'Very HOT'
    };

    const statusMapping = {
        'O': 'Open'
    };


    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(amount);
    };

    const toggleFollowUp = async (seqNo) => {
        if (expandedRows.includes(seqNo)) {
            setExpandedRows(expandedRows.filter(row => row !== seqNo));
            setSalesOpportunity({}); // Clear sales opportunity data when hiding
        } else {
            setExpandedRows([...expandedRows, seqNo]);
            try {
                const response = await fetch(
                    `https://api.chesadentalcare.com/sales_opportunity/${seqNo}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch sales opportunity details");
                }
                const data = await response.json();
                setSalesOpportunity(data);
                console.log("data", data);
                console.log("salesOpportunity", data);
                if (data.SalesOpportunitiesLines.length > 0) {

                    const initialRemarks = data.SalesOpportunitiesLines.map(line => line.Remarks || '');
                    setRemarks(initialRemarks);

                    const lastEntry =
                        data.SalesOpportunitiesLines[
                        data.SalesOpportunitiesLines.length - 1
                        ];
                    setFollowUpAction(lastEntry.StageKey);
                    setFollowUpDate(
                        lastEntry.ClosingDate ? lastEntry.ClosingDate.split("T")[0] : ""
                    );
                }
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching sales opportunity lines:', error);
                setSalesOpportunity({}); // Reset sales opportunity on error
            }
        } ``
    };

    const handleFollowUpDateChange = (e) => {
        setFollowUpDate(e.target.value);
    };

    const handleFollowUpActionChange = (e) => {
        setFollowUpAction(e.target.value);
    };

    const isFollowUpOverdue = (predictedClosingDate) => {
        if (!predictedClosingDate) return false;

        const today = new Date();
        const closingDate = new Date(predictedClosingDate);

        return closingDate < today;
    };

    const handleRemarksChange = (value, lineIndex) => {
        const newRemarks = [...remarks];
        newRemarks[lineIndex] = value;
        setRemarks(newRemarks);
    };


    const handleViewClick = (lead) => {
        navigate(`/lead/${lead.SequentialNo}`, { state: { lead } });
    };

    const handleLossClick = (lead) => {
        setSelectedLead(lead);
        setLossRemarks(lead.Remarks || ''); // Set the default value of the textarea
        setIsLossDialogOpen(true);
    };

    const handleLossSubmit = () => {
        // Handle the loss submission, e.g., send to server or update state
        console.log('Loss details submitted:', {
            ...selectedLead,
            lossRemarks,
            lossType
        });
        closeDialog();
    };

    const handleWonClick = (lead) => {
        setSelectedLead(lead);
        setIsWonDialogOpen(true);
    };

    const updateLead = async () => {
        try {
            const updatedLead = {
                ...lead,
                SalesOpportunitiesLines: salesOpportunity.SalesOpportunitiesLines
            };
            const response = await axios.put(`https://api.chesadentalcare.com/sales_opportunity/${SequentialNo}`, updatedLead);
            if (response.status === 200) {
                alert('Lead updated successfully');
            } else {
                throw new Error('Failed to update lead');
            }
        } catch (error) {
            alert(`Error updating lead: ${error.message}`);
        }
    };

    const handleWonSubmit = () => {
        // Handle the won submission, e.g., send to server or update state
        console.log('Won details submitted:', {
            ...selectedLead,
            wonType
        });
        closeDialog();
    };

    const closeDialog = () => {
        setIsViewDialogOpen(false);
        setIsLossDialogOpen(false);
        setIsWonDialogOpen(false);
        setSelectedLead(null);
    };

    const fetchLeads = async () => {
        try {
            const response = await fetch('https://api.chesadentalcare.com/open_leads');
            if (!response.ok) {
                throw new Error('Failed to fetch leads data');
            }
            const data = await response.json();

            const extractedLeads = data.value.map(item => ({
                SequentialNo: item.SalesOpportunities.SequentialNo,
                StartDate: item.SalesOpportunities.StartDate,
                InterestLevel: interestMapping[item.SalesOpportunities.InterestLevel] || item.SalesOpportunities.InterestLevel,
                MaxLocalTotal: item.SalesOpportunities.MaxLocalTotal,
                Status: statusMapping[item.SalesOpportunities.Status] || item.SalesOpportunities.Status,
                PredictedClosingDate: item.SalesOpportunities.PredictedClosingDate,
                DataOwnershipfield: item.SalesOpportunities.DataOwnershipfield,
                CardCode: item.SalesOpportunities.CardCode,
                Remarks: item.SalesOpportunities.Remarks || 'N/A',
                Source: sourceMapping[item.SalesOpportunities.Source] || item.SalesOpportunities.Source,
                CardName: item.BusinessPartners.CardName,
                Cellular: item.BusinessPartners.Cellular,
                City: item.BusinessPartners.City,
                BillToState: stateMapping[item.BusinessPartners.BillToState] || item.BusinessPartners.BillToState,
                Address: item.BusinessPartners.Remarks,
                SalesEmpId: item.SalesOpportunities.DataOwnershipfield
            }));




            const validSalesEmpIds = extractedLeads.map(lead => lead.SalesEmpId).filter(id => id !== undefined && id !== null);
            const uniqueSalesEmpIds = [...new Set(validSalesEmpIds)];
            const salesEmpMap = {};

            for (const id of uniqueSalesEmpIds) {
                if (salesEmpCache[id]) {
                    salesEmpMap[id] = salesEmpCache[id];
                } else {
                    const salesEmpResponse = await fetch(`https://api.chesadentalcare.com/sales_emp/${id}`);
                    if (!salesEmpResponse.ok) {
                        throw new Error(`Failed to fetch sales employee data for ID: ${id}`);
                    }
                    const salesEmpData = await salesEmpResponse.json();
                    salesEmpMap[id] = salesEmpData.SalesEmpName;
                    salesEmpCache[id] = salesEmpData.SalesEmpName;
                }
            }

            const ordersWithSalesEmpNames = extractedLeads.map(lead => ({
                ...lead,
                salesEmployeeName: salesEmpMap[lead.SalesEmpId] || 'Unknown'
            }));

            setLeads(ordersWithSalesEmpNames);
            console.log("ordersWithSalesEmpNames", ordersWithSalesEmpNames)
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setIsLoading(false);
            setError('Failed to fetch orders. Please try again later.');
        }
    };

    useEffect(() => {
        fetchLeads();
    }, []);

    const sortLeads = (leads, sortBy, sortOrder) => {
        return leads.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });
    };

    const toggleSortOrder = (key) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        setSortBy(key);
        setLeads(sortLeads([...leads], key, newSortOrder));
    };

    const nowDate = new Date();
    const formattedDate = `${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const totalPages = Math.ceil(leads.length / entriesPerPage);

    // Filter leads based on the selected filter type
    const filteredLeads = leads.filter(lead => {
        const isOldEntry = new Date(lead.PredictedClosingDate) < new Date(formattedDate);
        if (filterType === 'old') {
            return isOldEntry;
        } else {
            return true; // For 'all' filter
        }
    }).filter(lead =>
        lead.SequentialNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.salesEmployeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.CardName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastLead = currentPage * entriesPerPage;
    const indexOfFirstLead = indexOfLastLead - entriesPerPage;
    const currentLeads = filteredLeads.slice(indexOfFirstLead, indexOfLastLead);

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredLeads.length / entriesPerPage);
        const displayPages = 3;

        const generatePaginationButtons = () => {
            let buttons = [];
            if (totalPages <= 1) return buttons;

            if (currentPage > 1) {
                buttons.push(
                    <button key="prev" onClick={() => handlePageChange(currentPage - 1)}>
                        Prev
                    </button>
                );
            }

            for (let i = 1; i <= totalPages; i++) {
                if (i <= displayPages || i > totalPages - displayPages || (i >= currentPage - 1 && i <= currentPage + 1)) {
                    buttons.push(
                        <button
                            key={i}
                            onClick={() => handlePageChange(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </button>
                    );
                } else if (
                    (i === displayPages + 1 && currentPage > displayPages + 1) ||
                    (i === totalPages - displayPages && currentPage < totalPages - displayPages)
                ) {
                    buttons.push(<span key={i}>...</span>);
                }
            }

            if (currentPage < totalPages) {
                buttons.push(
                    <button key="next" onClick={() => handlePageChange(currentPage + 1)}>
                        Next
                    </button>
                );
            }

            return buttons;
        };

        const paginationButtons = generatePaginationButtons();

        return (
            <div className="pagination">
                {paginationButtons}
            </div>
        );
    };


    // console.log('Formatted Date:', formattedDate); 

    const getLastIndex = () => {
        // Get the last index of the SalesOpportunitiesLines array
        return salesOpportunity?.SalesOpportunitiesLines.length;
    };






    const fetchSalesEmpCode = async (id) => {
        try {
            const response = await fetch(`https://api.chesadentalcare.com/salesEmpCode?id=${id}`);
            const data = await response.json();
            return data.code; // Adjust this according to your actual response structure
        } catch (error) {
            console.error('Error fetching salesEmpCode:', error);
            throw new Error('Failed to fetch salesEmpCode');
        }
    };

    const patchFollowUp = async (lineNumber, remarks, SequentialNo, DataOwnershipfield) => {
        let loadingToastId;
        try {
            console.log("line number", lineNumber, remarks, SequentialNo, DataOwnershipfield);
            const lastLineIndex = getLastIndex();
            const lastLine = salesOpportunity.SalesOpportunitiesLines[salesOpportunity.SalesOpportunitiesLines.length - 1];
            console.log("formattedDate", lastLine);
            const formattedDate = formatDatePlusOne(lastLine.ClosingDate);

            const isoDate = new Date(followUpDate).toISOString();
            const newDate = `${isoDate.split('T')[0]}T00:00:00Z`;

            // Show loading toast
            loadingToastId = toast.loading("Updating follow-up details...");

            // Fetch salesEmpCode before patching
            const salesEmpCode = await fetchSalesEmpCode(DataOwnershipfield);

            const arr = [
                {
                    sequenceId: SequentialNo,
                    LineNumber: lastLineIndex,
                    sales_emp: salesEmpCode, // Use the fetched salesEmpCode
                    startDate: lastLine.ClosingDate,
                    closeDate: newDate,
                    stageKey: lineNumber.StageKey,
                    totalPrice: lineNumber.MaxLocalTotal,
                    remarks: remarks,
                    DataOwnershipfield: DataOwnershipfield
                }
            ];

            console.log(arr)

            const response = await axios.patch(
                'https://api.chesadentalcare.com/patch_followup',
                arr
            );

            if (response.status === 200) {
                toast.success('Follow-up details updated successfully', { id: loadingToastId });
                // Optionally update state or perform any necessary actions upon success
                setRemarks('')
                await toggleFollowUp(SequentialNo);
            } else {
                throw new Error('Failed to update follow-up details');
            }
        } catch (error) {
            toast.error(`Error updating follow-up details: ${error.message}`, { id: loadingToastId });
        }
    };


    const formatDatePlusOne = (dateString) => {
        // Parse the date string into a Date object
        const date = new Date(dateString);

        // Add one day to the date
        date.setDate(date.getDate() + 1);

        // Format the date as YYYY-MM-DD
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const handleExport = () => {
        if (!Array.isArray(leads) || leads.length === 0) {
            console.error('No data to export or data is not an array');
            return;
        }

        const formattedData = leads.map(lead => ({
            SequentialNo: lead.SequentialNo,
            StartDate: lead.StartDate,
            SalesEmployeeName: lead.salesEmployeeName,
            PredictedClosingDate: lead.PredictedClosingDate,
            InterestLevel: lead.InterestLevel,
            CardName: lead.CardName,
            Cellular: lead.Cellular,
            City: lead.City,
            BillToState: lead.BillToState,
            Address: lead.Address,
            Remarks: lead.Remarks,
            MaxLocalTotal: lead.MaxLocalTotal,
            Status: lead.Status,
        }));

        // Create a new worksheet
        const worksheet = XLSX.utils.json_to_sheet(formattedData);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');

        // Export the workbook to an Excel file
        XLSX.writeFile(workbook, 'leads.xlsx');
    };


    return (
        <>
            <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)' }}>
                <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start', position: 'relative', top: '15px' }}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '23px' }}>
                        Navigate to Chesa Services
                        <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
                            <ExternalLink size={20} />
                        </a>
                        <NotificationButton />
                    </p>

                </div>
                <div className="header">
                    <h1 className="company-name1" style={{ position: 'relative', top: '5px', left: '23px' }}>Open Leads</h1><br />
                    <div style={{ position: "relative", right: "5%" }}>
                        {/* <ModalWrapper handleFilter={handleFilter} /> */}
                    </div>
                </div>
                <Sidebar />
                <div className="main-content" style={{ left: "87%" }}>
                    <div className='search-filter-container'>
                        <div className='filter-oldornew'>
                            <button
                                className={filterType === 'all' ? 'active-filter' : ''}
                                onClick={() => setFilterType('all')}
                            >
                                All Entries
                            </button>
                            <button
                                className={filterType === 'old' ? 'active-filter' : ''}
                                onClick={() => setFilterType('old')}
                            >
                                Due Leads
                            </button>
                        </div>
                        <div className="search-bar-container" style={{ position: 'relative', left: '95vh', marginTop: '0px', margin: '0px' }}>
                            <input
                                type="text"
                                placeholder=" Search by SequentialNo..."
                                className="search-bar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            {searchTerm && (
                                <X
                                    className="clear-icon"
                                    onClick={() => setSearchTerm('')}
                                />
                            )}
                        </div>
                        <button onClick={handleExport} className="export-button" style={{ marginLeft: '230px' }}>
                            <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: '8px' }} />
                            Export
                        </button>
                    </div>
                    <div className="leads-table">
                        <table className='leads-table'>
                            <thead>
                                <tr>
                                    <th onClick={() => toggleSortOrder('SequentialNo')}>
                                        Sequential No
                                        {sortBy === 'SequentialNo' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('StartDate')}>
                                        Start Date {sortBy === 'StartDate' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('salesEmployeeName')}>
                                        Sales Emp Name {sortBy === 'salesEmployeeName' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('PredictedClosingDate')}>
                                        Predicted Closing Date {sortBy === 'PredictedClosingDate' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('InterestLevel')}>
                                        Interest Level {sortBy === 'InterestLevel' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('CardName')}>
                                        Customer Name {sortBy === 'CardName' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('Cellular')}>
                                        Phone {sortBy === 'Cellular' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('City')}>
                                        City {sortBy === 'City' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('BillToState')}>
                                        State {sortBy === 'BillToState' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('Remarks')}>
                                        Remarks {sortBy === 'Remarks' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('MaxLocalTotal')}>
                                        Expected Price {sortBy === 'MaxLocalTotal' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th onClick={() => toggleSortOrder('Status')}>
                                        Status {sortBy === 'Status' && (sortOrder === 'asc' ? '↑' : '↓')}
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan="13">
                                            <div className="spinner"></div>
                                        </td>
                                    </tr>
                                ) : error ? (
                                    <tr>
                                        <td colSpan="13">{error}</td>
                                    </tr>
                                ) : currentLeads.length === 0 ? (
                                    <tr>
                                        <td colSpan="13">No leads found.</td>
                                    </tr>
                                ) : (
                                    currentLeads.map(lead => (
                                        <React.Fragment key={lead.SequentialNo}>
                                            <tr>
                                                <td>
                                                    <div className="table-header">
                                                        {lead.SequentialNo}
                                                        {new Date(lead.PredictedClosingDate) < new Date(formattedDate) ? <FaExclamationTriangle className="error-icon" /> : ""}
                                                        <button className="toggle-follow-up-button" onClick={() => toggleFollowUp(lead.SequentialNo)}>
                                                            {expandedRows.includes(lead.SequentialNo) ? (
                                                                <>
                                                                    <span>&#9660;</span> {/* Down Chevron */}

                                                                </>
                                                            ) : (
                                                                <>
                                                                    <span>&#9654;</span> {/* Right Chevron */}

                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </td>
                                                <td>{lead.StartDate}</td>
                                                <td>{lead.salesEmployeeName}</td>
                                                <td>{lead.PredictedClosingDate}</td>
                                                <td>{lead.InterestLevel}</td>
                                                <td>{lead.CardName}</td>
                                                <td>{lead.Cellular}</td>
                                                <td>{lead.City}</td>
                                                <td>{lead.BillToState}</td>
                                                <td>{lead.Remarks}</td>
                                                <td>{formatCurrency(lead.MaxLocalTotal)}</td>
                                                <td>{lead.Status}</td>
                                                <td>
                                                    <div className="action-icons2">
                                                        <div className="action-icon2" title="View" style={{ cursor: "pointer" }}>
                                                            <button onClick={() => handleViewClick(lead)} className="action-button">View</button>
                                                        </div>
                                                        {/* <div className="action-icon2" title="Loss" style={{ cursor: "pointer" }}>
                                                            <button onClick={() => handleLossClick(lead)} className="action-button">Loss</button>
                                                        </div>
                                                        <div className="action-icon2" title="Won" style={{ cursor: "pointer" }}>
                                                            <button onClick={() => handleWonClick(lead)} className="action-button">Won</button>
                                                        </div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                            {expandedRows.includes(lead.SequentialNo) && salesOpportunity && salesOpportunity.SalesOpportunitiesLines && (
                                                <tr>
                                                    <td colSpan="13">
                                                        <table className="sales-opportunity-lines">
                                                            <thead>
                                                                <tr>
                                                                    <th>Order ID</th>
                                                                    <th>Start Date</th>
                                                                    <th>Follow-Up Date</th>
                                                                    <th>Value</th>
                                                                    <th>Stage Key</th>
                                                                    <th>Status</th>
                                                                    <th>Remarks</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {salesOpportunity.SalesOpportunitiesLines.map((line, index) => (

                                                                    <tr key={index}>
                                                                        <td>{line.LineNum}</td>
                                                                        <td>{line.StartDate.split("T")[0]}</td>
                                                                        <td>
                                                                            {index === salesOpportunity.SalesOpportunitiesLines.length - 1 ? (
                                                                                <input
                                                                                    type="date"
                                                                                    value={followUpDate}
                                                                                    onChange={handleFollowUpDateChange}
                                                                                    min={ line.ClosingDate.split("T")[0]  > new Date().toISOString().split("T")[0] ? line.ClosingDate.split("T")[0] : new Date().toISOString().split("T")[0]} // Set the min attribute
                                                                                />
                                                                            ) : line.ClosingDate ? (
                                                                                line.ClosingDate.split("T")[0]
                                                                            ) : (
                                                                                "-"
                                                                            )}
                                                                        </td>
                                                                        <td>{line.MaxLocalTotal}</td>
                                                                        <td>
                                                                            {index === salesOpportunity.SalesOpportunitiesLines.length - 1 ? (
                                                                                <select
                                                                                    value={followUpAction}
                                                                                    onChange={handleFollowUpActionChange}
                                                                                >
                                                                                    {Object.entries(stageKeyDefinitions).map(([key, value]) => (
                                                                                        <option key={key} value={key}>{value}</option>
                                                                                    ))}
                                                                                </select>
                                                                            ) : (
                                                                                line.StageKey ? stageKeyDefinitions[line.StageKey] : '-'
                                                                            )}
                                                                        </td>
                                                                        <td>{line.Status ? line.Status.replace('so_', '') : '-'}</td>
                                                                        <td>
                                                                            {index === salesOpportunity.SalesOpportunitiesLines.length - 1 ? (
                                                                                <textarea

                                                                                    onChange={(e) => handleRemarksChange(e.target.value, index)}
                                                                                    value={remarks[index]}

                                                                                    rows="4"
                                                                                    cols="10"
                                                                                />
                                                                            ) : (
                                                                                remarks[index] === '' ? "No Remarks" : remarks[index]
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {index === salesOpportunity.SalesOpportunitiesLines.length - 1 ? (
                                                                                <button onClick={() => patchFollowUp(line, remarks[remarks.length - 1], lead.SequentialNo, lead.DataOwnershipfield)} className='action-button'>
                                                                                    Update Follow-Up
                                                                                </button>
                                                                            ) : (
                                                                                // line.Status ? line.Status.replace('so_', '') : '-'
                                                                                "Stage Closed"
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                        </table>
                        {renderPagination()}
                    </div>
                    {isLossDialogOpen && (
                        <div className="dialog-overlay">
                            <div className="dialog">
                                <span className="close-lead-button" onClick={closeDialog}>&times;</span>
                                <div className="dialog-header">
                                    <h2>Update Lead Details</h2>

                                </div>
                                <label>
                                    Remarks:
                                    <textarea
                                        value={lossRemarks}
                                        onChange={(e) => setLossRemarks(e.target.value)}
                                        rows="4"
                                        cols="50"
                                    />
                                </label>
                                <div>
                                    <label>
                                        Lost Type:
                                        <div>
                                            <select
                                                value={lossType}
                                                onChange={(e) => setLossType(e.target.value)}
                                            >
                                                <option value="">Choose One</option>
                                                <option value="Reason 1">Lost</option>
                                                <option value="Reason 2">Confident</option>
                                                <option value="Reason 3">Unicorn</option>
                                                <option value="Reason 4">Bestodent</option>
                                                <option value="Reason 5">Suzident</option>
                                                <option value="Reason 6">Local Chair</option>
                                                {/* Add more options as needed */}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                                <button className="dialogue-button" onClick={handleLossSubmit}>Submit</button>
                                <button className="dialogue-button" onClick={closeDialog}>Cancel</button>
                            </div>
                        </div>
                    )}

                    {isWonDialogOpen && (
                        <div className="dialog-overlay">
                            <div className="dialog">
                                <div>
                                    <span className="close-lead-button" onClick={closeDialog}>&times;</span>

                                    <h2>Update Lead Details</h2>


                                    <label>
                                        Document Type:
                                        <select
                                            value={lossType}
                                            onChange={(e) => setWonType(e.target.value)}
                                        >
                                            <option value="">Choose One</option>
                                            <option value="Reason 1">Sales Quotation</option>
                                            <option value="Reason 2">Sales Order</option>

                                            {/* Add more options as needed */}
                                        </select>
                                    </label>
                                </div>
                                <label>
                                    Remarks:
                                    <textarea
                                        placeholder=""

                                        rows="4"
                                        cols="50"
                                    />
                                </label>
                                <button className="dialogue-button" onClick={handleLossSubmit}>Submit</button>
                                <button className="dialogue-button" onClick={closeDialog}>Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default OpenLeads;
