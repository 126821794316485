import React, { useEffect, useState } from 'react';
import { ExternalLink, X } from 'react-feather';
import './OurTopCompanies.css';
import { useSidebar } from '../../../ContextAPI/SidebarContext';
import Sidebar from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import NotificationButton from '../../../Notification/NotificationButton';
import { useAuth } from '../../../ContextAPI/AuthContext';

const OurTopCompanies = () => {
  const navigate = useNavigate();
  const { user,token } = useAuth();
  const [companies, setCompanies] = useState([]);
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  useEffect(() => {
    if (!user) {
        navigate('/');
    } else {
        
    }
}, [user, token, navigate]);
  console.log(companies);

  const baseURL = 'https://chesadentalcare.com/possystem/admin/admin/uploads/dealers/';

  useEffect(() => {
    // Fetch the data from the API
    fetch('https://api.chesadentalcare.com/company')  // Replace with your actual API endpoint
      .then(response => response.json())
      .then(data => setCompanies(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleImageClick = (name) => {
    // Navigate to the company page
    navigate(`/purchase/${name}`);
  };

  const handleViewMoreClick = (name) => {
    navigate(`/purchase/${name}`);
  };

  const handleButtonClick = (event, companyId) => {
    // Prevent the event from propagating to the image click handler
    event.stopPropagation();
    // Perform the button action (e.g., navigate to a different page)
    window.location.href = `/purchase/${companyId}`;
  };

  return (
    <>
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)'}}>
        <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start', position: 'relative', top: '15px' }}>
            <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '23px' }}>
                Navigate to Chesa Services
                <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
                    <ExternalLink size={20} />
                </a>
                <span className='notibu'><NotificationButton /></span>
            </p>
        </div>
        <div className="header">
            <h1 className="company-name1" style={{position: 'relative', top: '5px', left: '23px'}}>Our Top Companies</h1><br />
            <div style={{ position: "relative", right: "5%" }}>
                {/* <ModalWrapper handleFilter={handleFilter} /> */}
            </div>
        </div>
        <Sidebar />
        <div className="main-content" style={{ left: "87%" }}>
      <div className="top-companies-header">       
      </div>
      <div className="top-companies-container">
        <div className="top-companies">
          {companies.map(company => (
            <div key={company.company} className="company-card" onClick={() => handleImageClick(company.company)}>
              <div className="image-container">
                <img src={`${baseURL}${company.image}`} alt={company.company} className="company-image" />
                <div className="overlay"></div>
                <button className="company-image-button1" onClick={(event) => handleViewMoreClick(company.company)}>View More</button>
              </div>
              <h3 className="company-name">{company.company}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default OurTopCompanies;
