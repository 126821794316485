import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useCart } from "../ContextAPI/CartContext";

const AuthContext = createContext();


const isSessionExpired = () => {
 
  const expiryTime = sessionStorage.getItem('expiry');
  if (!expiryTime) return true;
  return new Date().getTime() > new Date(expiryTime).getTime();
};

export const AuthProvider = ({ children }) => {
  const { clearCart } = useCart();
  const [user, setUser] = useState(() => {
    const storedUser = sessionStorage.getItem('user');
    return storedUser && !isSessionExpired() ? JSON.parse(storedUser) : null;
  });
  const [token, setToken] = useState(() => {
    return sessionStorage.getItem('token') && !isSessionExpired() ? sessionStorage.getItem('token') : null;
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isSessionExpired()) {
      setUser(null);
      setToken(null);
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('expiry');
      sessionStorage.removeItem('id');
      sessionStorage.removeItem('d_code');
      sessionStorage.removeItem('role');
      sessionStorage.removeItem('sales_person');
    } else {
      // Restore specific details from session storage if user exists
      const storedId = sessionStorage.getItem('id');
      const storedDCode = sessionStorage.getItem('d_code');
      const storedBName = sessionStorage.getItem('role');
      const storedSalesPerson = sessionStorage.getItem('sales_person');
      setUser((prevUser) => ({
        ...prevUser,
        id: storedId,
        d_code: storedDCode,
        bname: storedBName,
        sales_person: storedSalesPerson // Ensure sales_person is restored
      }));
    }
  }, []);

  const login = async (credentials, navigate) => {
    try {
      setLoading(true);
      setError(null);

      const response = await fetch('https://api.chesadentalcare.com/login_sales', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();
      console.log('login data',data);
      // return

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }
      const dataAllnew = JSON.stringify(data.dataAll);
      const SapData = JSON.stringify(data.sapData);

      setUser(data.user); // Store all user details received from backend
      setToken(data.token);
      sessionStorage.setItem('user', JSON.stringify(data.user));
      sessionStorage.setItem('token', data.token);
      sessionStorage.setItem('sales_person', data.user.sales_person);
      sessionStorage.setItem('DealerData', dataAllnew)
      sessionStorage.setItem('SapDealerData', SapData)
      sessionStorage.setItem('employeeID', data.user.employeeid)


      const expiryTime = new Date(new Date().getTime() + 60 * 60 * 1000); // 1 hour from now
      sessionStorage.setItem('expiry', expiryTime.toISOString());

      toast.promise(
        new Promise((resolve) => {
          setTimeout(() => {
            window.location.href = '/dealer';
            resolve();
          }, 1100);
        }),
        {
          loading: 'Logging in...',
          success: 'Login successful!',
          error: 'Something went wrong!',
        }
      );
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const logout = (navigate) => {
    navigate('/')
    setUser(null);
    setToken(null);
    clearCart();
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('expiry');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('d_code');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('sales_person');
    sessionStorage.removeItem('selectedDealerId');
    sessionStorage.removeItem('employeeID');
    toast.success('Logged out successfully');
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout, loading, error }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);
