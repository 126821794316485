import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../../Sidebar/Sidebar';
import { useAuth } from '../../../ContextAPI/AuthContext';
import { useNavigate } from 'react-router-dom';
import './DealerSelectionPage.css';

const DealerSelectionPage = () => {
  const { user, token } = useAuth();
  const navigate = useNavigate();
  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(null); // Initially, null to indicate no selection

  useEffect(() => {
    if (!user) {
        navigate('/');
    }
  }, [user, token, navigate]);

  useEffect(() => {
    if (user && user.sales_person) {
      fetchDealersBySalesPersonName(user.sales_person);
    } else {
      console.error('User or user.sales_person is not defined');
    }
  }, [user]);

  const fetchDealersBySalesPersonName = async (salesPersonName) => {
    try {
      const response = await axios.get(`https://api.chesadentalcare.com/sales_employees?name=${encodeURIComponent(salesPersonName)}`);

      if (response.data && response.data.length > 0) {
        const filteredDealers = response.data.map(dealer => ({
          id: dealer.id,
          d_code: dealer.d_code,
          bname: dealer.bname
        }));
        setDealers(filteredDealers);
      } else {
        console.log('No dealers found for sales person:', salesPersonName);
        setDealers([]);
      }
    } catch (error) {
      console.error('Error fetching dealers:', error);
    }
  };

  const handleDealerChange = (event) => {
    const selectedDealerId = event.target.value;
    const selectedDealerData = dealers.find(dealer => dealer.id === parseInt(selectedDealerId));
    setSelectedDealer(selectedDealerData);
    console.log('Dealer selected:', selectedDealerData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Selected dealer:', selectedDealer);
  
    if (selectedDealer) {
      const dData = JSON.parse(sessionStorage.getItem('SapDealerData'));

  if (Array.isArray(dData)) {
    // Find the dealer that matches the selected dealer's code
    console.log("dData",dData)
    const matchedDealer = dData.find((element)  => element[0].CardCode === selectedDealer.d_code );
    console.log("matchedDealer",matchedDealer[0].PriceListNum)
    sessionStorage.removeItem('PriceList')
   
    setTimeout(() => {
      sessionStorage.setItem('PriceList',matchedDealer[0].PriceListNum)
    },1) 
      
  }
      // Store selected dealer details in sessionStorage
      sessionStorage.setItem('selectedDealer', selectedDealer.id ? selectedDealer.id : selectedDealer);

      sessionStorage.setItem('d_code', selectedDealer.d_code ? selectedDealer.d_code : selectedDealer);

      console.log('Selected dealer stored in sessionStorage.');
    } else if (selectedDealer === null || selectedDealer === undefined) {
      // If no dealer is selected, consider it as "Direct Order"
      sessionStorage.setItem('selectedDealer', 'Direct Order');
      sessionStorage.setItem('d_code', 'Direct Order'); // Replace 'DirectOrderCode' with the actual d_code for Direct Order if available
      console.log('Direct Order selected, stored in sessionStorage.');
    } else {
      console.error('No dealer selected');
    }
  
    // Navigate to the purchase page
    navigate('/home'); // Adjust the path as necessary
  };
  

  return (
    <>
      {/* Uncomment Sidebar if needed */}
      {/* <Sidebar /> */}
      <div className="dealer-selection-page">
        <div className="dealer-selection-container">
          <h2 className='h2class'>Choose a Dealer</h2>
          <form className='form-dealer' onSubmit={handleSubmit}>
            <label>
              Select Dealer:
              <select className="dealer-select" value={selectedDealer?.id || ''} onChange={handleDealerChange}>
                <option value="">Select a dealer...</option>
                <option value="Direct Order">Direct Order</option>
                {dealers.map((dealer) => (
                  <option key={dealer.id} value={dealer.id}>
                    {dealer.bname}
                  </option>
                ))}
              </select>
            </label>
            <button className="cssbuttons-io-button" type="submit">
              Submit
              <div className="icon">
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default DealerSelectionPage;
