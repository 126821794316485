import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios
import Sidebar from "../../Sidebar/Sidebar";
import { ExternalLink } from "react-feather";
import NotificationButton from "../../../Notification/NotificationButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSidebar } from "../../../ContextAPI/SidebarContext";
import "./NewOrderForm.css"; // Import the CSS file
import { toast } from "react-hot-toast";
import { Truck, TrainFront, Bank2, Bank } from "react-bootstrap-icons";
import { useCart } from "../../../ContextAPI/CartContext";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import EnterLeadsModal from "../EnterLeads/EnterLeadsModal";
import { blue } from "@mui/material/colors";
import { FaSearch } from "react-icons/fa";
import { Pointer } from "lucide-react";
import { Modal, Radio, Button, Input  } from 'antd';

const NewOrderForm = ({ onData }) => {
  const location = useLocation();

  const [newModal, setNewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { isSidebarOpen } = useSidebar();
  const [formErrors, setFormErrors] = useState({});
  const [leads, setleads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const leadsPerPage = 5;
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [payId, setPayId] = useState("");
  const [file, setFile] = useState([]);
  const { clearCart } = useCart();
  const [payRcpt, setPayRcpt] = useState(null); // Use null for file upload
  const [orderId, setOrderId] = useState("");
  const { cartItems } = location.state || { cartItems: [] };
  const { specs } = location.state;
  const [initialOrderValue, setInitialOrderValue] = useState(0);
  const [dType, setDType] = useState('');
  const [businessPartner, setBusinessPartner] = useState(null);
  const [error, setError] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // console.log("specs", specs);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    advanceAmount: 1000,
    balanceAmount: 0,
    expectedDispatchDate: "",
    freightDetails: "",
    logisticType: "",
    clinicOpeningDate: "",
    paymentMode: "Offline",
    doctorOrCompany: "",
    gstNumber: "",
    customerRefNo: "",
    orderValue: 0,
  });
  const [shippingAddress, setShippingAddress] = useState({
    customerName: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    pincode: "",
    shipping_address: "",
  });

  const [billingAddress, setBillingAddress] = useState({
    bName: "",
    bemail: "",
    bphone: "",
    bcity: "",
    bstate: "",
    bpincode: "",
    billing_address: "",
  });

  const openModal = () => {
    setNewModal(true);
  };

  const closeModal = () => {
    setNewModal(false);
  };

  // console.log("Mydata",location.state.onData)
  const UniqueIDs = location.state.onData;
  // console.log("ner",...location.state.onData)

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [items, setItems] = useState(
    cartItems.map((item) => ({ ...item, discount: item.discount || 0 }))
  );

  useEffect(() => {
    const id = sessionStorage.getItem('selectedDealer');
    if (id) {
      fetch(`https://api.chesadentalcare.com/dealer_info?id=${id}`)
        .then(response => response.json())
        .then(dealerData => {
          const dealer = dealerData[0];
          const { d_type } = dealer;
          setDType(d_type);
        })
        .catch(error => console.error('Error fetching dealer info:', error));
    }
  }, []);

  const renderPrice = (product) => {
    return formatCurrency(product[dType.toLowerCase()] || product.msp);
  };

  // useEffect(() => {
  //   const initialItems = cartItems.map((item) => ({ ...item, discount: item.discount || 0 }));

  //   const updatedItems = () => {
  //     const filteredItems = initialItems.map(item => {
  //       const isDentalChair =
  //         item.cat_name === 'Dental Chairs' &&
  //         ['Onyx Premium', 'Onyx Regular', 'Ninja N4', 'New Jwala Chair', 'JAL Chair'].includes(item.name);

  //       const isRemovableSuction =
  //         item.cat_name === 'Motorized Suction' &&
  //         item.name === 'Suction 0.25 HP Mobile';

  //       const isNonRemovableSuction =
  //         item.cat_name === 'Motorized Suction' &&
  //         item.name === 'Suction 0.25 HP INBUILT';

  //       // Configuration mapping with item codes
  //       const configurationMap = {
  //         'Onyx Premium': { name: 'ONYX PREMIUM ( UNIT IN BLACK COLOUR) DENTAL CHAIR WITH REGULAR LED LIGHT WITH ASSISTANT TRAY WITH MOTORISED SUCTION', itemCode: 'DCH 194', msp: '189500' },
  //         'Onyx Regular': { name: 'ONYX REGULAR (NEW) DENTAL CHAIR WITH UNDERHANGING DELIVERY SYSTEM , LED LIGHT WITH ASSISTANT TRAY AND MOTORISED SUCTION', itemCode: 'DCH 147' },
  //         'Ninja N4': { name: 'NINJA N4 DENTAL CHAIR WITH U/H DELIVERY SYSTEM, LED LIGHT WITH & MOTORISED SUCTION', itemCode: 'DCH 102' },
  //         'New Jwala Chair': { name: 'NEW JWALA DENTAL CHAIR WITH UNDERHANGING DELIVERY SYSTEM, LED LIGHT AND STANDARD ACCESSORIES ( MOTORISED SUCTION )', itemCode: 'DCH 179' },
  //         'JAL Chair': { name: 'DCH 193', itemCode: 'DCH 193' },
  //       };

  //       // Update item name and itemCode if it matches the configuration
  //       if (isDentalChair && item.suctionType === 'Suction 0.25 HP Mobile') {
  //         const configuredItem = configurationMap[item.name];
  //         if (configuredItem) {
  //           return { ...item, name: configuredItem.name, itemCode: configuredItem.itemCode };
  //         }
  //       }

  //       // Return the item unchanged if it doesn't match the removable suction or if it's a non-removable suction
  //       return item;
  //     }).filter(item => {
  //       const isRemovableSuction =
  //         item.cat_name === 'Motorized Suction' &&
  //         item.name === 'Suction 0.25 HP Mobile';

  //       return !isRemovableSuction; // Remove items with removable suction
  //     });

  //     setItems(filteredItems);
  //     console.log(filteredItems);
  //   };

  //   updatedItems();
  // }, [cartItems]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData, [name]: value };

      if (name === "freightDetails") {
        if (value === "Paid Basis") {
          setItems((prevItems) => {
            const transportationItemExists = prevItems.some(
              (item) => item.id === "TC 02"
            );

            if (!transportationItemExists) {
              return [
                ...prevItems,
                {
                  id: "TC 02",
                  code: "TC 02",
                  name: "Transportation",
                  msp: 3500,
                  mrp: 3500,
                  quantity: 1,
                  discount: 0,
                  taxcode: "GST18",
                  color: "N/A",
                  remarks: "Transport expenses",
                  image: "transport.png"
                },
              ];
            }

            return prevItems;
          });
        } else if (value === "To Pay") {
          setItems((prevItems) =>
            prevItems.filter((item) => item.id !== "TC 02")
          );
        }
      }

      if (name === "advanceAmount" || name === "orderValue") {
        const balanceAmount =
          newFormData.orderValue - newFormData.advanceAmount;
        newFormData.balanceAmount = balanceAmount >= 0 ? balanceAmount : 0;
      }

      return newFormData;
    });
  };

  const handleDiscountChange = (index, value) => {
    const newItems = [...items];
    const discountPercentage = parseFloat(value) || 0;

    // Assuming the discount is a percentage of the product price
    const unitPrice = newItems[index].sapPrice;
    const discountAmount = (unitPrice * discountPercentage) / 100;

    // Calculate the price after discount
    newItems[index].dprice = unitPrice - discountAmount;
    newItems[index].discount = discountPercentage;

    setItems(newItems);
  };

  const handleDoctorOrCompanyChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, doctorOrCompany: value });
  };

  const calculateGrossPrice = (item) => {
    return calculatePriceAfterDiscount(item) * item.quantity;
  };

  const generateUniqueId = (existingIds = new Set()) => {
    let id;
    do {
      id = Math.floor(Math.random() * 1000000000); // Generates a random integer ID
    } while (existingIds.has(id)); // Check if the ID is already used
    return id;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("bdetails", billingAddress);

    let sequenceIds;

    if (selectedLeads.length === 0) {
      toast.error("Please select an associated lead.");
      return;
    } else {
      sequenceIds = selectedLeads[0].SalesOpportunities.SequentialNo;
    }

    const storedRole = sessionStorage.getItem("role");
    const sales_emp = sessionStorage.getItem("sales_person");
    const storedDCode = sessionStorage.getItem("d_code");

    if (!payId || !file || !cartItems.length) {
      toast.error("Please fill all fields and ensure cart has items.");
      return;
    }

    const orderId = UniqueIDs.map((item) => item.uniqueId);
    if (!orderId || orderId.length === 0) {
      toast.error("Order ID is missing.");
      return;
    }

    const productNames = items.map((item) => item.name);
    const names = productNames.join(", ");
    const quantity = items.map((item) => item.quantity);
    const quan = quantity.join(", ");
    const productColor = items.map((item) => item.color);
    const col = productColor.join(", ");

    const {
      logisticType,
      paymentMode,
      expectedDispatchDate,
      clinicOpeningDate,
      balanceAmount,
      advanceAmount,
    } = formData;
    const { customerName, email, phone, shipping_address, state, pincode } =
      shippingAddress;
    const { bName, bemail, bphone, billing_address, bstate, bpincode, bcity } =
      billingAddress;

    const orderStatus = advanceAmount > 0 ? "advance received" : "pending";
    const status = advanceAmount > 0 ? "advance received" : "pending";
    const currentDate = new Date().toISOString().split("T")[0];
    console.log("storedDCode", storedDCode);
    // return

    const addOrderDetails = {
      id: orderId[0],
      role: storedRole || "",
      exe_name: sales_emp,
      cno: "",
      d_code: storedDCode === "Direct Order" ? "1234" : storedDCode,
      products: names,
      qty: quan,
      color: col || "",
      log_type: logisticType || "",
      name: customerName,
      email: email,
      phone: phone,
      address: shipping_address,
      country: "India",
      state: state,
      pincode: pincode,
      pay_mode: paymentMode || "",
      bname: billingAddress.bName,
      bemail: billingAddress.bemail,
      bphone: billingAddress.bphone,
      baddress: billingAddress.billing_address,
      bcity: billingAddress.bcity,
      bstate: billingAddress.bstate,
      bpincode: billingAddress.bpincode,
      subdealer: "",
      expect_date: expectedDispatchDate || "",
      opening_date: clinicOpeningDate || "",
      total_amount: formData.orderValue,
      paid_amount: advanceAmount || 0,
      pending_amount: balanceAmount || "",
      status: "Waiting",
      order_status: "Waiting",
      track: "",
      logistic: logisticType || "",
      logistic_status: "Waiting",
      payment: "",
      pay_sts: "pending",
      read_sts: "unread",
      aread: "unread",
      lread: "unread",
      pread: "unread",
      lg_sts: "unread",
      transportation: logisticType || "",
      order_date: currentDate,
      ship_date: "",
      delivery_date: "",
      cmpstate: "",
      return_type: 1,
    };

    // console.log("manojcart", items)
    const ordData = items.map((item, index) => {
      let discount = 0;
      if (item.discount !== undefined && item.discount !== "") {
        const parsedDiscount = parseFloat(item.discount);
        if (!isNaN(parsedDiscount)) {
          discount = Math.round(parsedDiscount * 100); // Convert percentage to integer
        } else {
          console.warn(
            `Invalid discount value for item ${item.id}: ${item.discount}`
          );
        }
      } else {
        console.warn(`Empty or undefined discount value for item ${item.id}`);
      }

      return {
        orderid: orderId[0],
        id: generateUniqueId(), // Adjusted for next issue
        product_name: item.name,
        suction: item.suction || "",
        product_price: item.msp,
        product_image: item.image || "",
        qty: item.quantity,
        discount: discount,
        dprice: calculatePriceAfterDiscount(item) || 0,
        color: item.color || "",
        product_code: item.code,
        specification: item.remarks || "",
        remarks: index === 0 ? specs : "",
        status: "pending",
      };
    });

    console.log(addOrderDetails);
    console.log(ordData);
    // return

    // Toast loading ID
    const loadingToastId = toast.loading("Submitting order details...");

    // Function to post to the first API
    const postOrderDetails = async () => {
      try {
        const response = await fetch(
          "https://api.chesadentalcare.com/add_order_details",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(addOrderDetails),
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log("Order Details API Response:", data);
          return data;
        } else {
          throw new Error(data.message || "Failed to submit order details");
        }
      } catch (error) {
        console.error("Error posting order details:", error);
        throw error; // Re-throw to handle in the main try-catch
      }
    };

    // Function to post to the second API (for formData)
    const postOrderFormData = async () => {
      try {
        const formData = new FormData();
        formData.append("pay_id", payId);
        formData.append("order_id", orderId[0]);
        formData.append("file", file);
        const response = await fetch(
          "https://api.chesadentalcare.com/upload_receipt",
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log("Order FormData API Response:", data);
          return data;
        } else {
          throw new Error(data.message || "Failed to submit order form data");
        }
      } catch (error) {
        console.error("Error posting order form data:", error);
        throw error; // Re-throw to handle in the main try-catch
      }
    };

    // Function to post to the third API (for order items)
    const postOrderItems = async () => {
      try {
        const response = await fetch(
          "https://api.chesadentalcare.com/add_order",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ items: ordData, specs }),
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log("Order Items API Response:", data);
          return data;
        } else {
          throw new Error(data.message || "Failed to submit order items");
        }
      } catch (error) {
        console.error("Error posting order items:", error);
        throw error; // Re-throw to handle in the main try-catch
      }
    };

    const postSequenceId = async () => {
      const body = {
        sequence_id: sequenceIds,
        order_id: orderId[0],
      };
      //  console.log("body",body);

      try {
        const response = await fetch(
          "https://api.chesadentalcare.com/sequence",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
          }
        );

        const data = await response.json();
        if (response.ok) {
          // console.log("Order Items API Response:", data);
          return data;
        } else {
          throw new Error(data.message || "Failed to submit order items");
        }
      } catch (error) {
        console.error("Error posting order items:", error);
        throw error; // Re-throw to handle in the main try-catch
      }
    };

    // Post to all APIs
    try {
      // First, execute postSequenceId and wait for its completion
      const seqResult = await postSequenceId();
  
      // Check if seqResult indicates a successful operation
      if (seqResult && seqResult.success) { 
          const [
              orderDetailsResult,
              orderFormDataResult,
              orderItemsResult,
          ] = await Promise.all([
              postOrderDetails(),
              postOrderFormData(),
              postOrderItems(),
          ]);
          // Check if each result has a success property or is valid
      const allResultsSuccessful = [
        seqResult,
        orderDetailsResult,
        orderFormDataResult,
        orderItemsResult,
       
      ].every((result, index) => {
        // For the third API result, check if it's an array (regardless of contents)
        if (index === 2) {
          return Array.isArray(result);
        }
        // For other results, simply check if result is present and has success property set to true
        return result && result.success;
      });

      if (allResultsSuccessful) {
        toast.success("Order details submitted successfully!", {
          id: loadingToastId,
        });
        setPayId("");
        setOrderId("");
        setFile(null);
        clearCart(); // Clear the cart
      } else {
        toast.success("Order details submitted successfully!", {
          id: loadingToastId,
        });
        localStorage.removeItem("cart");
        clearCart();
        setPayId("");
        setOrderId("");
        setFile(null);
        navigate("/waiting_orders");
      }
      } else {
        toast.error("Lead already exists. Please try different Lead.")
          console.error('postSequenceId failed:', seqResult);
      }

     
    } catch (error) {
      console.error("Error during API submission:", error);

      toast.error("Lead already exists. Please try different Lead.", {
        id: loadingToastId,
      });
    }
  };

  const calculatePriceAfterDiscount = (item) => {
    const unitPrice =  item.sapPrice;
    const discountAmount = (unitPrice * item.discount) / 100;
    return unitPrice - discountAmount;
  };

  const handleError = (error) => {
    if (error.response) {
      console.error("Server responded with status:", error.response.status);
      console.error("Response data:", error.response.data);
      toast.error(
        `Server Error: ${error.response.data.message || "Undefined error"}`
      );
    } else if (error.request) {
      console.error("No response received:", error.request);
      toast.error("No response from server. Please try again later.");
    } else {
      console.error("Request setup error:", error.message);
      toast.error(
        "Failed to submit order details. Please check your network connection."
      );
    }
  };

  const calculateTotalOrderValue = () => {
    return items.reduce((total, item) => {
      return total + calculateGrossPrice(item);
    }, 0).toFixed(2);
  };

  useEffect(() => {
    const orderValue = calculateTotalOrderValue();
    setInitialOrderValue(orderValue);
    const balanceAmount = orderValue - formData.advanceAmount;
    setFormData((prevData) => ({
      ...prevData,
      balanceAmount,
    }));
  }, [items, formData.advanceAmount, dType]);


  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      orderValue: initialOrderValue,
    }));
  }, [initialOrderValue]);

  useEffect(() => {
    const cardCode = sessionStorage.getItem("d_code");
    if (cardCode !== "Direct Order") {
      // console.log(cardCode);
      const DealerDetails = async () => {
        const resposne = await fetch(
          `https://api.chesadentalcare.com/dealer?id=${cardCode}`
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          });
        // console.log(resposne.value[0]);
        setBillingAddress({
          bName: resposne.value[0].CardName,
          bemail: resposne.value[0].EmailAddress,
          bphone: resposne.value[0].Phone1
            ? resposne.value[0].Phone1
            : resposne.value[0].Phone2,
          bcity: resposne.value[0].City,
          bstate: resposne.value[0].BillToState,
          bpincode: resposne.value[0].MailZipCode,
          billing_address: resposne.value[0].Address,
        });

        // console.log("dealer data ", dealerDetails);
      };
      DealerDetails();
    } else {
      console.error("dealer not present direct order");
    }
  }, []);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  };


  useEffect(() => {
    const fetchLeads = async () => {
      const empName = sessionStorage.getItem("sales_person");
      if (!empName) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://api.chesadentalcare.com/sales_employee_id/?name=${empName}`
        );
        const data = await response.json();
        const idToUse = data.id;

        if (idToUse) {
          const leadsResponse = await fetch(
            "https://api.chesadentalcare.com/open_leads"
          );
          const leadsData = await leadsResponse.json();

          if (leadsData.value.length > 0) {
            const filteredLeads = leadsData.value.filter(
              (value) =>
                value.SalesOpportunities.DataOwnershipfield === idToUse
            );
            setleads(filteredLeads);

            if (filteredLeads.length === 0) {
              setErrorMessage(`No leads found for the sales employee ${empName}.`);
            }
          } else {
            setErrorMessage("No leads found for the sales employee.");
          }
        }
      } catch (error) {
        console.error("Error fetching leads:", error);
        setErrorMessage("An error occurred while fetching leads.");
      } finally {
        setIsLoading(false); // Stop loader once data is fetched or an error occurs
      }
    };

    fetchLeads();
  }, []);

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = leads.slice(indexOfFirstLead, indexOfLastLead);

  const nextPage = () => {
    if (currentPage < Math.ceil(leads.length / leadsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  const handleCheckboxChange = (lead) => {
    setSelectedLeads([lead]); // Always sets the selected lead as a single-element array
  };

  useEffect(() => {
    if (shippingAddress.phone.length === 10) {
      const toastId = toast.loading('Fetching Business Partner data...');
      // Fetch Business Partner data when the phone number has 10 digits
      axios.get(`https://api.chesadentalcare.com/fetch_businesspartner_by_phone?phone=${shippingAddress.phone}`)
        .then(response => {
          setBusinessPartner(response.data);
          setIsModalVisible(true);
          console.log('bpdata', response.data)
          setError(null);
          toast.success('Business Partner data fetched successfully!', {
            id: toastId,
          });
        })
        .catch(err => {
          console.error('Error fetching Business Partner:', err);
          setError('No Business Partner found with this phone number.');
          setBusinessPartner(null);
          toast.error('Failed to fetch Business Partner data.', {
            id: toastId,
          });
        });
    }
  }, [shippingAddress.phone]);

  const handleAddressSelect = () => {
    if (selectedAddress) {
      setShippingAddress({
        ...shippingAddress,
        customerName: selectedAddress.AddressName,
        email: businessPartner.EmailAddress, // Keep the existing email if it's not part of the address
        phone: shippingAddress.phone, // Keep the existing phone if it's not part of the address
        city: selectedAddress.City,
        state: selectedAddress.State,
        pincode: selectedAddress.ZipCode,
        shipping_address: `${selectedAddress.Street}, ${selectedAddress.City}, ${selectedAddress.State}, ${selectedAddress.ZipCode}`,
      });
    }
    setIsModalVisible(false);
  };

  const filteredAddresses = businessPartner
    ? businessPartner.BPAddresses.filter((address) =>
      address.AddressName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  return (
    <div
      className={`sales-dashboard ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"
        }`}
    >
      <div
        className="first"
        style={{
          backgroundColor: "#f2f2f2",
          padding: "20px",
          textAlign: "start",
          borderRadius: "8px",
          position: "relative",
          top: "15px",
        }}
      >
        <p
          style={{
            fontFamily: "Arial, sans-serif",
            fontSize: "16px",
            margin: "-30px",
            fontWeight: "bold",
            position: "relative",
            top: "5px",
            left: "23px",
          }}
        >
          Navigate to Chesa Services
          <a
            href="https://chesadentalcare.com"
            style={{
              textDecoration: "none",
              color: "#007bff",
              marginLeft: "5px",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExternalLink size={20} />
          </a>
          <span className="notibu">
            <NotificationButton />
          </span>
        </p>
      </div>
      <div className="header">
        <h1
          className="company-name1"
          style={{
            borderRadius: "8px",
            position: "relative",
            top: "7px",
            left: "0px",
          }}
        >
          Order Details
        </h1>
        {/* Additional components or controls */}
      </div>
      <Sidebar />
      <div className="new-order-main-content">
        <div className="maintable">
          <h2>Order Summary</h2>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Product</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <th>Discount %</th>
                <th>Price After Discount</th>
                <th>Gross Price</th>
                <th>Color</th>
                <th>Specification</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.itemCode ? item.itemCode : item.code}</td>
                  <td>{item.name}</td>
                  <td>{formatCurrency(item.sapPrice)}</td>
                  <td>{item.quantity}</td>
                  <td>
                    <input
                      type="number"
                      value={item.discount}
                      onChange={(e) =>
                        handleDiscountChange(index, e.target.value)
                      }
                      defaultValue={0}
                      min="0"
                    />
                  </td>
                  <td>{formatCurrency(calculatePriceAfterDiscount(item).toFixed(2))}</td>
                  <td>{formatCurrency(calculateGrossPrice(item).toFixed(2))}</td>
                  <td>{item.color || "None"}</td>
                  <td>{item.remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="order-details-form" style={{ marginBottom: "18px" }}>
          <div className="maintable">
            <h3 style={{ position: "relative" }}>
              Leads Associated with {sessionStorage.getItem("sales_person").toUpperCase()}
            </h3>

            {isLoading ? (
              <p className="loader31" style={{ position: "relative", top: "-27px", left: "170%" }}></p>
            ) : leads.length > 0 ? (
              <>
                <h4 className="showingleadscount">
                  Showing <span style={{ fontWeight: "700" }}>{leads.length}</span> leads
                </h4>
                <table
                  className="tabNewOrd"
                  style={{ width: "100%", marginTop: "-15px" }}
                >
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>ID</th>
                      <th>Opportunity Name</th>
                      <th>Phone Number</th>
                      <th>City</th>
                      <th>Total Price</th>
                      <th>Start Date</th>
                      <th>Predicted Closing Date</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <TransitionGroup component="tbody">
                    {currentLeads.map((lead, index) => (
                      <CSSTransition
                        key={index}
                        timeout={500}
                        classNames="fade"
                      >
                        <tr onClick={() => handleCheckboxChange(lead)}>
                          <td>
                            <input
                              type="radio"
                              required
                              checked={selectedLeads[0] === lead}
                              onChange={() => handleCheckboxChange(lead)}
                            />
                          </td>
                          <td>{lead.SalesOpportunities.SequentialNo}</td>
                          <td>{lead.BusinessPartners.CardName}</td>
                          <td>{lead.BusinessPartners.Cellular}</td>
                          <td>{lead.BusinessPartners.City}</td>
                          <td>{lead.SalesOpportunities.MaxLocalTotal}</td>
                          <td>{lead.SalesOpportunities.StartDate}</td>
                          <td>{lead.SalesOpportunities.PredictedClosingDate}</td>
                          <td>{lead.SalesOpportunities.Remarks}</td>
                        </tr>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </table>
                <span className="didntfindlead" onClick={openModal}>
                  Didn't find Lead?
                </span>
                <div>
                  <button
                    className="continue-button1"
                    onClick={prevPage}
                    style={{ borderRadius: "50px" }}
                    disabled={currentPage === 1}
                  >
                    Prev
                  </button>
                  <button
                    className="continue-button1"
                    style={{ marginLeft: "15px", borderRadius: "50px" }}
                    onClick={nextPage}
                    disabled={currentPage >= Math.ceil(leads.length / leadsPerPage)}
                  >
                    Next
                  </button>
                </div>
              </>
            ) : (
              <p style={{ position: "relative", top: "-41px", left: "100%" }}>
                {errorMessage ? (
                  <>
                    <span style={{ fontWeight: 550, color: "red" }}>{errorMessage}</span><br />
                    <span style={{ position: "relative", left: "30%", color: "blue", fontWeight: "550", cursor: "Pointer" }} onClick={openModal}>
                      Add Quick Lead?
                    </span>
                  </>
                ) : (

                  <span style={{ fontWeight: 550, color: "blue" }}>
                    No leads found. Please try again later.
                  </span>


                )}
              </p>
            )}
          </div>

        </div>

        <form onSubmit={handleSubmit}>
          <div className="order-details-form">
            <div className="form-group">
              <label>Order Value</label>
              <input
                type="number"
                name="orderValue"
                value={parseFloat(formData.orderValue).toFixed(2)}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Advance Amount</label>
              <input
                type="number"
                name="advanceAmount"
                value={formData.advanceAmount}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>Balance Amount</label>
              <input
                type="number"
                name="balanceAmount"
                value={formData.balanceAmount}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>
                Expected Dispatch Date <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="date"
                required
                name="expectedDispatchDate"
                value={formData.expectedDispatchDate}
                onChange={handleChange}
                min={new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
              />
            </div>
            <div className="form-group">
              <label>
                Freight Details <span style={{ color: "red" }}>*</span>{" "}
              </label>
              <br />
              <div className="radio-buttons">
                <div>
                  <input
                    type="radio"
                    required
                    style={{
                      top: "-3px",
                      position: "relative",
                      marginRight: "10px",
                    }}
                    id="to-pay"
                    name="freightDetails"
                    value="To Pay"
                    checked={formData.freightDetails === "To Pay"}
                    onChange={handleChange}
                  />
                  <label htmlFor="to-pay">
                    <Bank style={{ marginRight: "8px" }} />
                    To Pay
                  </label>
                </div>
                <div>
                  <input
                    required
                    type="radio"
                    id="paid-basis"
                    name="freightDetails"
                    style={{
                      top: "-3px",
                      position: "relative",
                      marginRight: "10px",
                    }}
                    value="Paid Basis"
                    checked={formData.freightDetails === "Paid Basis"}
                    onChange={handleChange}
                  />
                  <label htmlFor="paid-basis">
                    <Bank2 style={{ marginRight: "8px" }} />
                    Paid Basis
                  </label>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                Logistic Type <span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <div className="radio-buttons">
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <input
                      type="radio"
                      required
                      style={{ top: "-3px", position: "relative" }}
                      name="logisticType"
                      value="By Road"
                      checked={formData.logisticType === "By Road"}
                      onChange={handleChange}
                      id="by-road"
                    />
                    <label
                      htmlFor="by-road"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                      }}
                    >
                      <Truck style={{ marginRight: "8px" }} /> By Road
                    </label>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="radio"
                      required
                      name="logisticType"
                      value="By Train"
                      checked={formData.logisticType === "By Train"}
                      onChange={handleChange}
                      style={{
                        marginLeft: "16px",
                        top: "-6px",
                        position: "relative",
                      }}
                      id="by-train"
                    />
                    <label
                      htmlFor="by-train"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "8px",
                        top: "-4px",
                        position: "relative",
                      }}
                    >
                      <TrainFront style={{ marginRight: "8px" }} /> By Train
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>Clinic Opening Date (Optional)</label>
              <input
                type="date"
                name="clinicOpeningDate"
                value={formData.clinicOpeningDate}
                onChange={handleChange}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="form-group">
              <label>
                Payment Mode <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="paymentMode"
                required
                value={formData.paymentMode}
                onChange={handleChange}
              >
                <option value="">Select</option>
                <option value="Doctor">offline</option>
              </select>
            </div>

            <div className="form-group">
              <label>
                Select Doctor or Company <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="doctorOrCompany"
                required
                value={formData.doctorOrCompany}
                onChange={handleDoctorOrCompanyChange}
              >
                <option value="">Select</option>
                <option value="Doctor">Doctor</option>
                <option value="Company">Company</option>
              </select>
            </div>
            {formErrors.paymentMode && (
              <span style={{ color: "red" }}>{formErrors.paymentMode}</span>
            )}
            {formData.doctorOrCompany && (
              <>
                <div className="form-group">
                  <label htmlFor="payId">
                    Transaction ID <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="payId"
                    value={payId}
                    onChange={(e) => setPayId(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="payRcpt">
                    Receipt <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="file"
                    required
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                {formData.doctorOrCompany === "Company" && (
                  <div className="form-group">
                    <label>GST Number</label>
                    <input
                      required
                      type="text"
                      name="gstNumber"
                      value={formData.gstNumber}
                      onChange={handleChange}
                    />
                  </div>
                )}
              </>
            )}
            <div className="form-group">
              <label>Customer Ref. No (Optional)</label>
              <input
                type="text"
                name="customerRefNo"
                value={formData.customerRefNo}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="shipping-address-section">
            <h2>Shipping Address </h2>
            <div className="form-group">
              <label>
                Customer Name <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                name="customerName"
                value={shippingAddress.customerName}
                onChange={handleShippingChange}
              />
            </div>
            <div className="form-group">
              <label>
                Email <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="email"
                required
                name="email"
                value={shippingAddress.email}
                onChange={handleShippingChange}
              />
            </div>
            <div className="form-group">
              <label>
                Phone Number <span style={{ color: "red" }}>* </span> <span>(Don't include +91)</span>
              </label>
              <input
                type="text"
                required
                name="phone"
                value={shippingAddress.phone}
                onChange={(e) => {
                  const { name, value } = e.target;
                  // Ensure the value is numeric and has a length of 10 or less
                  if (/^\d{0,10}$/.test(value)) {
                    handleShippingChange(e);
                  }
                }}
                maxLength="10"
              />
              {shippingAddress.phone && shippingAddress.phone.length !== 10 && (
                <span style={{ color: "red" }}>Phone number must be exactly 10 digits.</span>
              )}
            </div>

            <Modal
            style={{top: "55px"}}
      className="address-modal-for-order"
      title={null} // Remove the default title
      open={isModalVisible}
      onOk={handleAddressSelect}
      onCancel={() => setIsModalVisible(false)}
      footer={null} // Remove the default footer
    >
      {/* Title and Search Bar in a Single Line */}
      <div className="modal-header">
  <h3 className="modal-title">Select an Address</h3>
  
  <div className="search-container">
    <i 
      className={`search-icon ${isSearchActive ? 'active' : ''}`}
      onClick={() => setIsSearchActive(!isSearchActive)}
    >
      <FaSearch />
    </i>
    
    {isSearchActive && (
      <Input
        placeholder="Search by name"
        style={{borderRadius: "20px"}}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
        autoFocus
      />
    )}
  </div>
</div>


      <div className="address-content">
        <Radio.Group
          className="radio-group"
          onChange={(e) => setSelectedAddress(e.target.value)}
        >
          {filteredAddresses.length > 0 ? (
            filteredAddresses.map((address, index) => (
              <div key={index} className="address-card">
                <Radio value={address}>
                  <div>
                    <p>
                      <strong>{address.AddressName}</strong>
                    </p>
                    <p>{address.Street}</p>
                    <p>
                      {address.City}, {address.State}, {address.ZipCode}
                    </p>
                    <p>{address.Country}</p>
                    <p>Email: {businessPartner.EmailAddress}</p>
                  </div>
                </Radio>
              </div>
            ))
          ) : (
            <p>No addresses found.</p>
          )}
        </Radio.Group>
      </div>

      <div className="modal-footer">
        <Button key="cancel" onClick={() => setIsModalVisible(false)}>
          Cancel
        </Button>
        <Button
          key="submit"
          type="primary"
          onClick={handleAddressSelect}
          disabled={!selectedAddress}
        >
          Select
        </Button>
      </div>
    </Modal>
            <div className="form-group">
              <label>
                City <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                required
                name="city"
                value={shippingAddress.city}
                onChange={handleShippingChange}
              />
            </div>
            <div className="form-group">
              <label>
                State <span style={{ color: "red" }}>*</span>
              </label>
              <select
                name="state"
                value={shippingAddress.state}
                onChange={handleShippingChange}
              >
                <option value="">Select State</option>
                <option value="AN">Andaman and Nicobar Islands</option>
                <option value="AP">Andhra Pradesh</option>
                <option value="AR">Arunachal Pradesh</option>
                <option value="AS">Assam</option>
                <option value="BR">Bihar</option>
                <option value="CG">Chhattisgarh</option>
                <option value="CH">Chandigarh</option>
                <option value="DD">Daman and Diu</option>
                <option value="DL">Delhi</option>
                <option value="DN">Dadra and Nagar Haveli</option>
                <option value="GA">Goa</option>
                <option value="GJ">Gujarat</option>
                <option value="HR">Haryana</option>
                <option value="HP">Himachal Pradesh</option>
                <option value="JK">Jammu and Kashmir</option>
                <option value="JH">Jharkhand</option>
                <option value="KT">Karnataka</option>
                <option value="KL">Kerala</option>
                <option value="LD">Lakshadweep</option>
                <option value="MP">Madhya Pradesh</option>
                <option value="MH">Maharashtra</option>
                <option value="MN">Manipur</option>
                <option value="ML">Meghalaya</option>
                <option value="MZ">Mizoram</option>
                <option value="NL">Nagaland</option>
                <option value="NP">Nepal</option>
                <option value="OD">Odisha</option>
                <option value="PN">Punjab</option>
                <option value="PY">Puducherry</option>
                <option value="RJ">Rajasthan</option>
                <option value="SK">Sikkim</option>
                <option value="TN">Tamil Nadu</option>
                <option value="TG">Telangana</option>
                <option value="TR">Tripura</option>
                <option value="UK">Uttarakhand</option>
                <option value="UP">Uttar Pradesh</option>
                <option value="WB">West Bengal</option>
                {/* Add more states as needed */}
              </select>
            </div>
            <div className="form-group">
              <label>
                Pincode <span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="pincode"
                value={shippingAddress.pincode}
                onChange={handleShippingChange}
              />
            </div>
            <div className="form-group">
              <label>
                Shipping Address <span style={{ color: "red" }}>*</span>
                <input
                  type="text"
                  name="shipping_address"
                  value={shippingAddress.shipping_address}
                  onChange={handleShippingChange}
                  maxLength="99"
                />
              </label>
            </div>
          </div>
          <div className="billing-address-section">
            <h2>Billing Address</h2>
            <div className="form-group">
              <label>Customer Name</label>
              <input
                type="text"
                name="bName"
                value={billingAddress.bName}
                onChange={handleBillingChange}
                readOnly={sessionStorage.getItem("d_code") !== "Direct Order"}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="bemail"
                value={billingAddress.bemail}
                onChange={handleBillingChange}
                readOnly={sessionStorage.getItem("d_code") !== "Direct Order"}
              />
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <input
                type="text"
                name="bphone"
                value={billingAddress.bphone}
                onChange={handleBillingChange}
                readOnly={sessionStorage.getItem("d_code") !== "Direct Order"}
              />
            </div>
            <div className="form-group">
              <label>City</label>
              <input
                type="text"
                name="bcity"
                value={billingAddress.bcity}
                onChange={handleBillingChange}
                readOnly={sessionStorage.getItem("d_code") !== "Direct Order"}
              />
            </div>
            <div className="form-group">
              <label>State</label>
              <select
                name="bstate"
                value={billingAddress.bstate}
                onChange={handleBillingChange}
                disabled={sessionStorage.getItem("d_code") !== "Direct Order"}
              >
                <option value="">Select State</option>
                <option value="AN">Andaman and Nicobar Islands</option>
                <option value="AP">Andhra Pradesh</option>
                <option value="AR">Arunachal Pradesh</option>
                <option value="AS">Assam</option>
                <option value="BR">Bihar</option>
                <option value="CG">Chhattisgarh</option>
                <option value="CH">Chandigarh</option>
                <option value="DD">Daman and Diu</option>
                <option value="DL">Delhi</option>
                <option value="DN">Dadra and Nagar Haveli</option>
                <option value="GA">Goa</option>
                <option value="GJ">Gujarat</option>
                <option value="HR">Haryana</option>
                <option value="HP">Himachal Pradesh</option>
                <option value="JK">Jammu and Kashmir</option>
                <option value="JH">Jharkhand</option>
                <option value="KT">Karnataka</option>
                <option value="KL">Kerala</option>
                <option value="LD">Lakshadweep</option>
                <option value="MP">Madhya Pradesh</option>
                <option value="MH">Maharashtra</option>
                <option value="MN">Manipur</option>
                <option value="ML">Meghalaya</option>
                <option value="MZ">Mizoram</option>
                <option value="NL">Nagaland</option>
                <option value="NP">Nepal</option>
                <option value="OD">Odisha</option>
                <option value="PN">Punjab</option>
                <option value="PY">Puducherry</option>
                <option value="RJ">Rajasthan</option>
                <option value="SK">Sikkim</option>
                <option value="TN">Tamil Nadu</option>
                <option value="TG">Telangana</option>
                <option value="TR">Tripura</option>
                <option value="UK">Uttarakhand</option>
                <option value="UP">Uttar Pradesh</option>
                <option value="WB">West Bengal</option>
              </select>
            </div>
            <div className="form-group">
              <label>Pincode</label>
              <input
                type="text"
                name="bpincode"
                value={billingAddress.bpincode}
                onChange={handleBillingChange}
                readOnly={sessionStorage.getItem("d_code") !== "Direct Order"}
              />
            </div>
            <div className="form-group">
              <label>Shipping Address</label>
              <input
                type="text"
                name="billing_address"
                value={billingAddress.billing_address}
                onChange={handleBillingChange}
                readOnly={sessionStorage.getItem("d_code") !== "Direct Order"}
              />
            </div>
          </div>
          {/* <button type="submit">Submit</button> */}
          <button type="submit" className="su1">
            <div class="svg-wrapper-1">
              <div class="svg-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    fill="currentColor"
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  ></path>
                </svg>
              </div>
            </div>
            <span>Submit</span>
          </button>
        </form>
      </div>
      <div className="enterlead-new-modal">
        {newModal && (
          <div id="lead2" className="enterlead">
            <span className="close-times" onClick={closeModal}>
              &times;
            </span>
            <EnterLeadsModal onClose={closeModal} />
          </div>

        )}
      </div>
    </div>
  );
};
export default NewOrderForm;
