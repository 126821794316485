import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Home/Home.css'; // Import the CSS file
import Sidebar from '../Sidebar/Sidebar';
import { ExternalLink, X } from 'react-feather';
import NotificationButton from '../../Notification/NotificationButton';
import { useSidebar } from '../../ContextAPI/SidebarContext';
import { CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Select, Spin, Alert } from 'antd';
import '../Dashboard/Allorders.css';


const { Option } = Select;

const Home = () => {
  const { isSidebarOpen } = useSidebar();
  const [salesEmployees, setSalesEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalSales, setTotalSales] = useState(0);
  const [monthlySales, setMonthlySales] = useState([]);
  const [selectedEmployeeName, setSelectedEmployeeName] = useState('');
  const targetSale = 800000;
  const employeeSales = monthlySales?.monthData?.month_employee_sales || 0;

  const [salesData, setSalesData] = useState({
    total: 0,
    month: 0,
    week: 0
  });
  const [employeeData, setEmployeeData] = useState({
    total: 0,
    month: 0,
    week: 0
  });
  const [outstandingData, setOutstandingData] = useState({
    total: 0,
    month: 0,
    week: 0
  });
  const [paidData, setPaidData] = useState({
    total: 0,
    month: 0,
    week: 0
  });

  useEffect(() => {
    // Fetch sales employees from the API
    axios.get('https://api.chesadentalcare.com/sales_employees_info')
      .then(response => {
        setSalesEmployees(response.data);
        console.log('staff', response.data)
      })
      .catch(error => {
        console.error('Error fetching sales employees:', error);
      });
  }, []);

  const handleEmployeeChange = (e) => {
    const salesPerson = e.target.value;
    const employeeName = e.target.options[e.target.selectedIndex].text.toLowerCase(); // Ensure case insensitivity

    setSelectedEmployee(salesPerson);
    setSelectedEmployeeName(employeeName);

    // Fetch monthly sales for the selected employee
    axios.get(`https://api.chesadentalcare.com/credit-order-details?name=${salesPerson}`)
      .then(response => {
        const { creditOrderDetailsEmployee } = response.data;
        console.log("API Response:", creditOrderDetailsEmployee);

        // Filter the data for the selected employee
        const monthData = creditOrderDetailsEmployee.month.find(item => item.exe_name.toLowerCase() === employeeName);
        const totalData = creditOrderDetailsEmployee.total.find(item => item.exe_name.toLowerCase() === employeeName);
        const weekData = creditOrderDetailsEmployee.week.find(item => item.exe_name.toLowerCase() === employeeName);

        // Log filtered data for debugging
        console.log("Filtered monthData:", monthData);
        console.log("Filtered totalData:", totalData);
        console.log("Filtered weekData:", weekData);

        // Set the filtered data to state
        setMonthlySales({
          monthData: monthData || null,
          totalData: totalData || null,
          weekData: weekData || null
        });
      })
      .catch(error => {
        console.error('Error fetching monthly sales:', error);
      });
  };


  useEffect(() => {
    // Combined API call to fetch both credit and pending order details
    axios.get('https://api.chesadentalcare.com/credit-order-details')
      .then(response => {
        const { creditOrderDetails, pendingOrderDetails, paidOrderDetails, creditOrderDetailsEmployee } = response.data;
        setSalesData(creditOrderDetails);
        setOutstandingData(pendingOrderDetails);
        setPaidData(paidOrderDetails);
        setEmployeeData(creditOrderDetailsEmployee);
        console.log('credit', creditOrderDetails);
        console.log('out', pendingOrderDetails);
      })
      .catch(error => {
        console.error('Error fetching order details:', error);
      });
  }, []); // Empty array ensures it runs only once

  const getSaleStatus = () => {
    if (employeeSales >= targetSale) {
      return { status: 'success', icon: <CheckCircleOutlined />, difference: 0 };
    } else if (employeeSales >= targetSale * 0.8) {
      return { status: 'warning', icon: <ExclamationCircleOutlined />, difference: targetSale - employeeSales };
    } else {
      return { status: 'error', icon: <CloseCircleOutlined />, difference: targetSale - employeeSales };
    }
  };

  const { status, icon, difference } = getSaleStatus();

  return (
    <>

      <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)' }}>
        <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start', position: 'relative', top: '15px' }}>
          <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '23px' }}>
            Navigate to Chesa Services
            <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
              <ExternalLink size={20} />
            </a>
            <NotificationButton />
          </p>
          {/* <InfoButton /> */}
        </div>

        <div className="header">
          <h1 className="company-name1" style={{ position: 'relative', top: '5px', left: '23px' }}>Your Credit Status</h1><br />
          <div style={{ position: "relative", right: "5%" }}>
            {/* <ModalWrapper handleFilter={handleFilter} /> */}
          </div>
        </div>
        <Sidebar />
        <div className="main-content" style={{ left: "87%" }}>
          <div className="ledger-container">
            <div className="ledger-sub-container">
              <div className="ledger-card">
                <h2>Total Sales</h2>
                <p1>₹ {salesData.total.toLocaleString()}</p1>
                <hr style={{ border: '1px solid #ddd', margin: '10px 0' }} />
                <div className="ledger-card-sub">
                  <div>
                    <h3>This Month</h3>
                    <p>₹ {salesData.month.toLocaleString()}</p>
                  </div>
                  <div>
                    <h3>This Week</h3>
                    <p>₹ {salesData.week.toLocaleString()}</p>
                  </div>
                </div>
              </div>
              <div className="ledger-card">
                <h2>Total Outstanding</h2>
                <p1>₹ {outstandingData.total.toLocaleString()}</p1>
                <hr style={{ border: '1px solid #ddd', margin: '10px 0' }} />
                <div className="ledger-card-sub">
                  <div>
                    <h3>This Month</h3>
                    <p>₹ {outstandingData.month.toLocaleString()}</p>
                  </div>
                  <div>
                    <h3>This Week</h3>
                    <p>₹ {outstandingData.week.toLocaleString()}</p>
                  </div>
                </div>
              </div>
              <div className="ledger-card">
                <h2>Total Payment Received</h2>
                <p1>₹ {paidData.total.toLocaleString()}</p1>
                <hr style={{ border: '1px solid #ddd', margin: '10px 0' }} />
                <div className="ledger-card-sub">
                  <div>
                    <h3>This Month</h3>
                    <p>₹ {paidData.month.toLocaleString()}</p>
                  </div>
                  <div>
                    <h3>This Week</h3>
                    <p>₹ {paidData.week.toLocaleString()}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ledger-sub-container">
              <div className="ledger-card2">
                <h2>Due Overview</h2>
                <h3>Current Total Sale Amount</h3>
                <p>₹ {salesData.total.toLocaleString()}</p>
                <hr style={{ border: '1px solid #ddd', margin: '10px 0' }} />
                <h3>Paid Amount</h3>
                <p>₹ {paidData.total.toLocaleString()}</p>
                <hr style={{ border: '1px solid #ddd', margin: '10px 0' }} />
                <h3>Due Amount</h3>
                <p>₹ {outstandingData.total.toLocaleString()}</p>
              </div>

              <div className="ledger-card2">
                <label>Select Sales Employee:</label>
                <select value={selectedEmployee} onChange={handleEmployeeChange}>
                  <option value="">Select an employee</option>
                  {salesEmployees.map(employee => (
                    <option key={employee.username} value={employee.username}>{employee.username}</option>
                  ))}
                </select>

                {selectedEmployeeName && (
                  <div className={`sales-summary ${status}`}>
                    <h3>Monthly Sales for {selectedEmployeeName}:</h3>
                    <ul>
                      {monthlySales.monthData ? (
                        <>
                          <li>This Month Sales: ₹{employeeSales.toLocaleString()}</li>
                          <li>Target Sales: ₹{targetSale.toLocaleString()}</li>
                          <li className="status">
                            {icon} Target {status === 'success' ? 'Reached' : 'Not Reached'}{status !== 'success' && ` - ₹${difference.toLocaleString()} more needed`}
                          </li>
                        </>
                      ) : (
                        <li>No month sales data available for this employee</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;