import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar'; // Adjust the import path as necessary
import './CartPage.css';
import { useSidebar } from '../../ContextAPI/SidebarContext';
import { useCart } from '../../ContextAPI/CartContext'; // Import the useCart hook
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NewOrderForm from '../../components/Pages/AllOrders/NewOrderForm';


const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR'
    }).format(amount);
};

const generateUniqueRandomNumber = (usedNumbers) => {
    let randomNumber;
    do {
        randomNumber = Math.floor(Math.random() * 99999999) + 10;
    } while (usedNumbers.has(randomNumber));
    usedNumbers.add(randomNumber);
    return randomNumber;
};

const CartPage = () => {
    const { isSidebarOpen } = useSidebar();
    const [specs, setSpecs] = useState(null)
    const { cart, removeFromCart } = useCart(); // Use the cart context
    const navigate = useNavigate();
    const [uniqueNumbers, setUniqueNumbers] = useState(new Set());
    const [cartWithUniqueNumbers, setCartWithUniqueNumbers] = useState([]);
    const [dType, setDType] = useState('');

    console.log('Cart:', cart);

    const handleRemoveFromCart = (productId, suctionType, color) => {
        console.log(`Removing product with id ${productId}, suction ${suctionType}, color ${color}`);
        removeFromCart(productId, suctionType, color);
        // toast.success('Item removed from cart');
    };

    const getPriceForItem = (item) => {
        return  item.sapPrice;
    };

    const grandTotal = cart?.reduce((sum, item) => sum + getPriceForItem(item) * item.quantity, 0) || 0;

    // Order summary
    const orderSummary = {
        grandTotal: grandTotal,
        taxIncluded: true, // Update this based on your tax logic
        transportation: 'Additional', // Update this based on your actual transportation logic
        total: grandTotal // Adjust as necessary if there are additional calculations for the total
    };

    const handleCheckout = () => {
        navigate('/new-order-form', { state: { cartItems: cart, onData: cartWithUniqueNumbers, specs: specs } });
    };

    useEffect(() => {
        const usedNumbers = new Set();
        const cartItemsWithUniqueNumbers = cart.map((item) => ({
            ...item,
            uniqueId: generateUniqueRandomNumber(usedNumbers),
        }));
        setCartWithUniqueNumbers(cartItemsWithUniqueNumbers);
        console.log("car", cartItemsWithUniqueNumbers);
        setUniqueNumbers(usedNumbers);
    }, [cart]);

    useEffect(() => {
        const id = sessionStorage.getItem('selectedDealer');
        if (id) {
            fetch(`https://api.chesadentalcare.com/dealer_info?id=${id}`)
                .then(response => response.json())
                .then(dealerData => {
                    const dealer = dealerData[0];
                    const { d_type } = dealer;
                    setDType(d_type);
                })
                .catch(error => console.error('Error fetching dealer info:', error));
        }
    }, []);

    const renderPrice = (product) => {
        return formatCurrency(product[dType.toLowerCase()] || product.msp);
    };

    return (
        <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <Sidebar />
            {/* <NewOrderForm onData={cartWithUniqueNumbers}/> */}
            <div className="cart-page-content">
                <h1>Your Cart</h1>
                <table className="cart-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Product</th>
                            <th>Suction</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Value</th>
                            <th>Color</th>
                            <th>Specification</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartWithUniqueNumbers?.map((item) => (
                            <tr key={item.id}>
                                <td>{item.uniqueId}</td>
                                <td>{item.name}</td>
                                <td>{item.suctionType || "Not Applicable"}</td>
                                <td>{formatCurrency(item.sapPrice)}</td>
                                <td>{item.quantity}</td>
                                <td>{formatCurrency((item.sapPrice) * item.quantity)}</td>
                                <td>{item.color || 'None'}</td>
                                <td>{item.remarks}</td>
                                <td>
                                    <button className="action-button" onClick={() => handleRemoveFromCart(item.id, item.suctionType, item.color)}>
                                        Remove
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="remarks-order-summary">
                    <label htmlFor="remarks">Remarks:</label>
                    <div className="remarks-section">
                        <textarea value={specs}
                            onChange={(e) => setSpecs(e.target.value)} className="remarks-content" placeholder="Write your remarks..."></textarea>
                    </div>

                    <div className="order-summary">
                        <h3>Order Summary</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Grand Total:</td>
                                    <td>{formatCurrency(orderSummary.grandTotal)}</td>
                                </tr>
                                <tr>
                                    <td>Tax:</td>
                                    <td>{orderSummary.taxIncluded ? 'Included' : 'Not Included'}</td>
                                </tr>
                                <tr>
                                    <td>Transportation:</td>
                                    <td>{orderSummary.transportation}</td>
                                </tr>
                                <tr>
                                    <td>Total:</td>
                                    <td>{formatCurrency(orderSummary.total)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <button className="proceed-button" onClick={handleCheckout}>Continue to Checkout</button>
            </div>

        </div>
    );
};

export default CartPage;
