import React, { useState } from 'react';
import { Link, useLocation , useNavigate} from 'react-router-dom';
import './Sidebar.css'; // Import CSS file for styling
import backgroundImage from './chesa.png'; // Import the image
import { Clipboard,JournalMedical, Truck, Box, CheckCircle, PersonFillAdd , BoxArrowRight, ChevronBarLeft,ChevronUp,ChevronDown ,ChevronBarRight, ChevronBarDown, ChevronBarUp, People,HouseFill } from 'react-bootstrap-icons';
import { toast, ToastContainer } from 'react-toastify';
import { useSidebar } from '../../ContextAPI/SidebarContext';
import { useAuth } from '../../ContextAPI/AuthContext';

const Sidebar = () => {
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isSAPCrmOpen, setIsSAPCrmOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  

  const handleLogout = () => {
    logout(navigate); // Call the logout function from AuthContext
    toast.error("Logout Successful");
  };

  const toggleSubmenu = (menu) => {
    setOpenSubmenu(openSubmenu === menu ? null : menu);
  };

  const toggleOrderDetails = () => {
    setIsOrderDetailsOpen(!isOrderDetailsOpen);
  };

  const toggleProducts = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const toggleSAPCrm = () => {
    setIsSAPCrmOpen(!isSAPCrmOpen);
  };

  return (
    <>
      <div className={`sidebar1 ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-header2">
        <Link to="/purchase">
          <img src={backgroundImage} alt="Company Logo" className="company-logo1" />
          </Link>
        </div>
        <ul className="sidebar-options1">
        <li className={`sidebar-option1 ${location.pathname === '/home' ? 'active' : ''}`}>
            <Link to="/home">
              <HouseFill className="sidebar-icon" />
              <span
                className="text"
                style={{ color: '#BFB1AE' }}
                onMouseOver={(e) => e.target.style.color = '#ffa353'}
                onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
              >Home</span>
            </Link>
          </li>
        <li className={`sidebar-option1 ${location.pathname === '/dealer' ? 'active' : ''}`}>
            <Link to="/dealer">
              <PersonFillAdd className="sidebar-icon" />
              <span
                className="text"
                style={{ color: '#BFB1AE' }}
                onMouseOver={(e) => e.target.style.color = '#ffa353'}
                onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
              >Choose Dealer</span>
            </Link>
          </li>
          <li className={`sidebar-option1 ${location.pathname === '/our-top-companies' ? 'active' : ''}`}>
            <Link to="/our-top-companies">
              <Clipboard className="sidebar-icon" />
              <span
                className="text"
                style={{ color: '#BFB1AE' }}
                onMouseOver={(e) => e.target.style.color = '#ffa353'}
                onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
              >Our Top Companies</span>
            </Link>
          </li>
          <li className={`sidebar-option1 ${location.pathname === '/products' ? 'active' : ''}`} onClick={() => toggleSubmenu('products')}>
          <Box className="sidebar-icon" />
          <button className="toggle-button-1" onClick={() => toggleSubmenu('products')}>
              {openSubmenu === 'products' ? <ChevronUp /> : <ChevronDown />}
            </button>
            <span className="text" onClick={() => toggleSubmenu('products')}>Products</span>
          </li>
          {openSubmenu === 'products' && (
            <ul className="sub-options">
              <li className="sub-option">
                <Link to="/catalogue">
                  <span
                    className="text"
                    style={{ color: '#BFB1AE' }}
                    onMouseOver={(e) => e.target.style.color = '#ffa353'}
                    onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
                  >Catalogue</span>
                </Link>
              </li>
              <li className="sub-option">
                <Link to="/purchase">
                  <span
                    className="text"
                    style={{ color: '#BFB1AE' }}
                    onMouseOver={(e) => e.target.style.color = '#ffa353'}
                    onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
                  >Purchase</span>
                </Link>
              </li>
            </ul>
          )}
          <li className={`sidebar-option1 ${location.pathname === '/order-history' ? 'active' : ''}`} onClick={() => toggleSubmenu('orderHistory')}>
          <span >
          < JournalMedical className="sidebar-icon" />
          <button className="toggle-button-1">
                {openSubmenu === 'orderHistory' ? <ChevronUp /> : <ChevronDown />}
              </button>
              <span className="text">Order History</span>
            </span>
          </li>
          {openSubmenu === 'orderHistory' && (
            <ul className="sub-options">
              <li className="sub-option">
              <Link to="/all_orders">                   
                  <span
                    className="text"
                    style={{ color: '#BFB1AE' }}
                    onMouseOver={(e) => e.target.style.color = '#ffa353'}
                    onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
                  >All Orders</span>
                </Link>
              </li>
              <li className="sub-option">
                <Link to="/waiting_orders">
                  <span
                    className="text"
                    style={{ color: '#BFB1AE' }}
                    onMouseOver={(e) => e.target.style.color = '#ffa353'}
                    onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
                  >Waiting Orders</span>
                </Link>
              </li>
            </ul>
          )}
         <li className={`sidebar-option1 ${location.pathname === '/sap-crm-leads' ? 'active' : ''}`} onClick={() => toggleSubmenu('sapCrm')}>
            <People className="sidebar-icon" />
            <button className="toggle-button-1">
              {openSubmenu === 'sapCrm' ? <ChevronUp /> : <ChevronDown />}
            </button>
            <span className="text" onClick={() => toggleSubmenu('sapCrm')}>SAP CRM Leads</span>
          </li>
          {openSubmenu === 'sapCrm' && (
            <ul className="sub-options">
              <li className="sub-option">
                <Link to="/enter-leads">
                  <span className="text">Enter Leads</span>
                </Link>
              </li>
              <li className="sub-option">
                <Link to="/open-leads">
                  <span
                    className="text"
                    style={{ color: '#BFB1AE' }}
                    onMouseOver={(e) => e.target.style.color = '#ffa353'}
                    onMouseOut={(e) => e.target.style.color = '#BFB1AE'}
                  >Open Leads</span>
                </Link>
              </li>
            </ul>
          )}         
        </ul>
        {isSidebarOpen ? (
      <Link to="/" onClick={handleLogout}>
        <button className="aa1">
          Logout
          <div className="arrow-wrapper">
            <div className="arrow"></div>
          </div>
        </button>
      </Link>
    ) : (
      // <div>
      <BoxArrowRight size={26} className='sidebar-icon' onClick={handleLogout} style={{ cursor: 'pointer',position: 'relative', top : '16%', left : '19px' }} />
      // </div>
    )}
        <div className="sidebar-footer">
          <p style={{ color: 'wheat' }}>&copy; Chesa Dental Care Sales Dashboard</p>
        </div>

        <ToastContainer />
      </div>
      <button className="toggle-button" onClick={toggleSidebar}>
        {isSidebarOpen ? <ChevronBarLeft /> : <ChevronBarRight />}
      </button>
    </>
  );
};

export default Sidebar;
