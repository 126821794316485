import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ExternalLink, X } from 'react-feather';
import Sidebar from '../../Sidebar/Sidebar'; // Adjust the import path as necessary
import { useSidebar } from '../../../ContextAPI/SidebarContext';
import { useCart } from '../../../ContextAPI/CartContext'; // Import the useCart hook
import './PurchasePage.css';
import { CircleArrowLeft, CircleArrowRight } from 'lucide-react'
import { useParams, useNavigate } from 'react-router-dom';
import NotificationButton from '../../../Notification/NotificationButton';
import { useAuth } from '../../../ContextAPI/AuthContext';


const PurchasePage = () => {
  const { name } = useParams();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(8);
  const { addToCart } = useCart();
  const { isSidebarOpen } = useSidebar();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentEntries, setCurrentEntries] = useState([]);
  const [dropdownCategory, setDropdownCategory] = useState('');
  const [suctionType, setSuctionType] = useState({});
  const [allProducts, setAllProducts] = useState([]);
  const [dType, setDType] = useState('');
  const [products, setProducts] = useState([]);
  const [chairColors, setChairColors] = useState({});
  const [remarks, setRemarks] = useState({}); // State to store remarks for each product
  const { user, token } = useAuth();
  const [notFoundCount, setNotFoundCount] = useState(0);
  const [sapData, setSapData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/');
    } else {

    }
  }, [user, token, navigate]);


  useEffect(() => {
    filterProducts();
  }, [name, selectedCompany, selectedCategory, allProducts]);

  const productbaseURL = 'https://chesadentalcare.com/possystem/admin/admin/uploads/product/';
  const companybaseURL = 'https://chesadentalcare.com/possystem/admin/admin/uploads/dealers/';
  const categorybaseURL = 'https://chesadentalcare.com/possystem/admin/admin/uploads/category/';

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined || isNaN(amount)) {
      return ''; // Return an empty string if amount is null, undefined, or NaN
    }
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  };

  useEffect(() => {
    // Your data fetching logic here

    // Simulate loading state (remove this in actual code)
    setTimeout(() => {
      setLoading(false); // Set loading to false after data is fetched
    }, 2000); // Simulate a delay
  }, []);
  

  useEffect(() => {
    fetch('https://api.chesadentalcare.com/company')
      .then(response => response.json())
      .then(data => setCompanies(data))
      .catch(error => console.error('Error fetching companies:', error));
  }, []);

  useEffect(() => {
    fetch('https://api.chesadentalcare.com/category')
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  useEffect(() => {
    const decodedName = decodeURIComponent(name);

    // Fetch all products
    fetch('https://api.chesadentalcare.com/products_all')
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        // Set all products initially with quantity 1
        const productsWithQuantity = data.map(product => ({ ...product, quantity: 1 }));
        setAllProducts(productsWithQuantity);

        let filtered = productsWithQuantity;

        // Filter by company name if present
        if (decodedName) {
          filtered = filtered.filter(product =>
            product.company === decodedName || product.cat_name === decodedName
          );
        }

        setFilteredProducts(filtered);
        setCurrentEntries(filtered);

        // Fetch colors for dental chairs
        productsWithQuantity.forEach(product => {
          if (product.cat_name === 'Dental Chairs' || product.cat_name === 'Combo Package') {
            fetchChairColors(product.name);
          }
        });

        // Extract item codes and call SAP API
        const itemCodes = productsWithQuantity.map(product => product.code); // Adjust 'code' based on your actual item code field
        console.log('Item Codes:', itemCodes);
        const storedSapData = sessionStorage.getItem('sapProductDetails');

        if (storedSapData) {
          const sapData = JSON.parse(storedSapData);
          console.log('Loaded SAP data from session storage:', sapData);
          setSapData(sapData);
          setLoading(false);
        } else {
          console.log('running on else block');
          fetch('https://api.chesadentalcare.com/products_sap', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ itemCodes }), // Send the item codes as an array
          })
            .then(response => {
              console.log('SAP API Response Status:', response.status); // Log SAP API response status
              if (!response.ok) {
                throw new Error(`SAP API response was not ok: ${response.statusText}`);
              }
              return response.json();
            })
            .then(fetchedSapData => {
              sessionStorage.setItem('sapProductDetails', JSON.stringify(fetchedSapData));
              console.log('Fetched SAP Product Details:', fetchedSapData); // Log the fetched SAP product details
              setSapData(fetchedSapData); // Store in state           
              // Process SAP data as needed
              setLoading(false);
            })
            .catch(error => console.error('Error fetching SAP product details:', error));
          setLoading(false);
        }
      }
      )
      .catch(error => console.error('Error fetching all products:', error));
  }, [name]);

  const filteredPrices = useMemo(() => {
    function filterByPriceList(sapData) {
      console.log('sapData', sapData);
      if (!Array.isArray(sapData)) {
        console.error('sapData is null, undefined, or not an array');
        return [];
      }

      const priceListNumber = Number(sessionStorage.getItem('PriceList'));

      if (isNaN(priceListNumber)) {
        console.error('Price list number is not valid');
        return [];
      }

      console.log('Price List Number:', priceListNumber);

      const filteredProducts = sapData.map(product => {
        if (!Array.isArray(product.ItemPrices)) {
          console.warn('ItemPrices is not an array or does not exist for product:', product);
          return null;
        }

        const matchingPrice = product.ItemPrices.find(price => price.PriceList === priceListNumber);

        if (matchingPrice) {
          return {
            itemCode: product.ItemCode, // Assuming `code` is the item code property
            price: matchingPrice.Price
          };
        } else {
          return null;
        }
      }).filter(product => product !== null);

      console.log('Filtered Products with Matching Price List:', filteredProducts);
      return filteredProducts;
    }

    return filterByPriceList(sapData);
  }, [sapData]);

  const priceListMap = {
    1: 'MRP',
    2: 'MSP',
    5: 'DP',
    6: 'SDP',
    9: 'CF'
  };

  const handleCompanyClick = (name) => {
    console.log('company name', name);
    setSelectedCompany(name);
    setSelectedCategory(null);

    const encodedName = encodeURIComponent(name);

    fetch(`https://api.chesadentalcare.com/company_details?company=${encodedName}`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched data:', data);

        const productsWithQuantity = data.map(product => ({ ...product, quantity: 1 }));
        setProducts(productsWithQuantity);
        setFilteredProducts(productsWithQuantity);
        setCurrentEntries(productsWithQuantity.slice(0, entriesPerPage));
      })
      .catch(error => console.error('Error fetching products:', error));
  };

  const filterProducts = useCallback(() => {
    let filteredProducts = allProducts;
    if (name) {
      filteredProducts = filteredProducts.filter(product => product.company === name);
    } else {
      if (selectedCompany) {
        filteredProducts = filteredProducts.filter(product => product.company_name === selectedCompany);
      }
      if (selectedCategory) {
        filteredProducts = filteredProducts.filter(product => product.cat_name === selectedCategory);
      }
    }
    setProducts(filteredProducts);
  }, [name, selectedCompany, selectedCategory, allProducts]);

  useEffect(() => {
    const id = sessionStorage.getItem('selectedDealer');
    if (id) {
      fetch(`https://api.chesadentalcare.com/dealer_info?id=${id}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(dealerData => {
          // Assuming dealerData is an array and we take the first element
          const dealer = dealerData[0];
          const { d_type } = dealer;
          setDType(d_type);

          // Filter or match products based on d_type (MSP, DP, SDP, etc.)
          const filteredProducts = allProducts.filter(product => {
            switch (d_type) {
              case 'MSP':
                return product.msp;
              case 'DP':
                return product.dp;
              case 'SDP':
                return product.sdp;
              case 'CF':
                return product.cf;
              default:
                return false;
            }
          });

          setProducts(filteredProducts);
        })
        .catch(error => console.error('Error fetching dealer info:', error));
    }
  }, [allProducts]);

  const renderPrice = (product) => {
    switch (dType) {
      case 'MSP':
        return formatCurrency(product.msp);
      case 'DP':
        return formatCurrency(product.dp);
      case 'SDP':
        return formatCurrency(product.sdp);
      case 'CF':
        return formatCurrency(product.cf);
      default:
        return formatCurrency(product.msp);
    }
  };


  const handleCategoryClick = (name) => {
    setSelectedCategory(name); // Set the selected category
    setSelectedCompany(null); // Reset the selected company

    fetch(`https://api.chesadentalcare.com/category_details?cat_name=${encodeURIComponent(name)}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Add quantity field to each product
        const productsWithQuantity = data.map(product => ({ ...product, quantity: 1 }));
        setProducts(productsWithQuantity);
        setFilteredProducts(productsWithQuantity); // Set filtered products
        setCurrentEntries(productsWithQuantity.slice(0, entriesPerPage)); // Update current entries for pagination
      })
      .catch(error => console.error('Error fetching products:', error));
  };


  const fetchChairColors = async (name) => {
    try {
      const response = await fetch(`https://api.chesadentalcare.com/chairs_color?name=${encodeURIComponent(name)}`);

      if (response.status === 404) {
        // Handle 404 not found case
        setNotFoundCount(prevCount => prevCount + 1);
        return; // Early exit, no further processing needed
      }

      if (!response.ok) {
        // Handle other response errors if needed
        console.error(`Error fetching chair colors: ${response.status}`);
        return;
      }

      const data = await response.json();
      setChairColors(prevColors => ({
        ...prevColors,
        [name]: data.length > 0 ? data : [] // Store data or an empty array if no data is found
      }));
    } catch (error) {
      // Handle network errors or unexpected errors
      console.error('Unexpected error:', error);
    }
  };

  const handleAddToCart = async (product, suctionType, remarks) => {
    console.log("Add to suc", suctionType);

    try {
      if (product.cat_name === 'Combo Package') {
        // Handle combo package logic as before
        const packageName = encodeURIComponent(product.name);
        const url = `https://api.chesadentalcare.com/package?package_name=${packageName}`;

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const packageItems = await response.json();

        const packageItem = packageItems.find(item => item.package_name === product.name);
        if (packageItem) {
          const itemsToAdd = [
            packageItem.pck1,
            packageItem.pck2,
            packageItem.pck3,
            packageItem.pck4,
            packageItem.pck5,
            packageItem.pck6
          ].filter(item => item !== "");

          const productsToAdd = itemsToAdd
            .map(itemName => allProducts.find(product => product.name === itemName))
            .filter(Boolean);

          for (const item of productsToAdd) {
            if (item) {
              const matchingProduct = sapData.find(sap => sap.ItemCode === item.code);
              if (matchingProduct) {
                const priceListNumber = Number(sessionStorage.getItem('PriceList'));
                const sapPriceData = matchingProduct.ItemPrices.find(price => price.PriceList === priceListNumber);
                const sapPrice = sapPriceData ? sapPriceData.Price : 'Not Available';

                console.log('Matching Product:', matchingProduct);
                console.log('Matching Product sapPrice:', sapPrice || 'No sapPrice found');

                const updatedItem = item.cat_name === 'Dental Chairs' ? { ...item, color: product.color } : item;
                await addToCart({ ...updatedItem, remarks, quantity: product.quantity, sapPrice: sapPrice });
              } else {
                console.error(`Matching SAP product not found for item: ${item.name}`);
              }
            } else {
              console.error(`Product "${itemName}" not found in allProducts.`);
            }
          }
        } else {
          console.error('Package not found in the response');
        }
      } else if (product.subcat_name === 'indian chairs' || product.subcat_name === 'indian chairs with pneumatic') {
        // Ensure suctionType is correctly obtained

        const selectedSuctionType = product.suctionType;

        if (selectedSuctionType === 'Pneumatic Suction') {
          // Directly add the product to the cart with suctionType as Pneumatic Suction
          console.log('Adding Pneumatic Suction to cart:', { ...product, suctionType: 'Pneumatic Suction', remarks });
          await addToCart({ ...product, suctionType: 'Pneumatic Suction', remarks });
          return;  // Exit early to avoid processing other suction types
        }
        if (selectedSuctionType && selectedSuctionType !== 'Suction 0.25 HP INBUILT') {
          const suctionProduct = allProducts.find(p => p.name === selectedSuctionType);

          if (suctionProduct) {
            // Find the matching product from SAP data
            const matchingProduct = sapData.find(sap => sap.ItemCode === suctionProduct.code);

            if (matchingProduct) {
              const priceListNumber = Number(sessionStorage.getItem('PriceList'));
              const sapPriceData = matchingProduct.ItemPrices.find(price => price.PriceList === priceListNumber);
              const sapPrice = sapPriceData ? sapPriceData.Price : 'Not Available';

              console.log('Matching Product:', matchingProduct);
              console.log('Matching Product sapPrice:', sapPrice || 'No sapPrice found');
              console.log('Suction added to cart:', suctionProduct);

              await addToCart({ ...suctionProduct, quantity: product.quantity, sapPrice: sapPrice });
            } else {
              console.error(`Matching SAP product not found for item: ${suctionProduct.name}`);
            }
          } else {
            console.error(`Suction type "${selectedSuctionType}" not found in allProducts.`);
          }
        }
        console.log('Selected Suction Type:', selectedSuctionType);
        // Log an error and exit if suctionType is missing
        if (!selectedSuctionType) {
          console.error('Selected suction type is missing from product:', product);
          return;  // Exit early if suctionType is critical
        }

        // Find the suction product based on the selected type
        const suctionProduct = allProducts.find(p => p.name === selectedSuctionType);
        console.log('Selected Suction Type:', selectedSuctionType, 'Suction Product:', suctionProduct);

        if (suctionProduct) {
          let matchingProduct;

          // Handle specific logic only for Suction 0.25 HP INBUILT
          if (selectedSuctionType === 'Suction 0.25 HP INBUILT') {
            console.log('All Products:', allProducts);
            if (product.name === 'Onyx Premium') {
              matchingProduct = allProducts.find(p => p.code === 'DCH 194');
            } else if (product.name === 'Onyx Regular') {
              matchingProduct = allProducts.find(p => p.name.trim().replace(/\n/g, '') === 'ONYX REGULAR (NEW) DENTAL CHAIR WITH UNDERHANGING DELIVERY SYSTEM , LED LIGHT WITH ASSISTANT TRAY AND MOTORISED SUCTION');
            } else if (product.name === 'New Jwala Chair') {
              matchingProduct = allProducts.find(p => p.code === 'DCH 179');
            } else if (product.name === 'JAL Chair') {
              matchingProduct = allProducts.find(p => p.code === 'DCH 193');
            } else if (product.name === 'Ninja N4') {
              matchingProduct = allProducts.find(p => p.code === 'DCH 102');
            }

            console.log('Matching Product:', matchingProduct); // Log the entire object
            console.log('Matching Product sapPrice:', matchingProduct ? matchingProduct.sapPrice : 'No matching product found');


            if (matchingProduct) {
              const priceListNumber = Number(sessionStorage.getItem('PriceList'));
              const sapProduct = sapData.find(sap => sap.ItemCode === matchingProduct.code);
              const sapPriceData = sapProduct.ItemPrices.find(price => price.PriceList === priceListNumber);
              const sapPrice = sapPriceData ? sapPriceData.Price : 'Not Available';
              console.log('Matching Product:', sapPrice);
              console.log('Matching Product sapPrice:', sapPrice || 'No sapPrice found');
              console.log('Adding to cart:', { ...matchingProduct, sapPrice: sapPrice, suctionType: suctionProduct.name, quantity: product.quantity, remarks });
              await addToCart({ ...matchingProduct, color: product.color, sapPrice: sapPrice, suctionType: suctionProduct.name, quantity: product.quantity, remarks });
            } else {
              console.error('Matching product not found in allProducts.');
            }
          } else {
            // For other suction types, directly add the suction product to the cart
            console.log('Adding other suction type to cart:', { ...product, suctionType: suctionProduct.name, remarks });
            await addToCart({ ...product, suctionType: suctionProduct.name, remarks });
          }
        } else {
          console.error(`Suction type "${selectedSuctionType}" not found in allProducts.`, allProducts);
        }
      }
      else {
        console.log('Product added to cart:', { product, remarks });
        await addToCart({ ...product, remarks });

        const selectedSuctionType = product.suctionType;
        if (selectedSuctionType && selectedSuctionType !== 'Pneumatic Suction') {
          const suctionProduct = allProducts.find(p => p.name === selectedSuctionType);
          if (suctionProduct) {
            const priceListNumber = Number(sessionStorage.getItem('PriceList'));

            // Define matchingProduct by finding it in sapData based on the product code
            const matchingProduct = sapData.find(sap => sap.ItemCode === suctionProduct.code);

            if (matchingProduct) {
              const sapPriceData = matchingProduct.ItemPrices.find(price => price.PriceList === priceListNumber);
              const sapPrice = sapPriceData ? sapPriceData.Price : 'Not Available';

              console.log('Matching Product:', matchingProduct);
              console.log('Matching Product sapPrice:', sapPrice || 'No sapPrice found');

              console.log('Suction added to cart:', suctionProduct);
              await addToCart({ ...suctionProduct, quantity: product.quantity, sapPrice: sapPrice });
            } else {
              console.error('Matching SAP product not found for suction product:', suctionProduct);
            }
          } else {
            console.error(`Suction type "${selectedSuctionType}" not found in allProducts.`);
          }
        }

      }

    } catch (error) {
      console.error(`Error handling add to cart for ${product.name}:`, error);
    }
  };


  const handleQuantityChange = (id, value) => {
    // Function to update the quantity in a product array
    const updateQuantity = (productArray) => {
      return productArray.map(product =>
        product.id === id ? { ...product, quantity: value } : product
      );
    };

    // Update the `allProducts` state to ensure consistency across all filters
    setAllProducts(prevAllProducts => updateQuantity(prevAllProducts));

    // Update the `filteredProducts` state to reflect changes in the current view
    setFilteredProducts(prevFilteredProducts => updateQuantity(prevFilteredProducts));

    // Update the `currentEntries` to ensure displayed entries are also updated
    setCurrentEntries(prevCurrentEntries => updateQuantity(prevCurrentEntries));
  };

  const handleDropdownChange = (event) => {
    const selectedCategory = event.target.value;
    setDropdownCategory(selectedCategory);
    handleCategoryClick(selectedCategory);
  };

  const renderAddToCartButton = (product, suctionType) => {
    return (
      <td>
        <button className="CartBtn" onClick={() => handleAddToCart(product, suctionType, remarks[product.id] || '')}>
          <span className="IconContainer">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" fill="rgb(17, 17, 17)" className="cart"><path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"></path></svg>
          </span>
          <p className="namesnow">Add to Cart</p>
        </button>
        {/* <button onClick={() => handleAddToCart(product, suctionType, remarks[product.id] || '')} className="action-button">Add to Cart</button> */}
      </td>

    );
  };


  const handleRemarksChange = (productId, remarksText) => {
    // console.log("remarks", remarksText)
    setRemarks(prevRemarks => ({
      ...prevRemarks,
      [productId]: remarksText
    }));
    // console.log("overall",remarks)
  };

  const renderSuctionTypeDropdown = (product) => {
    if (product.cat_name === 'Dental Chairs') {
      return (
        <td>
          <select
            value={suctionType[product.id] || ''}
            onChange={(e) => handleSuctionTypeChange(product.id, e.target.value)}
          >
            <option value="">Select Suction Type</option>
            <option value="Pneumatic Suction">Pneumatic Suction</option>
            <option value="Suction 0.25 HP Mobile">Suction 0.25 HP Mobile</option>
            <option value="Suction 0.25 HP INBUILT">Suction 0.25 HP INBUILT</option>
            <option value="Durr VS-250">Durr VS-250</option>
            <option value="Durr VS-300">Durr VS-300</option>
          </select>
        </td>
      );
    } else if (product.cat_name === 'Indian Chairs' && product.code !== 'DCH 193') {
      return (
        <td>
          <select
            value={suctionType[product.id] || ''}
            onChange={(e) => handleSuctionTypeChange(product.id, e.target.value)}
          >
            <option value="">Select Suction Type</option>
            <option value="Suction 0.25 HP INBUILT">Suction 0.25 HP INBUILT</option>
          </select>
        </td>
      );
    } else if (product.cat_name === 'Indian Chairs' && product.code === 'DCH 193') {
      return (
        <td>
          <select
            value={suctionType[product.id] || ''}
            onChange={(e) => handleSuctionTypeChange(product.id, e.target.value)}
          >
            <option value="">Select Suction Type</option>
            <option value="Pneumatic Suction">Pneumatic Suction</option>
          </select>
        </td>
      );
    } else {
      return <td>Not Applicable</td>;
    }
  };



  const handleSuctionTypeChange = (productId, suctionType) => {
    // Update the suctionType in the suctionTypes state (if you're tracking it separately)
    setSuctionType(prevSuctionTypes => ({
      ...prevSuctionTypes,
      [productId]: suctionType
    }));

    // Function to update the suctionType in a product array
    const updateSuctionType = (productArray) => {
      return productArray.map(product =>
        product.id === productId ? { ...product, suctionType: suctionType } : product
      );
    };

    // Update the `allProducts` state to ensure consistency across all filters
    setAllProducts(prevAllProducts => updateSuctionType(prevAllProducts));

    // Update the `filteredProducts` state to reflect changes in the current view
    setFilteredProducts(prevFilteredProducts => updateSuctionType(prevFilteredProducts));

    // Update the `currentEntries` to ensure displayed entries are also updated
    setCurrentEntries(prevCurrentEntries => updateSuctionType(prevCurrentEntries));
  };


  const handleColorChange = (productId, color) => {
    // Function to update the color in a product array
    const updateColor = (productArray) => {
      return productArray.map(product =>
        product.id === productId ? { ...product, color: color } : product
      );
    };

    // Update the `products` state
    setProducts(prevProducts => updateColor(prevProducts));

    // Update the `allProducts` state to ensure consistency across all filters
    setAllProducts(prevAllProducts => updateColor(prevAllProducts));

    // Update the `filteredProducts` state to reflect changes in the current view
    setFilteredProducts(prevFilteredProducts => updateColor(prevFilteredProducts));

    // Update the `currentEntries` to ensure displayed entries are also updated
    setCurrentEntries(prevCurrentEntries => updateColor(prevCurrentEntries));
  };


  const renderColorDropdown = (product) => {
    const colors = chairColors[product.name]; // Assuming chairColors is an object

    if (!colors || colors.length === 0) {
      return <td>Not Applicable</td>;
    }

    // Check if product category is 'Dental Chairs' or 'Combo Package'
    if (product.cat_name === 'Dental Chairs' || product.cat_name === 'Combo Package') {
      return (
        <td>
          <select
            value={product.color || ""}
            onChange={(e) => handleColorChange(product.id, e.target.value)}
          >
            <option value="">Select Color</option>
            {colors.map((color, index) => (
              <option key={index} value={color.color}>{color.color}</option>
            ))}
          </select>
        </td>
      );
    } else if (product.cat_name === 'Indian Chairs') {
      // Render different content for 'Another Category'
      return (
        <td>
          <select
            value={selectedColor}
            onChange={(e) => handleColorChange(product.id, e.target.value)}
          >
            <option value="">Select a color</option>
            <option value="CH-F - Purple" style={{ color: '#A020F0' }}>CH-F - Purple</option>
            <option value="CH-P - Apple Green" style={{ color: '#3f9999' }}>CH-P - Apple Green</option>
            <option value="CH-D - Dark Blue" style={{ color: '#39609b' }}>CH-D - Dark Blue</option>
            <option value="CH-B - Sea Green" style={{ color: '#2E8B57' }}>CH-B - Sea Green</option>
            <option value="CH-K - Putty" style={{ color: '#e7df94' }}>CH-K - Putty</option>
            <option value="CH-E - Maroon" style={{ color: '#800000' }}>CH-E - Maroon</option>
            <option value="CH-C - Deep Blue" style={{ color: '#00008B' }}>CH-C - Deep Blue</option>
            <option value="CH-O - Off White" style={{ color: '#e1ded7' }}>CH-O - Off White</option>
            <option value="CH-Q - Silver" style={{ color: '#808080' }}>CH-Q - Silver</option>
            <option value="CH-H - Pink" style={{ color: '#E0115F' }}>CH-H - Pink</option>
            <option value="CH-J - Yellow" style={{ color: '#FFFF00' }}>CH-J - Yellow</option>
            <option value="CH-I - Orange" style={{ color: '#c55530' }}>CH-I - Orange</option>
            <option value="CH-A - Gross Green" style={{ color: '#388004' }}>CH-A - Gross Green</option>
            <option value="CH-L - Brown" style={{ color: '#964B00' }}>CH-L - Brown</option>
            <option value="CH-R - Sky Blue" style={{ color: '#87CEEB' }}>CH-R - Sky Blue</option>
            <option value="CH-M - Copper" style={{ color: '#B87333' }}>CH-M - Copper</option>
          </select>
          {/* Your custom rendering logic for 'Another Category' */}
        </td>
      );
    } else if (product.cat_name === 'Yet Another Category') {
      // Render different content for 'Yet Another Category'
      return (
        <td>
          {/* Your custom rendering logic for 'Yet Another Category' */}
          <p>Custom content for Yet Another Category</p>
        </td>
      );
    } else {
      return <td>Not Applicable</td>;
    }
  };


  const renderProductRow = (product) => (
    <tr key={product.id}>
      <td>{product.name}</td>
      {renderSuctionTypeDropdown(product)}
      {renderColorDropdown(product)}
    </tr>
  );


  const displayedProducts = filteredProducts.length > 0 ? filteredProducts : allProducts;

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries1 = displayedProducts.slice(indexOfFirstEntry, indexOfFirstEntry + entriesPerPage);
  const totalPages = Math.ceil(displayedProducts.length / entriesPerPage);

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const maxPageNumbersToShow = 5;
  const pageGroup = Math.ceil(currentPage / maxPageNumbersToShow);
  const startPage = (pageGroup - 1) * maxPageNumbersToShow + 1;
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const scrollCompanies = (direction) => {
    const container = document.querySelector('.purchase-top-companies');
    const scrollAmount = 200; // Adjust as necessary
    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollCategories = (direction) => {
    const container = document.querySelector('.purchase-category');
    const scrollAmount = 200; // Adjust as necessary
    if (direction === 'left') {
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const currentEntriesWithPrices = useMemo(() => {
    const priceMap = new Map(filteredPrices.map(price => [price.itemCode, price.price]));

    return currentEntries1.map(product => ({
      ...product,
      sapPrice: priceMap.get(product.code) || 'Not Available'
    }));
  }, [currentEntries1, filteredPrices]);

  return (
    <>
      <div className={`sales-dashboard-chesa ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)' }}>
        <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start', position: 'relative', top: '15px' }}>
          <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '36px' }}>
            Navigate to Chesa Services
            <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
              <ExternalLink size={20} />
            </a>
            <span className='notibu'><NotificationButton /></span>
          </p>

        </div>
        <div className="header">
          <h1 className="company-name1" style={{ position: 'relative', top: '5px', left: '23px' }}>Place Order</h1><br />
          <div style={{ position: "relative", right: "5%" }}>
            {/* <ModalWrapper handleFilter={handleFilter} /> */}
          </div>

        </div>
        <Sidebar />
        <div className="main-content" style={{ left: "87%" }}>
          <div className='purchase-page'>

            <div className="purchase-companies-list-container">
              <h2>Select a Company</h2>
              <div className="purchase-companies-list">
                <button className="chevron-button-purchase-left" onClick={() => scrollCompanies('left')}><span><CircleArrowLeft /></span></button>
                <div className="purchase-top-companies">
                  {companies.map(company => (
                    <div key={company.id} className="purchase-company-card" onClick={() => handleCompanyClick(company.company)}>
                      <div className="purchase-image-container">
                        <img src={`${companybaseURL}${company.image}`} alt={company.company} className="purchase-company-image" />
                      </div>
                    </div>
                  ))}
                </div>
                <button className="chevron-button-purchase-right" onClick={() => scrollCompanies('right')}><span><CircleArrowRight /></span></button>
              </div>
            </div>
            <div className="purchase-category-list-container">
              <h2>Select a Category</h2>
              <div className="purchase-category-list">
                <button className="chevron-button-purchase-left" onClick={() => scrollCategories('left')}><span><CircleArrowLeft /></span></button>
                <div className="purchase-category">
                  {categories.map(category => (
                    <div key={category.id} className="purchase-category-card" onClick={() => handleCategoryClick(category.cat_name)}>
                      <div className="purchase-image-container">
                        <img src={`${categorybaseURL}${category.image}`} alt={category.cat_name} className="purchase-category-image" />
                      </div>
                      <h3 className="purchase-category-name">{category.cat_name}</h3>
                    </div>
                  ))}
                </div>
                <button className="chevron-button-purchase-right" onClick={() => scrollCategories('right')}><span><CircleArrowRight /></span></button>
              </div>
            </div>
            <div className="purchase-product-details-container" >
              <div className="category-dropdown">
                <select value={dropdownCategory} onChange={handleDropdownChange} className='purchase-select-dropdown'>
                  <option value="">Select Category</option>
                  {categories.map(category => (
                    <option key={category.id} value={category.cat_name}>{category.cat_name}</option>
                  ))}
                </select>
              </div>
              <div className="purchase-product-details">
                <div>
                  <h2>{selectedCompany ? `Products for ${selectedCompany}` : selectedCategory ? ` Products for ${selectedCategory}` : 'All Products'}</h2>
                  <div className="purchase-product-table-wrapper">
                    <table className={`tableman ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} >
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Item Code</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Price</th>
                          <th>Sap Price</th>
                          <th>Quantity</th>
                          <th>Suction Type</th>
                          <th>Color</th>
                          <th>Specification</th>
                          <th>Add to Cart</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentEntriesWithPrices.map(product => (
                          <tr key={product.id}>
                            <td>{product.id}</td>
                            <td>{product.code}</td>
                            <td><img src={`${productbaseURL}${product.image}`} alt={product.code} className="purchase-product-image" /></td>
                            <td>{product.name}</td>
                            <td>{renderPrice(product)}</td>
                            <td>
                              {loading ? (
                                <span>Loading...</span> // Show loading indicator while SAP price is loading
                              ) : (
                                formatCurrency(product.sapPrice) || "Loading..."// Show actual SAP price once loaded
                              )}
                            </td> {/* Display SAP Price */}
                            <td>
                              <input
                                type="number"
                                value={product.quantity}
                                onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value, 10))}
                                min="1"
                                className="quantity-input"
                              />
                            </td>

                            {renderSuctionTypeDropdown(product)}
                            {renderColorDropdown(product)}
                            <td>
                              <textarea
                                className='textarea-purchase'
                                placeholder="Enter remarks"
                                value={remarks[product.id] || ''}
                                onChange={(e) => handleRemarksChange(product.id, e.target.value)}
                              />
                            </td> {/* Placeholder for specification */}
                            {renderAddToCartButton(product)}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="pagination">
                      <button onClick={handlePrevPage} disabled={currentPage === 1} className="pagination-button">Previous</button>
                      {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map(page => (
                        <button
                          key={page}
                          onClick={() => handlePageClick(page)}
                          className={`pagination-button ${currentPage === page ? 'page-button active' : ''}`}
                        >
                          {page}
                        </button>
                      ))}
                      <button onClick={handleNextPage} disabled={currentPage === totalPages} className="pagination-button">Next</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PurchasePage;

