import React , { useState, useEffect }from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from '../LoginSignUp/LoginPage';
import Signup from '../LoginSignUp/SignUp';
import { useLocation } from 'react-router-dom';
import AllOrders from '../Pages/AllOrders/AllOrders';
import WaitingOrders from '../Pages/WatingOrders/WaitingOrders';
import ReadyForDispatch from '../Pages/ReadyForDispatch/ReadyForDispatch';
import catalogue from '../Pages/CataloguePage/CataloguePage'
import DeliveredOrders from '../Pages/DeliverdOrders/DeliveredOrders';
import OrderDetails from '../Pages/OrderDetails/OrderDetails';
import Grn from '../Pages/DC_Creation/Grn';
import Error404 from '../../ErrorPages/Error404';
import Error500 from '../../ErrorPages/Error500';
import ProductionRouting from '../ProductionRouting/ProductionRouting';
import Release_order from '../ProductionRouting/Release_order';
import CartPage from '../../Notification/Cartpage/CartPage';
import EnterLeads from '../Pages/EnterLeads/EnterLeads';
import OurTopCompanies from '../Pages/OurTopCompanies/OurTopCompanies';
import CataloguePage from '../Pages/CataloguePage/CataloguePage';
import PurchasePage from '../Pages/PurchasePage/PurchasePage';
import OpenLeads from '../Pages/OpenLead/OpenLeads'
import ViewPage from '../Pages/OpenLead/ViewPage'
import IndUpdate from '../Pages/AllOrders/IndUpdate';
import IndOrderDetails from '../Pages/AllOrders/IndOrderDetails';
import NewOrderForm from '../Pages/AllOrders/NewOrderForm';
import Dealer from '../Pages/Dealer/DealerSelectionPage'
import TestPurchasePage from '../Pages/PurchasePage/TestingPurchase';
import  Home  from '../Home/Home';

const AppRoutes = () => {
  const [ user, setUser ]= useState();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      setUser({ token });
      console.log(token)
    }
    setLoading(false);
  }, [setUser]);

  if (loading) {
    return <div className='loader1'>Loading...</div>;
  }

  return (
    <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/404" element={<Error404 />} />
    <Route path="/500" element={<Error500 />} />
    <Route path="/production_consumption" element={<ProductionRouting />} />
    <Route path="/testpurchase" element={<TestPurchasePage />} />
    
    {user ? (
      <>
       
        <Route path="/waiting_orders" element={<WaitingOrders />} />
        <Route path="/home" element={<Home />} />
        <Route path="/all_orders" element={<AllOrders />} />
        <Route path="/ready-for-dispatch" element={<ReadyForDispatch />} />
        <Route path="/delivered-orders" element={<DeliveredOrders />} />
        <Route path="/order_details/:orderId" element={<OrderDetails />} />
        <Route path="/grn/:orderId" element={<Grn />} />
        <Route path="/release/:orderId" element={<Release_order />} />
        <Route path="cart-page" element={<CartPage />} />
        <Route path="/enter-leads" element={<EnterLeads/>}/>
        <Route path="/our-top-companies" element={<OurTopCompanies/>}/>
        <Route path="/catalogue" element={<CataloguePage/>}/>
        <Route path="/purchase/:name?" element={<PurchasePage />} />
        
        <Route path='/open-leads' element={<OpenLeads/>}/>
        <Route path="/lead/:SequentialNo" element={<ViewPage/>} />
        <Route path='/order/:id' element={<IndUpdate/>}/>
        {/* <Route path='/purchasepage/:name' element={<PurchasePage/>}/> */}
        <Route path='/ind-order-details/:id' element={<IndOrderDetails/>}/>
        <Route path='/new-order-form' element={<NewOrderForm/>}/>
        <Route path='/dealer' element={<Dealer/>}/>
      </>
    ) : (
      <Route path="*" element={<Navigate to="/" state={{ from: location }} />} />
    )}
  </Routes>
  );
};

export default AppRoutes;

