import React, { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import Sidebar from '../../Sidebar/Sidebar';
import { ExternalLink } from 'react-feather';
import NotificationButton from '../../../Notification/NotificationButton';
import { useSidebar } from '../../../ContextAPI/SidebarContext';
import axios from 'axios';
import './IndUpdate.css'; // Importing the CSS file

const IndUpdate = () => {
    const { isSidebarOpen } = useSidebar();
    const { id } = useParams(); // Get 'id' from URL params
    const [orders, setOrders] = useState([]);
    const [individualOrderDetails, setIndividualOrderDetails] = useState([]);
    const [error, setError] = useState(null);
    const [orderData, setOrderData] = useState(null); // Added state for orderData
    const baseURL = 'https://chesadentalcare.com/possystem/admin/admin/uploads/product/';
    
    useEffect(() => {
        const fetchOrderData = async () => {
            try {
                const orderURL = `https://api.chesadentalcare.com/get_orderId?ids=${id}`;
                console.log(`Fetching order details from: ${orderURL}`);
                
                // Fetch general order details
                const orderResponse = await axios.get(orderURL);
                console.log('Order Response:', orderResponse.data);

                if (orderResponse.data.length === 0) {
                    throw new Error('No order data available');
                }
                const orderData = orderResponse.data[0];
                setOrders(orderResponse.data);
                setOrderData(orderData); // Set the order data

                // Use the id from the fetched order data for the individual details request
                const individualURL = `https://api.chesadentalcare.com/idv_update?id=${orderData.id}`;
                console.log(`Fetching individual order details from: ${individualURL}`);

                // Fetch individual product details
                const individualResponse = await axios.get(individualURL);
                console.log('Individual Order Response:', individualResponse.data);

                if (individualResponse.data.length === 0) {
                    throw new Error('No individual order data available');
                }
                setIndividualOrderDetails(individualResponse.data); // Assuming the response is an array with multiple objects
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(`Error fetching data: ${error.message}`);
            }
        };

        fetchOrderData();
    }, [id]);

    if (error) {
        return <div className="error-message">Error: {error}</div>;
    }

    if (!orders.length || !individualOrderDetails) {
        return <div className="loading-message">Loading...</div>;
    }

    return (
        <>
            <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)'}}>
            <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start', position: 'relative', top: '15px' }}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '23px' }}>
                        Navigate to Chesa Services
                        <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
                            <ExternalLink size={20} />
                        </a>
                        <NotificationButton />
                    </p>
                    {/* <InfoButton /> */}
                </div>
                <div className="header">
                    <h1 className="company-name1" style={{ position: 'relative', top: '5px', left: '23px' }}>Order Details</h1><br />
                    <div style={{ position: "relative", right: "5%" }}>
                        {/* <ModalWrapper handleFilter={handleFilter} /> */}
                    </div>
                </div>
                <Sidebar />
                <div className='IndUpdate-button'>
                    <Link to={`/ind-order-details/${orderData.id}`} className='IndUpdate-action-button'>View All</Link>
                </div>
                <div className="main-content">
                    <div>
                        
                        {error && <p>{error}</p>}
                        <div className="order-container">

                            {individualOrderDetails.map((order, index) => (
                                <div key={index} className="order-card">
                                    
                                    <ul>
                                        <li> {order.product_name}</li>
                                        <li>Quantity: {order.qty}</li>
                                        <li>Price: {order.product_price}</li>
                                        <li>
                                            <img src={`${baseURL}${order.product_image}`} alt="product" className="company-image" />
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndUpdate;
