import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faClose, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './Notification.css'; // Import custom CSS for styling
import { useCart } from '../ContextAPI/CartContext';

const NotificationButton = () => {
  const [showModal, setShowModal] = useState(false);
  const [cartCount, setCartCount] = useState(0); // State for cart count
  const { cart } = useCart(); // Accessing cart context
  const [dueToday, setDueToday] = useState([]);
  const [readNotifications, setReadNotifications] = useState(new Set());

  useEffect(() => {
    // Fetch cart count from your API or state management
    const fetchCartCount = async () => {
      try {
        const response = await axios.get('https://api.chesadentalcare.com/cart'); // Replace with your actual cart API endpoint
        setCartCount(response.data.cartCount);
      } catch (error) {
        console.error('Error fetching cart count:', error);
      }
    };

    fetchCartCount();
  }, []);

  useEffect(() => {
    // Calculate cart count from context
    if (cart) {
      setCartCount(cart.length); // Assuming cart is an array of items
    }
  }, [cart]);

  useEffect(() => {
    // Fetch leads automatically on component mount to check for follow-ups due today
    const checkFollowUps = async () => {
      try {
        const response = await axios.get('https://api.chesadentalcare.com/open_leads');
        const today = new Date().toISOString().split('T')[0];
        const leads = response.data.value; // Assuming leads are in response.data.value

        // Filter leads where PredictedClosingDate is today
        const leadsDueToday = leads.filter(lead => lead.SalesOpportunities.PredictedClosingDate === today);
        setDueToday(leadsDueToday); // Update dueToday state with filtered leads
      } catch (error) {
        console.error('Error checking follow-up dates:', error);
      }
    };

    checkFollowUps();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleClick = () => {
    setShowModal(true); // Open the notification modal when the button is clicked
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const markAsRead = (index) => {
    setReadNotifications((prevReadNotifications) => new Set(prevReadNotifications).add(index));
  };

  const unreadCount = dueToday.length - readNotifications.size;

  return (
    <div className="notification-container">
      <Link to="/cart-page">
        <button className="cart-button">
          <FontAwesomeIcon icon={faShoppingCart} />
          {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
        </button>
      </Link>
      <button className="entry" onClick={handleClick}>
        <FontAwesomeIcon icon={faBell} />
        {unreadCount > 0 && <span className="unread-count">{unreadCount}</span>} {/* Display unread count */}
      </button>
      {showModal && (
        <div className="notification-modal-overlay">
          <div className="notification-modal">
            <button className="exit" onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </button>
            <h2>Follow-Up Due Today</h2>
            <p>You have {dueToday.length} leads with follow-up due today:</p>
            
            <ul className="leads-list">
              {dueToday.map((lead, index) => (
                <li key={index} className="lead-item">
                  <div className="lead-details">
                    <strong>Customer Name:</strong> {lead.BusinessPartners.CardName}<br />
                    <strong>Contact:</strong> {lead.BusinessPartners.Cellular}
                  </div>
                  {!readNotifications.has(index) && (
                    <button className="mark-as-read-button" onClick={() => markAsRead(index)}>Mark as Read</button>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
