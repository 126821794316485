import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../Sidebar/Sidebar";
import { ExternalLink } from "react-feather";
import NotificationButton from "../../../Notification/NotificationButton";
import { useSidebar } from "../../../ContextAPI/SidebarContext";
import "../AllOrders/IndOrderDetails.css"; // Import the CSS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faCheck,
  faCogs,
  faTruck,
  faBoxOpen,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";

const progressMapping = {
  "Order Placed": 18,
  "Confirm": 34,
  "Processing": 51,
  "Dispatched": 67,
  "Delivered": 83,
  "Installation Completed": 100,
};


const statusSequence = [
  "Order Placed",
  "Confirm",
  "Processing",
  "Dispatched",
  "Delivered",
  "Installation Completed",
];

const statusMapping = {
  "Order Placed": "Order Placed",
  "Advance Received": "Confirm",
  "Job Card Issued": "Processing",
  "Dispatched": "Dispatched",
  "Delivered": "Delivered",
  "Installation Completed": "Installation Completed",
};

const iconMapping = {
  "Order Placed": faShoppingCart,
  "Confirm": faCheck,
  "Processing": faCogs,
  "Dispatched": faTruck,
  "Delivered": faBoxOpen,
  "Installation Completed": faWrench,
};

const IndOrderDetails = () => {
  const { id } = useParams();
  const [orderDetails, setOrderDetails] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [error, setError] = useState(null);
  const { isSidebarOpen } = useSidebar();
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const baseURL =
    "https://chesadentalcare.com/possystem/admin/admin/uploads/receipt/";

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const orderResponse = await axios.get(
          `https://api.chesadentalcare.com/order_detail/?id=${id}`
        );
        console.log("Order Details:", orderResponse.data); // Debugging log
        setOrderDetails(orderResponse.data);
        // Set order status after fetching order details
        const mappedStatus = statusMapping[orderResponse.data[0].status];
        console.log("Mapped Status:", mappedStatus); // Debugging log
        setOrderStatus(mappedStatus); // Assuming 'status' is the correct field name
      } catch (error) {
        console.error("Error fetching order details:", error);
        setError(`Error fetching order details: ${error.message}`);
      }
    };
    const fetchProductDetails = async () => {
      try {
        const productResponse = await axios.get(
          `https://api.chesadentalcare.com/idv_update?id=${id}`
        );
        setProductDetails(productResponse.data);
        console.log(productDetails)
      } catch (error) {
        console.error("Error fetching product details:", error);
        setError(`Error fetching product details: ${error.message}`);
      }
    };

    fetchOrderDetails();
    fetchProductDetails();
  }, [id]);

  const handleViewReceipt = async () => {
    try {
      const response = await fetch(
        `https://api.chesadentalcare.com/order_receipt/?id=${id}`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Assuming the data is an array and you need the first item
      const receipt = data[0];
      if (receipt && receipt.pay_rcpt) {
        const receiptURL = `${baseURL}${receipt.pay_rcpt}`;
        console.log("Receipt URL:", receiptURL);

        setReceiptDetails(receipt);
        setShowReceiptModal(true);
      } else {
        throw new Error("Receipt data is not in the expected format.");
      }
    } catch (error) {
      console.error("Error fetching receipt details:", error);
      setError(`Error fetching receipt details: ${error.message}`);
    }
  };

  const handleCloseReceiptModal = () => {
    setShowReceiptModal(false);
    setReceiptDetails(null);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!orderDetails || !productDetails.length) {
    return <div>Loading...</div>;
  }

  const { total_amount, paid_amount, pending_amount, shipping_type, log_type } =
    orderDetails[0];
  const { status, specification, remarks } = productDetails[0];
  const allSpecifications = productDetails
    .map((product) => product.specification)
    .filter((spec) => spec)
    .join(", ");
  const allRemarks = productDetails
    .map((product) => product.remarks)
    .filter((remark) => remark)
    .join(", ");

  const shippingAddress = {
    doctorName: orderDetails[0].name,
    shipAddress: orderDetails[0].address,
    phone: orderDetails[0].phone,
    email: orderDetails[0].email,
    city: orderDetails[0].bcity,
    state: orderDetails[0].state,
    pinCode: orderDetails[0].pincode,
  };

  const billingAddress = {
    doctorName: orderDetails[0].bname,
    shipAddress: orderDetails[0].baddress,
    phone: orderDetails[0].bphone,
    email: orderDetails[0].bemail,
    city: orderDetails[0].bcity,
    state: orderDetails[0].bstate,
    pinCode: orderDetails[0].bpincode,
  };

  const deliveryDetails = {
    orderId: orderDetails[0].id,
    logisticPartner: orderDetails[0].logistic,
    logisticId: orderDetails[0].logistic_status,
    orderDate: orderDetails[0].order_date,
    orderExpectedDate: orderDetails[0].expect_date,
    clinicOpeningDate: orderDetails[0].opening_date,
    shippingDate: orderDetails[0].ship_date,
    estimateDeliveryDate: orderDetails[0].delivery_date,
  };

  const handlePrint = () => {
    window.print();
  };

  const viewReceiptUrl = `https://api.chesadentalcare.com/view_receipt?id=${id}`;

  const getProgressPercentage = () => {
    
    
    const currentIndex = statusSequence.indexOf(orderStatus) === -1 ? 0 : statusSequence.indexOf(orderStatus);
    console.log("Current Index:", currentIndex); // Debugging log
    return ((currentIndex + 1) / statusSequence.length) * 100;
  };

  const getActiveStepIndex = () => {
    const currentPercentage = getProgressPercentage();
    return statusSequence.findIndex(
      (step) => currentPercentage <= progressMapping[step]
    );
  };

  
  return (
    <div
      className={`sales-dashboard ${
        isSidebarOpen ? "sidebar-open" : "sidebar-closed"
      }`}
      style={{ backgroundColor: "rgba(242, 242, 242, 1.97)" }}
    >
      <div className="first" style={{position: 'relative', left: '20px'}}>
        <p>
          Navigate to Chesa Services
          <a
            href="https://chesadentalcare.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ExternalLink size={20} />
          </a>
          <NotificationButton />
        </p>
      </div>
      <Sidebar className="sidebar" />
      <div className="inorder-main-container">
      <div className="progress-tracker" style={{position: 'relative', top: '5px'}}>
  <div className="progress-bar-container">
    <div
      className="progress-bar-inner"
      style={{ width: `${getProgressPercentage()}%` }}
    ></div>
  </div>
  {statusSequence.map((step, index) => (
    <div
      className={`progress-step ${
        index <= getActiveStepIndex() ? "active" : ""
      }`}
      key={index}
    >
      <div
        className={`progress-circle ${
          index <= getActiveStepIndex() ? "active" : ""
        }`}
      >
        <FontAwesomeIcon
  className={`icon ${index <= getActiveStepIndex() ? "active" : ""}`}
  icon={iconMapping[step]}
  style={{ color: index <= getActiveStepIndex() ? "#ffffff" : "#777" }}
/>
      </div>
      {index < statusSequence.length - 1 && (
        <div
          className={`progress-bar-segment ${
            index < getActiveStepIndex() ? "active" : ""
          }`}
        />
      )}
      <div
        className={`progress-text ${
          index <= getActiveStepIndex() ? "active" : ""
        }`}
      >
        {step}
      </div>
    </div>
  ))}
</div>
        <h2>
          Items from Order ({id}) Date ({orderDetails[0]?.order_date})
        </h2>
        <div className="details-indorder-container">
          <div className="product-details">
            <table className="indord-table">
              <thead>
                <tr>
                  <th>Product name</th>
                  <th>Suction</th>
                  <th>Unit Price</th>
                  <th>Quantity</th>
                  <th>Discount %</th>
                  <th>Price After Discount</th>
                  <th>Color</th>
                </tr>
              </thead>
              <tbody>
                {productDetails.map((product, index) => (
                  <tr key={index}>
                    <td>{product.product_name}</td>
                    <td>{product.suction}</td>
                    <td>{product.product_price}</td>
                    <td>{product.qty}</td>
                    <td>{product.discount}</td>
                    <td>
                      {product.product_price -
                        product.product_price * (product.discount / 100)}
                    </td>
                    <td>{product.color}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="order-details">
            <h2>Order Status</h2>
            <table className="indord-table">
              <tbody>
                <tr>
                  <td>Total Amount</td>
                  <td>{total_amount}</td>
                </tr>
                <tr>
                  <td>Advance Amount</td>
                  <td>{paid_amount}</td>
                </tr>
                <tr>
                  <td>Balance Amount</td>
                  <td>{pending_amount}</td>
                </tr>
                <tr>
                  <td>Grand Total Amount</td>
                  <td>{total_amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="extra-details">
          <h2>Additional Order Details</h2>
          <table className="indord-table">
            <tbody>
              <tr>
                <td>Remarks</td>
                <td>{allRemarks || "N/A"}</td>
              </tr>
              <tr>
                <td>Order Status</td>
                <td>{status}</td>
              </tr>
              <tr>
                <td>Log Type</td>
                <td>{log_type}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="address-container">
          <div className="address-box">
            <h2>Shipping Address</h2>
            <h4>{shippingAddress.doctorName}</h4>
            <p>Address: {shippingAddress.shipAddress}</p>
            <p>Phone: {shippingAddress.phone}</p>
            <p>Email: {shippingAddress.email}</p>
            <p>City: {shippingAddress.city}</p>
            <p>State: {shippingAddress.state}</p>
            <p>Pincode: {shippingAddress.pinCode}</p>
          </div>
          <div className="address-box">
            <h2>Billing Address</h2>
            <h4>{billingAddress.doctorName}</h4>
            <p>Address: {billingAddress.shipAddress}</p>
            <p>Phone: {billingAddress.phone}</p>
            <p>Email: {billingAddress.email}</p>
            <p>City: {billingAddress.city}</p>
            <p>State: {billingAddress.state}</p>
            <p>Pincode: {billingAddress.pinCode}</p>
          </div>
          <div className="address-box">
            <h2>Delivery Details</h2>
            <p>Order Id: {orderDetails[0]?.id}</p>
            <p>Logistic Partner: {deliveryDetails.logisticPartner}</p>
            <p>Logistic Id: {deliveryDetails.logisticId}</p>
            <p>Order Date: {deliveryDetails.orderDate}</p>
            <p>Order Expected Date: {deliveryDetails.orderExpectedDate}</p>
            <p>Clinic Opening Date: {deliveryDetails.clinicOpeningDate}</p>
            <p>Shipping Date: {deliveryDetails.shippingDate}</p>
            <p>Sequence id: {deliveryDetails.sequence_id}</p>
            <p>
              Estimate Delivery Date: {deliveryDetails.estimateDeliveryDate}
            </p>
          </div>
        </div>

        <div className="indorder-button-container">
          <button class="print-btn" onClick={handlePrint}>
            <span class="printer-wrapper">
              <span class="printer-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 92 75"
                >
                  <path
                    stroke-width="5"
                    stroke="black"
                    d="M12 37.5H80C85.2467 37.5 89.5 41.7533 89.5 47V69C89.5 70.933 87.933 72.5 86 72.5H6C4.067 72.5 2.5 70.933 2.5 69V47C2.5 41.7533 6.75329 37.5 12 37.5Z"
                  ></path>
                  <mask fill="white" id="path-2-inside-1_30_7">
                    <path d="M12 12C12 5.37258 17.3726 0 24 0H57C70.2548 0 81 10.7452 81 24V29H12V12Z"></path>
                  </mask>
                  <path
                    mask="url(#path-2-inside-1_30_7)"
                    fill="black"
                    d="M7 12C7 2.61116 14.6112 -5 24 -5H57C73.0163 -5 86 7.98374 86 24H76C76 13.5066 67.4934 5 57 5H24C20.134 5 17 8.13401 17 12H7ZM81 29H12H81ZM7 29V12C7 2.61116 14.6112 -5 24 -5V5C20.134 5 17 8.13401 17 12V29H7ZM57 -5C73.0163 -5 86 7.98374 86 24V29H76V24C76 13.5066 67.4934 5 57 5V-5Z"
                  ></path>
                  <circle fill="white" r="3" cy="49" cx="78"></circle>
                </svg>
              </span>

              <span class="printer-page-wrapper">
                <span class="printer-page"></span>
              </span>
            </span>
            Print
          </button>

          {/* <button className="action-button" onClick={handlePrint}>Print Transaction Page</button> */}
          <button className="action-button1122" onClick={handleViewReceipt}>
            View Receipt
          </button>
        </div>
      </div>

      {showReceiptModal && (
        <div className="receipt-modal">
          <div className="receipt-modal-content">
            <span className="receipt-close" onClick={handleCloseReceiptModal}>
              &times;
            </span>
            <h2>Receipt Details</h2>
            <p>Payment ID: {receiptDetails.pay_id}</p>
            <p>
              <img
                src={`${baseURL}${receiptDetails.pay_rcpt}`}
                className="receipt-image"
                alt="Receipt"
                onError={(e) =>
                  (e.target.src =
                    "https://www.ourweigh.co.uk/user/products/sorry-no-image.jpg")
                } // URL to the fallback image
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default IndOrderDetails;
