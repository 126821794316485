import React, { useState, useEffect } from 'react';
import { ExternalLink, X } from 'react-feather';
import Sidebar from '../../Sidebar/Sidebar';
import '../../Dashboard/Allorders.css';
import Modal from '../../Products/Modal';
import { CheckCircle, AlertCircle, Tool, XCircle, Clock, Eye, Edit } from 'react-feather';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalWrapper from '../../Filter/ModalWrapper';
import StatusUpdateModal from '../../Products/StatusUpdateModal'; // Import StatusUpdateModal
import { CheckCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import NotificationButton from '../../../Notification/NotificationButton';
import { useAuth } from '../../../ContextAPI/AuthContext';
import { useSidebar } from '../../../ContextAPI/SidebarContext';


const salesEmpCache = {};



const AllOrders = () => {
    const { isSidebarOpen, toggleSidebar } = useSidebar();
    const [searchTerm, setSearchTerm] = useState('');
    const { user, token } = useAuth();
    const [error, setError] = useState("")
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [entriesPerPage] = useState(5);
    const [orders, setOrders] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [selectedOrderItems, setSelectedOrderItems] = useState([]);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [orderByOrderDate, setOrderByOrderDate] = useState('asc');
    const [orderByDispatchDate, setOrderByDispatchDate] = useState('asc');
    const [isStatusUpdateModalOpen, setIsStatusUpdateModalOpen] = useState(false);
    const [isDCCreationModalOpen, setIsDCCreationModalOpen] = useState(false);
    const [filteredOrders, setFilteredOrders] = useState(orders);
    const navigate = useNavigate();
    const [authLoading, setAuthLoading] = useState(true);
    const [filterOptions, setFilterOptions] = useState({
        month: '',
        year: '',
        status: '',
    });


    useEffect(() => {
        if (!user) {
            navigate('/');
        } else {
            setAuthLoading(false);
        }
    }, [user, token, navigate]);


    useEffect(() => {
        const fetchOrders = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(`https://api.chesadentalcare.com/get_all_orders`);

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setOrders(data);

                // const uniqueSalesEmpIds = [...new Set(data.map(order => order.SalesEmp))].filter(id => id); 
                // const salesEmpap = {};

                // for (const id of uniqueSalesEmpIds) {
                //     if (salesEmpCache[id]) {
                //         salesEmpMap[id] = salesEmpCache[id];
                //     } else {
                //         const salesEmpResponse = await fetch(`https://api.chesadentalcare.com/sales_emp/${id}`);
                //         if (!salesEmpResponse.ok) {
                //             throw new Error(`Failed to fetch sales employee data for ID: ${id}`);
                //         }

                //         const salesEmpData = await salesEmpResponse.json();
                //         salesEmpMap[id] = salesEmpData.SalesEmpName;
                //         salesEmpCache[id] = salesEmpData.SalesEmpName;
                //     }
                // }
                // const ordersWithSalesEmpNames = data.map(order => ({
                //     ...order,
                //     salesEmployeeName: salesEmpMap[order.SalesEmp] || 'Unknown'
                // }));
                // setOrders(ordersWithSalesEmpNames);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setIsLoading(false);
                setError('Failed to fetch orders. Please try again later.');
            }
        };

        fetchOrders();
    }, []);

    if (authLoading) {
        return <div className='loader21'></div>;
    }

    const handleOrderDetailsClick = (orderId) => {

        const orderDetails = orders.find(order => order.OrderID === orderId);
        const encryptedOrderId = btoa(orderId.toString());
        if (orderDetails) {
            const orderItems = orders.filter(order => order.OrderID === orderId);
            const orderWithItem = { ...orderDetails, orderItems };
            navigate(`/release/${encryptedOrderId}`, { state: { orderWithItem } });
            console.log(orderWithItem);
        } else {
            console.error('Order details not found');
        }
    };


    // const handleCloseDCCreationModal = () => {
    //     setIsDCCreationModalOpen(false);
    // };

    // const handleCreateDC = (serialNumber) => {
    //     // Logic to create DC goes here
    //     console.log(`DC created for Order ${selectedOrderNumber} with serial number ${serialNumber}`);
    //     setIsDCCreationModalOpen(false);Company Logo

    // }



    const handleStatusUpdateClick = (orderId) => {
        setSelectedOrderId(orderId);
        setIsStatusUpdateModalOpen(true);
    };


    const handleFilter = (filterOptions) => {
        const { month, year, status, docstatus } = filterOptions;

        let updatedFilteredOrders = orders.slice(); // Create a copy of the original orders

        // Filter orders based on selected month
        if (month) {
            updatedFilteredOrders = updatedFilteredOrders.filter(order => {
                const orderMonth = parseInt(order.OrderDate.split('-')[1]); // Extract month from "dd-mm-yyyy" format
                return orderMonth === parseInt(month);
            });
        }

        // Filter orders based on selected year
        if (year) {
            updatedFilteredOrders = updatedFilteredOrders.filter(order => {
                const orderYear = parseInt(order.OrderDate.split('-')[2]); // Extract year from "dd-mm-yyyy" format
                return orderYear === parseInt(year);
            });
        }

        // Filter orders based on selected status
        if (status) {
            updatedFilteredOrders = updatedFilteredOrders.filter(order => order.OrderStatus === status);
        }

        if (docstatus) {
            updatedFilteredOrders = updatedFilteredOrders.filter(order => order.DocStatus === docstatus);
        }


        // console.log(filterOptions);
        console.log(updatedFilteredOrders);

        setFilterOptions(filterOptions); // Update filter options state
        setFilteredOrders(updatedFilteredOrders); // Update filtered orders state
    };





    const handleActionClick = (orderId, orderItems) => {
        setSelectedOrderId(orderId);
        setSelectedOrderItems(orderItems);
        setIsModalOpen(true);
    };

    const handleViewClick = (orderId) => {
        toast.success("Action button clicked")
        setSelectedOrderId(orderId);
        setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown menu
    };


    // const handleUpdateOrderClick = () => {
    //     // Handle update order click
    //     console.log('Update order clicked for order ID:', selectedOrderId);
    // };

    const handleCloseModal = () => {
        setSelectedOrderId(null);
        setSelectedOrderItems([]);
        setIsModalOpen(false);
    };

    const handleOrderDateSort = () => {
        setOrderByOrderDate(orderByOrderDate === 'asc' ? 'desc' : 'asc');
    };

    const handleDispatchDateSort = () => {
        setOrderByDispatchDate(orderByDispatchDate === 'asc' ? 'desc' : 'asc');
    };

    const sortOrders = (orders, sortBy, sortOrder) => {
        return orders.sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });
    };

    const toggleSortOrder = (key) => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
        setSortBy(key);
        setOrders(sortOrders([...orders], key, newSortOrder));
    };

    const groupedOrders = orders.reduce((acc, order) => {
        if (!acc[order.OrderID]) {
            acc[order.OrderID] = [];
        }
        acc[order.OrderID].push(order);
        // console.log(acc);
        return acc;
    }, {});

    const groupedOrders1 = filteredOrders.reduce((acc, order) => {
        if (!acc[order.OrderID]) {
            acc[order.OrderID] = [];
        }
        acc[order.OrderID].push(order);
        // console.log(acc);
        return acc;
    }, {});

    const groupedOrdersArray = Object.values(groupedOrders);
    const groupedOrdersArray1 = Object.values(groupedOrders1);

    const FilteredOrders = groupedOrdersArray.filter(orderGroup =>
        orderGroup.some(order => order.OrderNumber.toString().includes(searchTerm.toString()))
    );

    const FilteredOrders1 = groupedOrdersArray1.filter(orderGroup =>
        orderGroup.some(order => order.OrderNumber.toString().includes(searchTerm.toString()))
    );

    const sortedOrders = orderByOrderDate
        ? sortOrders(FilteredOrders, 'OrderDate', orderByOrderDate)
        : orderByDispatchDate
            ? sortOrders(FilteredOrders, 'ExpectedDispatchDate', orderByDispatchDate)
            : FilteredOrders;


    const sortedOrders1 = orderByOrderDate
        ? sortOrders(FilteredOrders1, 'OrderDate', orderByOrderDate)
        : orderByDispatchDate
            ? sortOrders(FilteredOrders1, 'ExpectedDispatchDate', orderByDispatchDate)
            : FilteredOrders1;



    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    const indexOfLastEntry1 = currentPage1 * entriesPerPage;
    const indexOfFirstEntry1 = indexOfLastEntry1 - entriesPerPage;
    const currentEntries1 = sortedOrders1.slice(indexOfFirstEntry1, indexOfLastEntry1);
    const currentEntries = sortedOrders.slice(indexOfFirstEntry, indexOfLastEntry);
    // console.log(currentEntries);
    // const currentEntries1 = sortedOrders1.slice(indexOfFirstEntry1, indexOfLastEntry);

    const handleViewDetails = (order) => {
        navigate(`/order/${order}`, { state: { order } });
    }

    const renderPagination = (currentPage, setCurrentPage, filteredOrders, entriesPerPage) => {
        const totalPages = Math.ceil(filteredOrders.length / entriesPerPage);
        const displayPages = 3; // Number of pages to display before and after the current page

        const generatePaginationButtons = () => {
            let buttons = [];
            if (totalPages <= 1) return buttons;

            // Previous button
            if (currentPage > 1) {
                buttons.push(
                    <button key="prev" onClick={() => setCurrentPage(currentPage - 1)}>
                        Prev
                    </button>
                );
            }

            // Always show the first page
            buttons.push(
                <button
                    key={1}
                    onClick={() => setCurrentPage(1)}
                    className={currentPage === 1 ? 'active' : ''}
                >
                    1
                </button>
            );

            // Ellipsis before current range if needed
            if (currentPage > displayPages + 2) {
                buttons.push(<span key="ellipsis-start">...</span>);
            }

            // Pages around the current page
            for (let i = Math.max(2, currentPage - displayPages); i <= Math.min(totalPages - 1, currentPage + displayPages); i++) {
                buttons.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={i === currentPage ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }

            // Ellipsis after current range if needed
            if (currentPage < totalPages - displayPages - 1) {
                buttons.push(<span key="ellipsis-end">...</span>);
            }

            // Always show the last page
            if (totalPages > 1) {
                buttons.push(
                    <button
                        key={totalPages}
                        onClick={() => setCurrentPage(totalPages)}
                        className={currentPage === totalPages ? 'active' : ''}
                    >
                        {totalPages}
                    </button>
                );
            }

            // Next button
            if (currentPage < totalPages) {
                buttons.push(
                    <button key="next" onClick={() => setCurrentPage(currentPage + 1)}>
                        Next
                    </button>
                );
            }

            return buttons;
        };

        return (
            <div className="pagination">
                {generatePaginationButtons()}
            </div>
        );
    };


    return (
        <>
            <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)' }}>
                <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start', borderRadius: '8px', position: 'relative', top: '15px' }}>
                    <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '23px' }}>
                        Navigate to Chesa Services
                        <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
                            <ExternalLink size={20} />
                        </a>
                        <NotificationButton />
                    </p>

                </div>
                <div className="header">
                    <h1 className="company-name1">ALL Orders</h1><br />
                    <div style={{ position: "relative", right: "5%" }}>
                        {/* <ModalWrapper handleFilter={handleFilter} /> */}
                    </div>

                </div>
                <Sidebar />
                <div className="main-content" style={{ left: "87%" }}>
                    <div className="search-bar-container" style={{ position: 'relative', left: '128vh', marginTop: '0px', margin: '0px' }}>
                        <input
                            type="text"
                            placeholder=" Search..."
                            className="search-bar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {searchTerm && (
                            <X
                                className="clear-icon"
                                onClick={() => setSearchTerm('')}
                            />
                        )}
                    </div>
                    {filterOptions.month || filterOptions.year || filterOptions.status ? (
                        <div className="entries-info">
                            Showing {currentEntries1.length} of {FilteredOrders1.length} entries
                        </div>
                    ) : (
                        <div className="entries-info">
                            Showing {currentEntries.length} of {FilteredOrders.length} entries
                        </div>
                    )}




                    <table className="bordered-table">
                        <thead>
                            <tr>
                                <th onClick={() => toggleSortOrder('orderID')}>
                                    Order ID {sortBy === 'orderID' && (sortOrder === 'asc' ? '↑' : '↓')}
                                </th>
                                <th>Order Number</th>
                                <th onClick={() => toggleSortOrder('OrderDate')}>
                                    Order Date {sortBy === 'OrderDate' && (sortOrder === 'asc' ? '↑' : '↓')}
                                </th>
                                <th onClick={() => toggleSortOrder('ExpectedDispatchDate')}>
                                    Dispatch Date {sortBy === 'ExpectedDispatchDate' && (sortOrder === 'asc' ? '↑' : '↓')}
                                </th>
                                <th>Customer Name</th>
                                <th>Address</th>
                                <th>Color</th>
                                <th>Order Status</th>
                                <th>Doc Status</th>
                                {/* <th>Sales Employee Name</th> */}
                                <th>Products</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {isLoading ? (
                            // Render loading skeletons while data is being fetched
                            <tbody><br /> <br /> <br /><br />
                                <div class="loader21" style={{ position: 'relative', left: '600%' }}></div><br />
                                {/* <div className='load'>Loading...</div><br /> <br /><br /> */}
                            </tbody>
                        ) : (
                            <tbody>
                                {filterOptions.month || filterOptions.year || filterOptions.status ? (
                                    // Render filtered orders if any filters are applied
                                    currentEntries1.map(orderGroup => (
                                        <tr key={orderGroup[0].OrderNumber}>
                                            <td style={{ color: "indigo" }}>{orderGroup[0].OrderID}</td>
                                            <td style={{ color: "darkgreen" }}>{orderGroup[0].OrderNumber}</td>
                                            <td style={{ color: "red" }}>{orderGroup[0].OrderDate}</td>
                                            <td style={{ color: "purple" }}>{orderGroup[0].ExpectedDispatchDate}</td>
                                            <td style={{ color: "darkblue" }}>{orderGroup[0].CustomerName}</td>
                                            <td>{orderGroup[0].Address}</td>
                                            <td>{orderGroup[0].Color}</td>
                                            <td>
                                                {orderGroup[0].OrderStatus === 'Delivered' ? (
                                                    <div className="order-status delivered">
                                                        <CheckCircle className="success-icon" />
                                                        Delivered
                                                    </div>
                                                ) : orderGroup[0].OrderStatus === 'Approved For Dispatch' ? (
                                                    <div className="order-status approved">
                                                        <span style={{ color: 'white' }}>
                                                            <CheckCircle size={16} />
                                                            Approved for Dispatch
                                                        </span>
                                                    </div>
                                                ) : orderGroup[0].OrderStatus === 'Ready For Dispatch' ? (
                                                    <span style={{ color: 'purple' }}>
                                                        <Clock size={16} />
                                                        Ready for Dispatch
                                                    </span>
                                                ) : orderGroup[0].OrderStatus === 'Dispatched' ? (
                                                    <div className="order-status dispatched">
                                                        <CheckCircleFill className="success-icon1" />
                                                        Dispatched
                                                    </div>
                                                ) : orderGroup[0].OrderStatus === 'Job Card Issued' ? (
                                                    <div className="order-status issued1" >
                                                        <Tool size={16} />
                                                        Job Card Issued
                                                    </div>
                                                ) : (
                                                    <span style={{ color: 'red' }}>
                                                        <AlertCircle size={16} />
                                                        Unknown Status
                                                    </span>
                                                )}
                                            </td>
                                            <td>{orderGroup[0].DocStatus === 'O' ? 'Open' : 'Close'}</td>
                                            {/* <td>{orderGroup[0].salesEmployeeName || 'Loading ...'}</td> */}
                                            <td>
                                                <button onClick={() => handleActionClick(orderGroup[0].OrderID, orderGroup)} className="continue-button1">View Products</button>
                                            </td>
                                            <td>
                                                {/* Replace button with icons for view details and update order */}
                                                <div className="action-icons2">
                                                    <div className="action-icon2" title="DC creation" style={{ cursor: "pointer" }}>
                                                        <button onClick={() => handleOrderDetailsClick(orderGroup[0].OrderID, orderGroup[0].OrderNumber)} className="button-21">DC creation</button>
                                                    </div>
                                                    {/* <div className="action-icon2" title="Update Status" style={{ cursor: "pointer" }}>
                                                        <button onClick={() => handleStatusUpdateClick(orderGroup[0].OrderID, orderGroup[0].OrderStatus)} className="button-21">Update Status</button>
                                                    </div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    // Render all orders if no filters are applied
                                    currentEntries.map(orderGroup => (
                                        <tr key={orderGroup[0].OrderNumber}>
                                            <td style={{ color: "indigo" }}>{orderGroup[0].OrderID}</td>
                                            <td style={{ color: "darkgreen" }}>{orderGroup[0].OrderNumber}</td>
                                            <td style={{ color: "red" }}>{orderGroup[0].OrderDate}</td>
                                            <td style={{ color: "purple" }}>{orderGroup[0].ExpectedDispatchDate}</td>
                                            <td style={{ color: "darkblue" }}>{orderGroup[0].CustomerName}</td>
                                            <td>{orderGroup[0].Address}</td>
                                            <td>{orderGroup[0].Color}</td>
                                            <td>
                                                {orderGroup[0].OrderStatus === 'Delivered' ? (
                                                    <div className="order-status delivered">
                                                        <CheckCircle className="success-icon" />
                                                        Delivered
                                                    </div>
                                                ) : orderGroup[0].OrderStatus === 'Approved For Dispatch' ? (
                                                    <div className="order-status approved">
                                                        <span style={{ color: 'white' }}>
                                                            <CheckCircle size={16} />
                                                            Approved for Dispatch
                                                        </span>
                                                    </div>
                                                ) : orderGroup[0].OrderStatus === 'Ready For Dispatch' ? (
                                                    <span style={{ color: 'purple' }}>
                                                        <Clock size={16} />
                                                        Ready for Dispatch
                                                    </span>
                                                ) : orderGroup[0].OrderStatus === 'Dispatched' ? (
                                                    <div className="order-status dispatched">
                                                        <CheckCircleFill className="success-icon1" />
                                                        Dispatched
                                                    </div>
                                                ) : orderGroup[0].OrderStatus === 'Job Card Issued' ? (
                                                    <div className="order-status issued1" >
                                                        <Tool size={30} className='icon12' />
                                                        Job Card Issued
                                                    </div>
                                                ) : (
                                                    <span style={{ color: 'red' }}>
                                                        <AlertCircle size={16} />
                                                        Unknown Status
                                                    </span>
                                                )}
                                            </td>
                                            <td>{orderGroup[0].DocStatus === 'O' ? 'Open' : 'Close'}</td>
                                            {/* <td>{orderGroup[0].salesEmployeeName || 'Loading ...'}</td> */}
                                            <td>
                                                <button onClick={() => handleActionClick(orderGroup[0].OrderID, orderGroup)} className="continue-button1">View Products</button>
                                            </td>
                                            <td>
                                                {/* Replace button with icons for view details and update order */}
                                                <div className="action-icons2">
                                                    <div className="action-icon2" title="DC creation" style={{ cursor: "pointer" }}>
                                                        <button onClick={() => handleViewDetails(orderGroup[0].OrderNumber)} className="continue-button1">View Details</button>
                                                    </div>
                                                    {/* <div className="action-icon2" title="Update Status" style={{ cursor: "pointer" }}>
                                                        <button onClick={() => handleStatusUpdateClick(orderGroup[0].OrderID, orderGroup[0].OrderStatus)} className="button-21">Update Status</button>
                                                    </div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>

                        )}
                    </table>
                    {filterOptions.month || filterOptions.year || filterOptions.status ? (
                        <div>
                            {/* Pagination for FilteredOrders1 */}
                            <div className="pagination">
                                {renderPagination(currentPage1, setCurrentPage1, FilteredOrders1, entriesPerPage)}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {/* Pagination for FilteredOrders */}
                            <div className="pagination">
                                {renderPagination(currentPage, setCurrentPage, FilteredOrders, entriesPerPage)}
                            </div>
                        </div>
                    )}

                </div>

            </div>

            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                    selectedOrderItems={selectedOrderItems}
                />
            )}

            {isStatusUpdateModalOpen && ( // Render status update modal if open
                <StatusUpdateModal
                    isOpen={isStatusUpdateModalOpen}
                    onRequestClose={() => setIsStatusUpdateModalOpen(false)}
                    orderId={selectedOrderId}
                    orderNumber={selectedOrderItems[0]?.OrderNumber} // Pass order number as prop
                />
            )}



            <ToastContainer />
        </>
    );
};

export default AllOrders;