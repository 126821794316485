import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { ExternalLink } from "react-feather";
import Sidebar from "../../Sidebar/Sidebar";
import NotificationButton from "../../../Notification/NotificationButton";
import { useSidebar } from "../../../ContextAPI/SidebarContext";
import axios from "axios";
import toast from "react-hot-toast";
import "./ViewPage.css";

const ViewPage = () => {
  const { SequentialNo } = useParams();
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const { isSidebarOpen } = useSidebar();
  const location = useLocation();
  const lead = location.state.lead;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [salesOpportunity, setSalesOpportunity] = useState(null);
  const [followUpDate, setFollowUpDate] = useState("");
  const [followUpAction, setFollowUpAction] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [startDate, setStartDate] = useState("");
  const [action, setAction] = useState(6); // Default action key (First Call)
  const [isLossDialogOpen, setIsLossDialogOpen] = useState(false);
  const [isWonDialogOpen, setIsWonDialogOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [lossRemarks, setLossRemarks] = useState("");
  const [lossType, setLossType] = useState("");
  const [wonType, setWonType] = useState("");
  const [salesOrderNumber, setSalesOrderNumber] = useState('');
  const [fetchedData, setFetchedData] = useState(null);
  const [docEntry, setDocEntry] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [customer, setCustomer] = useState('');

  const stageKeyDefinitions = {
    1:"Cold",
    2:"Warm",
    3:"Hot",
    4:"Closed",
    6: "First Call",
    7: "First Meeting",
    15: "Second Call",
    12: "Second Meeting",
    16: "Third Call",
    8: "Quotation",
    9: "Negotiation",
    10: "Payment Confirmation",
    11: "Order Confirmed",
    13: "Revision Of Products",
  };

  useEffect(() => {
    const fetchSalesOpportunity = async () => {
      try {
        const response = await fetch(
          `https://api.chesadentalcare.com/sales_opportunity/${SequentialNo}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch sales opportunity details");
        }
        const data = await response.json();
        setSalesOpportunity(data);
        console.log("salesOpportunity", data);
        if (data.SalesOpportunitiesLines.length > 0) {

          
          const initialRemarks = data.SalesOpportunitiesLines.map(line => line.Remarks || '');
          setRemarks(initialRemarks);
          const lastEntry =
            data.SalesOpportunitiesLines[
            data.SalesOpportunitiesLines.length - 1
            ];
            
          setFollowUpDate(
            lastEntry.ClosingDate ? lastEntry.ClosingDate.split("T")[0] : ""
          );
          setStartDate(
            lastEntry.ClosingDate ? lastEntry.ClosingDate.split("T")[0] : ""
          );
          setFollowUpAction(lastEntry.StageKey);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchSalesOpportunity();
  }, [SequentialNo]);

  const getLastIndex = () => {
    // Get the last index of the SalesOpportunitiesLines array
    return salesOpportunity?.SalesOpportunitiesLines.length;
  };

  const handleFollowUpDateChange = (e) => {
    setFollowUpDate(e.target.value);
  };

  const handleFollowUpActionChange = (e) => {
    console.log("e.target",e.target.value);
    setFollowUpAction(e.target.value);
  };

  const handleRemarksChange = (value, lineIndex) => {
    const newRemarks = [...remarks];
    newRemarks[lineIndex] = value;
    setRemarks(newRemarks);
};


  const formatDatePlusOne = (dateString) => {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add one day to the date
    date.setDate(date.getDate() + 1);

    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const fetchSalesEmpCode = async (id) => {
    try {
      const response = await fetch(`https://api.chesadentalcare.com/salesEmpCode?id=${id}`);
      const data = await response.json();
      return data.code; // Adjust this according to your actual response structure
    } catch (error) {
      console.error('Error fetching salesEmpCode:', error);
      throw new Error('Failed to fetch salesEmpCode');
    }
  };

  const patchFollowUp = async (lineNumber, remarks, SequentialNo, DataOwnershipfield,followUpAction) => {
    // console.log(followUpAction);
    // return
    const lastLineIndex = getLastIndex();
    const lastLine = salesOpportunity.SalesOpportunitiesLines[salesOpportunity.SalesOpportunitiesLines.length - 1];
    console.log("formattedDate", lastLine);
    const formattedDate = formatDatePlusOne(lastLine.ClosingDate);
    // const lastRemark = remarks.filter(Boolean).slice(-1)[0] || "";
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(followUpDate);
    if (!isValidDate) {
      throw new Error("Invalid date format. Expected format is YYYY-MM-DD.");
    }



    const salesEmpCode = await fetchSalesEmpCode(DataOwnershipfield);
    // Convert the date string to ISO format
    const isoDate = new Date(followUpDate).toISOString();
    // Return the date part of the ISO string
    const newDate = isoDate.split("T")[0] + "T00:00:00Z";

    const arr = [
      {
        sequenceId: SequentialNo,
        LineNumber: lastLineIndex,
        sales_emp: salesEmpCode,
        startDate: lastLine.ClosingDate,
        closeDate: newDate,
        stageKey: followUpAction,
        totalPrice: lineNumber.MaxLocalTotal,
        remarks: remarks,
        DataOwnershipfield: DataOwnershipfield
      },
    ];
    const data = JSON.stringify(arr);
    console.log(data);

    // Show loading toast
    const loadingToastId = toast.loading("Updating follow-up details...");

    try {
      const response = await axios.patch(
        `https://api.chesadentalcare.com/patch_followup`,
        arr
      );
      if (response.status === 200) {
        toast.success("Follow-up details updated successfully", {
          id: loadingToastId,
        });
        // navigate(`/lead/${SequentialNo}`);
      } else {
        throw new Error("Failed to update follow-up details");
      }
    } catch (error) {
      toast.error(`Error updating follow-up details: ${error.message}`, {
        id: loadingToastId,
      });
    }
  };

  const handleLossClick = (lead) => {
    setSelectedLead(lead);
    setLossRemarks(lead.Remarks || ""); // Set the default value of the textarea
    setIsLossDialogOpen(true);
  };

  const handleLossSubmit = () => {
    // Handle the loss submission, e.g., send to server or update state
    console.log("Loss details submitted:", {
      ...selectedLead,
      lossRemarks,
      lossType,
    });
    closeDialog();
  };



  const checkDocumentNumber = () => {
    const lastLine = salesOpportunity.SalesOpportunitiesLines[salesOpportunity.SalesOpportunitiesLines.length - 1];
    return lastLine.DocumentNumber === null;
  };

  const handleWonClick = (lead) => {
    if (checkDocumentNumber()) {
      setIsWonDialogOpen(true);
    } else {
      alert("Sales order already exists for this lead.");
    }
  };

  const handleFetch = async () => {
    setIsFetching(true);
    // Show a loading toast when fetching starts
    const loadingToast = toast.loading('Fetching data...');

    try {
      const response = await axios.get(`https://api.chesadentalcare.com/DocEntry?id=${salesOrderNumber}`);

      // Assuming response.data.value is the array of sales orders
      const salesOrders = response.data.value;
      

      // Check if there are any sales orders in the response
      if (salesOrders.length > 0) {
        const firstOrder = salesOrders[0]; // Get the first order
        const { CardName, ShipToCode } = firstOrder; // Extract DocEntry
        if(firstOrder.DocumentStatus === 'bost_Open'){
          setFetchedData(firstOrder);
        setDocEntry(CardName); // Update state with the fetched data
        setCustomer(ShipToCode);
        }else{
          toast.error("This order is closed");
          setDocEntry(null); // Update state with the fetched data
          setCustomer(null);
          return
        }

        

        // Log the fetched data and DocEntry
        toast.success("Order fetched successfully")
        console.log("Fetched data:", firstOrder);
        console.log("salesOrders",firstOrder.DocumentStatus)
      } else {
        console.log("No sales orders found for the given Sales Order Number.");
        toast.error('No sales orders found for the given Sales Order Number.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error fetching data.');
    } finally {
      // Close the loading toast
      toast.remove(loadingToast);
      setIsFetching(false);
    }
  };

  const handleWonSubmit = async () => {
    const loadingToast = toast.loading('Submitting...');
    if (!salesOpportunity) {
      console.error('No sales opportunity data available');
      return;
    }

    const salesEmpCode = salesOpportunity.SalesOpportunitiesLines[0].DataOwnershipfield; // Fetch sales employee code

    // Create the data object to send
    const dataToSend = {
      sapid: SequentialNo,
      start: startDate,
      close: followUpDate,
      docentry: docEntry, // Use DocEntry from fetchedData
      price: salesOpportunity.SalesOpportunitiesLines[0].MaxLocalTotal,
      ownerid: salesEmpCode
    };

    // Log the data to be sent
    console.log('Data to be sent:', JSON.stringify(dataToSend, null, 2));
    try {
      const response = await axios.patch('https://api.chesadentalcare.com/patchwon', {
        sapid: SequentialNo,
        start: startDate,
        close: followUpDate,
        docentry: docEntry,
        price: salesOpportunity.SalesOpportunitiesLines[0].MaxLocalTotal,
        ownerid: salesEmpCode
      });
      toast.success("Lead Patched successfully")
      console.log('Patch successful:', response.data);
      navigate('/open-leads', { replace: true });
      window.location.reload();
      // Handle success, maybe show a notification or update the UI
    } catch (error) {
      toast.error('Error Patching Lead');
      console.error('Error updating opportunity:', error.response ? error.response.data : error.message);
      // Handle error, maybe show an error message to the user
    } finally {
      // Close the loading toast
      toast.remove(loadingToast);
    }
  };


  const closeDialog = () => {
    setIsLossDialogOpen(false);
    setIsWonDialogOpen(false);
    setSelectedLead(null);
    setFetchedData(null);
    setDocEntry('');
    setSalesOrderNumber('');
  };
  const handleGoBack = () => {
    navigate("/open-leads");
  };

  if (salesOpportunity) {
    console.log("salesOpportunity.SalesOpportunitiesLines.length", salesOpportunity.SalesOpportunitiesLines.length)
  }

  return (
    <>
      <div
        className={`sales-dashboard ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"
          }`}
        style={{ backgroundColor: "rgba(242, 242, 242, 1.97)" }}
      >
        <div
          className="first"
          style={{
            backgroundColor: "#f2f2f2",
            padding: "20px",
            textAlign: "start",
            position: "relative",
            top: "15px",
          }}
        >
          <p
            style={{
              fontFamily: "Arial, sans-serif",
              fontSize: "16px",
              margin: "-30px",
              fontWeight: "bold",
              position: "relative",
              top: "5px",
              left: "23px",
            }}
          >
            Navigate to Chesa Services
            <a
              href="https://chesadentalcare.com"
              style={{
                textDecoration: "none",
                color: "#007bff",
                marginLeft: "5px",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLink size={20} />
            </a>
            <NotificationButton />
          </p>
        </div>
        <Sidebar />
        <div className="main-content" style={{ left: "87%" }}>
          <div className="view-page">
            <div className="top-buttons">
              <button onClick={handleGoBack} className="action-button">
                Go Back
              </button>

              <button
                onClick={() => handleLossClick(lead)}
                className="action-button"
              >
                Loss
              </button>

              <button
                onClick={() => handleWonClick(lead)}
                className="action-button"
              >
                Won
              </button>
            </div>
            <h2>
              Lead Entry ({lead.SequentialNo}) Create Date: ({lead.StartDate})
            </h2>
            <table className="lead-details">
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Customer Code</th>
                  <th>Start Date</th>
                  <th>Predicted Closing Date</th>
                  <th>Order Value</th>
                  <th>Source</th>
                  <th>Sales Emp Name</th>
                  <th>Interest Level</th>
                  <th>Order Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{lead.SequentialNo}</td>
                  <td>{lead.CardCode}</td>
                  <td>{lead.StartDate}</td>
                  <td>{lead.PredictedClosingDate}</td>
                  <td>{lead.MaxLocalTotal}</td>
                  <td>{lead.Source}</td>
                  <td>{lead.salesEmployeeName}</td>
                  <td>{lead.InterestLevel}</td>
                  <td>{lead.Status}</td>
                </tr>
              </tbody>
            </table>
            <div className="follow-up-section">
              {salesOpportunity && (
                <>
                  <h3>Update Follow-Up </h3>
                  <table className="sales-opportunity-lines">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Start Date</th>
                        <th>Follow-Up Date</th>
                        <th>Value</th>
                        <th>Action</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salesOpportunity.SalesOpportunitiesLines.map(
                        (line, index) => (
                          <tr key={index}>
                            <td>{line.LineNum}</td>
                            <td>{line.StartDate.split("T")[0]}</td>
                            <td>
                              {index === salesOpportunity.SalesOpportunitiesLines.length - 1 ? (
                                <input
                                  type="date"
                                  value={followUpDate}
                                  onChange={handleFollowUpDateChange}
                                  min={ line.ClosingDate.split("T")[0]  > new Date().toISOString().split("T")[0] ? line.ClosingDate.split("T")[0] : new Date().toISOString().split("T")[0]} // Set the min attribute
                                />
                              ) : line.ClosingDate ? (
                                line.ClosingDate.split("T")[0]
                              ) : (
                                "-"
                              )}
                            </td>

                            <td>{line.MaxLocalTotal}</td>
                            <td>
                              {index ===
                                salesOpportunity.SalesOpportunitiesLines.length -
                                1 ? (
                                <select
                                  value={followUpAction}
                                  onChange={handleFollowUpActionChange}
                                >
                                  {Object.entries(stageKeyDefinitions).map(
                                    ([key, value]) => (
                                      <option key={key} value={key}>
                                        {value}
                                      </option>
                                    )
                                  )}
                                </select>
                              ) : line.StageKey ? (
                                stageKeyDefinitions[line.StageKey]
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>
                              {line.Status
                                ? line.Status.replace("so_", "")
                                : "-"}
                            </td>

                            {index === salesOpportunity.SalesOpportunitiesLines.length - 1 ? (
                              <td>
                                <textarea
                                  onChange={(e) => handleRemarksChange(e.target.value,index)}
                                  value={remarks[index]}
                                  rows="4"
                                  cols="10"
                                />
                              </td>
                            ) : (
                              <td>{remarks[index] === ''? "No Remarks" : remarks[index]}</td>
                            )}

                            <td>
                              {index ===
                                salesOpportunity.SalesOpportunitiesLines.length -
                                1 ? (
                                <button
                                  onClick={() =>
                                    patchFollowUp(
                                      line,
                                      remarks[remarks.length - 1],
                                      lead.SequentialNo,
                                      lead.DataOwnershipfield,
                                      followUpAction

                                    )
                                  }
                                  className="action-button"
                                >
                                  Update Follow-Up
                                </button>
                              ) : (
                                "Stage Closed"
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
        </div>
        {isLossDialogOpen && (
          <div className="dialog-overlay">
            <div className="dialog">
              <span className="close-lead-button" onClick={closeDialog}>
                &times;
              </span>
              <div className="dialog-header">
                <h2>Update Lead Details</h2>
              </div>
              <label>
                Remarks:
                <textarea
                  value={lossRemarks}
                  onChange={(e) => setLossRemarks(e.target.value)}
                  rows="4"
                  cols="50"
                />
              </label>
              <div>
                <label>
                  Lost Type:
                  <div>
                    <select
                      value={lossType}
                      onChange={(e) => setLossType(e.target.value)}
                    >
                      <option value="">Choose One</option>
                      <option value="Reason 1">Lost</option>
                      <option value="Reason 2">Confident</option>
                      <option value="Reason 3">Unicorn</option>
                      <option value="Reason 4">Bestodent</option>
                      <option value="Reason 5">Suzident</option>
                      <option value="Reason 6">Local Chair</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                </label>
              </div>
              <button className="dialogue-button" onClick={handleLossSubmit}>
                Submit
              </button>
              <button className="dialogue-button" onClick={closeDialog}>
                Cancel
              </button>
            </div>
          </div>
        )}

        {isWonDialogOpen && (
          <div className="dialog-overlay">
            <div className="dialog">
              <div>
                <span className="close-lead-button" onClick={closeDialog}>
                  &times;
                </span>
                <h2>Update Lead Details</h2>
                <label>
                  Document Type:
                  <select
                    value={lossType}
                    onChange={(e) => setWonType(e.target.value)}
                  >
                    <option value="">Choose One</option>
                    {/* <option value="Reason 1">Sales Quotation</option> */}
                    <option value="Reason 2">Sales Order</option>
                    {/* Add more options as needed */}
                  </select>
                </label>
                <div className="sales-data-won">
                  <label>
                    Sales Order Number:
                    <input
                      type="text"
                      placeholder=""
                      size="25"
                      value={salesOrderNumber}
                      onChange={(e) => setSalesOrderNumber(e.target.value)}
                    />
                  </label>
                  <button className="dialogue-fetch-button" onClick={handleFetch}>
                    Fetch
                  </button>
                </div>
                {docEntry && (
                  <div>
                    <p>Customer Name  :  {customer}</p>
                    <p>Dealer Name  :  {docEntry}</p>
                  </div>
                )}
                <label>
                  Remarks:
                  <textarea placeholder="" rows="4" cols="50" />
                </label>
                {!isFetching && docEntry && (
                  <button className="dialogue-button" onClick={handleWonSubmit}>
                    Submit
                  </button>
                )}
                <button className="dialogue-button" onClick={closeDialog}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewPage;
