import React, { useState } from 'react';
import Swal from 'sweetalert';

const StatusUpdateModal = ({ isOpen, onRequestClose, orderId }) => {
    const [selectedStatus, setSelectedStatus] = useState('');
    const [isLoading, setIsLoading] = useState(false); // State to manage loading status

    const handleUpdateOrderClick = async () => {
        try {
            setIsLoading(true); // Set loading to true when update starts
            const response = await fetch('http://35.154.172.240:3004/update-order', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: orderId,
                    status: selectedStatus,
                }),
            });
            const data = await response.json();
            console.log(data);

            if (response.ok) {
                // Show success message using SweetAlert
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Order status updated successfully!',
                }).then(() => onRequestClose());
            } else {
                // Show error message using SweetAlert
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to update order status. Please try again later.',
                });
            }
        } catch (error) {
            console.error(error);
            // Show error message using SweetAlert
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'An error occurred. Please try again later.',
            });
        } finally {
            setIsLoading(false); // Set loading to false after update completes (success or error)
        }
    };

    const handleCloseModal = () => {
        onRequestClose();
    };

    return (
        <div className={isOpen ? "modal-overlay" : "modal-overlay hidden"}>
            <div className="modal">
                <span className="close" style={{ fontSize: "24px" }} onClick={handleCloseModal}>&times;</span>
                <h2>Update Order Status</h2>
                <select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    className="status-dropdown"
                >
                    <option value="">Select Status</option>
                    <option value="Delivered">Delivered</option>
                    <option value="Not Delivered">Not Delivered</option>
                    <option value="Dispatched">Dispatched</option>
                    <option value="Approved For Dispatch"> Approved For Dispatched</option>
                    {/* <option value="Dispatched">Dispatched</option> */}
                </select>
                <button onClick={handleUpdateOrderClick} disabled={isLoading}>
                    {isLoading ? 'Updating...' : 'Update Status'}
                </button>
            </div>
        </div>
    );
};

export default StatusUpdateModal;
