import React, { useEffect, useState } from 'react';
import { ExternalLink, X } from 'react-feather';
import './CataloguePage.css';
import { useSidebar } from '../../../ContextAPI/SidebarContext';
import Sidebar from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import NotificationButton from '../../../Notification/NotificationButton';
import { useAuth } from '../../../ContextAPI/AuthContext';

const CataloguePage = () => {
  const [categories, setCategories] = useState([]);
  const { user,token } = useAuth();
  const { isSidebarOpen } = useSidebar();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
        navigate('/');
    } else {
        
    }
}, [user, token, navigate]);
  const baseURL = 'https://chesadentalcare.com/possystem/admin/admin/uploads/category/';

  useEffect(() => {
    // Fetch the data from the API
    fetch('https://api.chesadentalcare.com/category')  // Replace with your actual API endpoint
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const fetchProductsForCategory = (name) => {
    fetch(`https://api.chesadentalcare.com/category_details?cat_name=${encodeURIComponent(name)}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Fetched products for category:', name, data);
        const productsWithQuantity = data.map(product => ({ ...product, quantity: 1 }));
        setProducts(productsWithQuantity); // Assuming setProducts is defined
      })
      .catch(error => console.error('Error fetching products:', error));
  };

  const handleImageClick = (name) => {
    fetchProductsForCategory(name);
    navigate(`/purchase/${encodeURIComponent(name)}`);
  };

  const handleButtonClick = (event, name) => {
    event.stopPropagation();
    fetchProductsForCategory(name);
    navigate(`/purchase/${encodeURIComponent(name)}`);
  };

  return (
    <>
    <div className={`sales-dashboard ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`} style={{ backgroundColor: 'rgba(242, 242, 242, 1.97)'}}>
        <div className='first' style={{ backgroundColor: '#f2f2f2', padding: '20px', textAlign: 'start',  position: 'relative', top: '15px' }}>
            <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '16px', margin: '-30px', fontWeight: "bold", position: 'relative', top: '5px', left: '23px' }}>
                Navigate to Chesa Services
                <a href="https://chesadentalcare.com" style={{ textDecoration: 'none', color: '#007bff', marginLeft: '5px' }} target="_blank" rel="noopener noreferrer">
                    <ExternalLink size={20} />
                </a>
                <NotificationButton />
            </p>

        </div>
        <div className="header">
            <h1 className="company-name1" style={{position: 'relative', top: '5px', left: '23px'}}>Product Catalogue</h1><br />
            <div style={{ position: "relative", right: "5%" }}>
                {/* <ModalWrapper handleFilter={handleFilter} /> */}
            </div>
        </div>
        <Sidebar />
        <div className="main-content" style={{ left: "87%" }}>
      <div className="top-categories-container">
        <div className="top-categories">
          {categories.map(category => (
            <div key={category.id} className="category-card" onClick={() => handleImageClick(category.cat_name)}>
              <div className="image-container">
                <img src={`${baseURL}${category.image}`} alt={category.cat_name} className="category-image" />
                <div className="overlay"></div>
                <button className="company-image-button1" onClick={(event) => handleButtonClick(event, category.cat_name)}>View More</button>
              </div>
              <h3 className="category-name">{category.cat_name}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
    </>
  );
};

export default CataloguePage;
